import { patientsColumns,patientsSorting,patientsColumnVisibilty, patientsColumnVisibility } from "CommonData/PatientsColumns";
import { useLoading } from "components/LoadingProvider";
import { useSnackbar } from "components/SnackbarProvider";
import Table from "components/Table";
import HomeLayout from "layout/HomeLayout";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetPatientsQuery } from "services/patientApi";
import { setSelectedMenuItem } from "slices/globaslice";

function PatientsListPage() {
  const { data: patientsData, error, isLoading,refetch } = useGetPatientsQuery();
  const showSnackbar = useSnackbar();
  const dispatch = useDispatch();
  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setSelectedMenuItem(4));
  }, []);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);

  return (
    <>
      {
        <Table
          data={patientsData || []}
          title="Patients"
          columns={patientsColumns}
          rowClickUrl="/patients/"
          sorting={patientsSorting}
          columnVisibility={patientsColumnVisibility}
          refetch={refetch}
          />
      }
    </>
  );
}

export default PatientsListPage;
//