import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, Grid, Slide } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import logo from "assets/slog.png";

const Footer = () => {

  return (
    <Box
      sx={{
        maxWidth: "100vw",
        backgroundColor: "white",
        color: "#C7C7C7",
        px: "40px",
        py: 2,
      }}
    >
      <Box component="footer" sx={{ pt: 1, textAlign: "center" }}>
        <Typography variant="body2">&copy; 2024 AtHomeGeriCare</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
