import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, FormControlLabel, Switch, Box } from '@mui/material';

const LegalComplianceCard = ({ caregiverDetails, handleChange, isEditing }) => {
  const [compliance, setCompliance] = useState({
    background_check_clearance: false,
    security_verification: false,
    professional_insurance: false,
    driving_license_status: false,
  });

  useEffect(() => {
    if (caregiverDetails?.legal_compliances) {
      setCompliance({
        ...compliance,
        ...caregiverDetails?.legal_compliances,
      });
    }
  }, [caregiverDetails]);

  const handleToggle = (name) => {
    const updatedCompliance = { ...compliance, [name]: !compliance?.[name] };
    setCompliance(updatedCompliance);
    handleChange("legal_compliances" ,updatedCompliance);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">Legal and Compliance</Typography>
        <Grid container spacing={2}>
          {[
            { label: 'Background check clearance', name: 'background_check_clearance' },
            { label: 'Security verification', name: 'security_verification' },
            { label: 'Professional insurance', name: 'professional_insurance' },
            { label: 'Driving license status', name: 'driving_license_status' },
          ].map((field) => (
            <>
              {isEditing ? (
            <Grid item xs={12} key={field.name}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={compliance?.[field.name]}
                      onChange={() => handleToggle(field.name)}
                      name={field.name}
                      color="primary"
                    />
                  }
                  label={field.label}
                />
                </Grid>
              ) : (
              <Grid item xs={3} key={field.name}>
                <Box>
                  <Typography variant="subtitle1">{field.label}</Typography>
                  <Typography variant="body1">
                    {compliance?.[field.name] ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Grid>
              )}
              </>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LegalComplianceCard;
