import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  TextField,
  Typography,
  Box, Button,
  Chip
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  useGetAppointmentByIdQuery,
  useUpdateAppointmentMutation
} from "services/appointmentApi";
import { useSnackbar } from "./SnackbarProvider";
import { useLoading } from "./LoadingProvider";
import { getColor } from "common/utilities";
import ScheduleAppointment from "./ScheduleAppointment";
import UpdateAppointment from "./UpdateAppointment";
import { useGetCaregiversQuery, useUpdateCaregiverMutation } from "services/caregiverApi";
import { useGetUsersQuery } from "services/userApi";
import { CaregiverStatuses, ClientStatuses, meetingTypes, PatientStatuses } from "CommonData/Common";
import { useGetClientByIdQuery, useUpdateClientMutation } from "services/clientApi";

const AppointmentDetail = () => {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const {
    data: appointment,
    error: apptError,
    isLoading: isApptLoading,
  } = useGetAppointmentByIdQuery(appointmentId);
  const [updateAppointment, { isLoading: isCancelling }] =
    useUpdateAppointmentMutation();
  const [selectedPatientids, setSelectedPatientids] = useState([]);
  const [selectedCaregiverids, setSelectedCaregiverids] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const { data: cgs, cgserror, iscgsLoading } = useGetCaregiversQuery();
  const { data: staffsdata, staffserror, isstaffsLoading } = useGetUsersQuery();
  const [selectedStaffs, setSelectedStaffs] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const [updateCaregiver, { isLoading: updatingCG, error: updatecgerror }] =
    useUpdateCaregiverMutation();
    const [updateClient, { isLoading: isUpdating, error: updateError }] =
      useUpdateClientMutation();

    const { data: clientInAppointment , isLoading : isClntLoading, error: clntError } = useGetClientByIdQuery(
      appointment?.client, 
      {skip : !appointment?.client}
    );

  const isLoading = isApptLoading || iscgsLoading || updatingCG || isUpdating || isClntLoading
  const error = apptError || cgserror || updatecgerror || updateError || clntError
    
  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (error ) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);
    
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();

 useEffect(()=>{
  if (appointment){
    setSelectedCaregiverids(appointment?.caregivers);
    setSelectedClientId(appointment?.client)
    setSelectedPatientids(appointment?.patients)

  }
 }, [appointment])

  useEffect(() => {
    if (cgs) {
      setCaregivers(cgs);
    }
  }, [cgs]);
 
  useEffect(() => {
  if (staffsdata) {
    setStaffs(staffsdata);
  }
}, [staffsdata]);

 const cancelMeeting = async (notes) => {
    const updatedFields = {
      id: appointmentId,
      status: "cancelled",
      notes: `${appointment.meeting_type}  scheduled on ${appointment.appointment_date} at ${appointment.from_time} is cancelled. ` +
        notes
    };
    startLoading();
    try {
      const result = await updateAppointment(updatedFields);
      if (result.error) {
        throw result.error;
      }
      // console.log(result)
      showSnackbar("Appointment cancelled successfully", "success");
    } catch (err) {
      console.error("Failed to cancel appointment:", err);
      showSnackbar("Failed to cancel appointment", "error");
    } finally {
      stopLoading();
      const params = new URLSearchParams(location.search);
      const previousURL = params.get('redirect');
      if (previousURL) {
        navigate(previousURL);
      } else {
        navigate('/clients'); // Fallback URL if no previous URL is stored
      }
    }
  };
  
  const markAsAttended  = async (notes, otherFields={}) => {
    const updatedFields = {
      id: appointmentId,
      status: "completed",
      notes: `${appointment.meeting_type} scheduled on ${appointment.appointment_date} at ${appointment.from_time} is completed. ` +
        notes,
      ...otherFields
    };
    console.log(updatedFields)
    startLoading();
    try {
      const result = await updateAppointment(updatedFields);
      if (result.error) {
        throw result.error;
      }
      showSnackbar("Appointment completed successfully", "success");
    } catch (err) {
      console.error("Failed to complete appointment:", err);
      showSnackbar("Failed to complete appointment", "error");
    } finally {
      stopLoading();
      const params = new URLSearchParams(location.search);
      const previousURL = params.get('redirect');
      if (previousURL) {
        navigate(previousURL);
      } else {
        navigate('/clients'); // Fallback URL if no previous URL is stored
      }
    }
  };
  
  
  const completeClientDiscussion = async (notes) =>{
    var data = {}
    
    data.client = appointment?.client
    data.client_status = ClientStatuses.AWAITING_CG_INTERVIEW.name
    await markAsAttended(notes, data)
  }

  const closeAsSelected = async (notes) =>{
    var data = {}
    if (selectedCaregiverids.length >0){
      data.caregivers = appointment?.caregivers
      data.cg_status = CaregiverStatuses.ASSIGNED.name
    }
    if (selectedClientId) {
      data.client = appointment?.client
      data.client_status = ClientStatuses.CG_SELECTED.name
    }
    if (selectedPatientids.length >0){
      data.patients = appointment?.patients
      data.patient_status = PatientStatuses.CG_ASSIGNED.name
    }
    
    await markAsAttended(notes, data)
  }
  
  const closeAsRejected = async (notes) =>{
    var data = {}
    if (selectedCaregiverids.length >0){
      data.caregivers = appointment?.caregivers
      data.cg_status = CaregiverStatuses.AVAILABLE.name
    }
    if (selectedClientId) {
      data.client = appointment?.client
      data.client_status = ClientStatuses.CG_INTERVIEW.name
    }
    if (selectedPatientids.length >0){
      data.patients = appointment?.patients
      data.patient_status = PatientStatuses.CG_INTERVIEW.name
    }
    await markAsAttended(notes, data)
  }
   
  const NoteBox = ({ buttonText, onClick,buttonsx={}, color, onClose }) => {
    const [noteText, setNoteText] = useState('');
  
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        <TextField
          variant="outlined"
          multiline
          rows={5}
          placeholder="Write your note here..."
          fullWidth
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
        />
        <Box sx={{ mt:2,display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button variant="contained" sx={buttonsx} color={color} disabled={!noteText} onClick={() => onClick(noteText)}>
            {buttonText}
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    );
  };  

  const [startCancel, setStartCancel] = useState(false);
  const [selectedWithNotes, setSelectedWithNotes] = useState(false);
  const [rejectedWithNotes, setRejectedWithNotes] = useState(false);
  const [completeClntDisc, setCompleteClntDisc] = useState(false);
  const location = useLocation();
  const [attendNotes, setAttendNotes] = useState(false);
  const [update, setUpdate] = useState(false);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;
  return (
    <>
    { update && appointment ?
      <UpdateAppointment updateAppt={appointment}/>
      :    
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box component="form" sx={{ width:"100%", p: 2}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Appointment Schedule</Typography>
          {appointment && <Chip label={appointment?.status} sx={{bgcolor: getColor(appointment?.status), color: "white"}} />}
        </Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
          {
          startCancel ?
          <Grid item xs={12}>
            <NoteBox buttonText={"Cancel Meeting"} 
              buttonsx={{
                bgcolor: "#FF0000",
                '&:hover': {
                  bgcolor: "#CC0000", // Darker shade of red for hover effect
                },
              }}
            onClick={cancelMeeting} onClose={() => setStartCancel(false)} color="secondary"/>
          </Grid>
          :
          attendNotes ?
          <Grid item xs={12}>
            <NoteBox buttonText={"Mark as Attended"} onClick={markAsAttended} onClose={() => setAttendNotes(false) } color="success"/>
          </Grid>
          :
          selectedWithNotes ?
          <Grid item xs={12}>
            <NoteBox buttonText={"Close as Selected"} onClick={closeAsSelected} onClose={() => setSelectedWithNotes(false) } color="success"/>
          </Grid>
          :
          rejectedWithNotes ?
          <Grid item xs={12}>
            <NoteBox buttonText={"Close as Rejected"} onClick={closeAsRejected} onClose={() => setRejectedWithNotes(false) } color="success"/>
          </Grid>
          :
          completeClntDisc ?
          <Grid item xs={12}>
            <NoteBox buttonText={"Complete Initial Client Discussion"} onClick={completeClientDiscussion} onClose={() => setCompleteClntDisc(false) } color="success"/>
          </Grid>
          :
          <>
            {appointment?.status == "scheduled" ?
            <Grid item xs={12} container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button
                  sx={{
                    bgcolor: "#FF0000",
                    '&:hover': {
                      bgcolor: "#CC0000", // Darker shade of red for hover effect
                    },
                  }}
                  variant="contained"
                  startIcon={<CancelIcon />}
                  onClick={() => setStartCancel(true)}
                  disabled={startCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => setUpdate(true)}
                >
                  Update
                </Button>
              </Grid>
              {appointment?.meeting_type != meetingTypes.clientCaregiverInterview.name && 
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<CheckCircleIcon />}
                  onClick={()=> setAttendNotes(true)}
                  disabled={
                    attendNotes 
                  }
                >
                  Mark as Attended
                </Button>
              </Grid>
              }
              {appointment?.meeting_type == meetingTypes.clientCaregiverInterview.name && 
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CheckCircleIcon />}
                  onClick={()=> setSelectedWithNotes(true)}
                  disabled={selectedWithNotes}
                >
                  Close as selected
                </Button>
              </Grid>
              }
              {appointment?.meeting_type == meetingTypes.clientCaregiverInterview.name &&
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CheckCircleIcon />}
                  onClick={()=> setRejectedWithNotes(true)}
                  disabled={rejectedWithNotes}
                >
                  Close as rejected
                </Button>
              </Grid>
            }
            {appointment?.meeting_type == meetingTypes.initialClientMeet.name &&
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CheckCircleIcon />}
                  onClick={()=> setCompleteClntDisc(true)}
                  disabled={completeClntDisc}
                >
                  Complete Initial Client Discussion
                </Button>
              </Grid>
            }
            </Grid>
            :
            <Grid item xs={12}>
              <TextField
              multiline
              rows={5}
                label="Meeting Notes"
                disabled={true}
                value={appointment?.notes}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            }
            <Grid item xs={12}>
              <TextField
                label="Title"
                disabled={true}
                value={appointment?.title}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label="Date"
                disabled={true}
                // format="YYYY-MM-DD"
                value={
                  appointment
                    ? dayjs(appointment.appointment_date, "YYYY-MM-DD")
                    : null
                }
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={4}>
              <TimePicker
                label="Timing From"
                disabled={true}
                value={
                  appointment ? dayjs(appointment.from_time, "HH:mm:ss") : null
                }
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={4}>
              <TimePicker
                label="Timing To"
                disabled={true}
                value={
                  appointment ? dayjs(appointment.to_time, "HH:mm:ss") : null
                }
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item xs={12}>
              {
                // <Chip
                //   label={
                //     appointment
                //       ?
                //         appointment.client_details && 
                //         "Client: " + appointment.client_details?.firstName + ", " + appointment.client_details?.lastName
                        
                //       : "Checking for client..."
                //   }
                clientInAppointment && <Chip
                  label={
                    clientInAppointment
                      ? 
                        "Client: " + clientInAppointment?.firstName + ", " + clientInAppointment?.lastName
                        
                      : "Checking for client..."
                  }
                  color="warning"
                  variant="contained"
                  sx={{ m: 0.5 }}
                />
                
              }

              {/* Selected Patients Chips */}
              {appointment?.patients?.map((pid) => {
                const patient = clientInAppointment?.patients?.find(p => p.id==pid)
                return (
                <Chip
                  key={pid}
                  label={"Patient: " + patient?.firstName + ", " + patient?.lastName}
                  color="error"
                  variant="contained"
                  sx={{ m: 0.5 }}
                />
                )
              })}

              {/* Selected Caregivers Chips */}
              
            {/* Selected Caregivers Chips */}
            {appointment && appointment.caregivers?.map((cgid) => {
              const caregiver = cgs?.find(cg => cg.id === cgid);
              return (
                caregiver && (
                  <Chip
                    key={cgid}
                    label={`Caregiver: ${caregiver.firstName}, ${caregiver.lastName}`}
                    color="info"
                    variant="contained"
                    sx={{ m: 0.5 }}
                  />
                )
              );
            })}
              {appointment && appointment.staffs?.map((sid) => {
              const staff = staffs?.find(s => s.id === sid);
              return (
                staff && (
                  <Chip
                    key={sid}
                    label={`Staff: ${staff.username}`}
                    color="success"
                    variant="contained"
                    sx={{ m: 0.5 }}
                  />
                )
              );
            })}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                disabled={true}
                label="Other EmailIds (optional)"
                InputLabelProps={{ shrink: true }}
                value={appointment?.emails.join(";")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                disabled={true}
                label="Meeting Type"
                InputLabelProps={{ shrink: true }}
                value={appointment?.meeting_type}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={true}
                label="Email Content"
                InputLabelProps={{ shrink: true }}
                value={appointment?.invitation_text}
                multiline
                rows={10}
                fullWidth
              />
            </Grid>
          </>
          }
          </Grid>
        </Box>
      </LocalizationProvider>
    }
  </>
  );
};

export default AppointmentDetail;
