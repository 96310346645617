import { caregiversColumns, caregiversSorting, caregiversColumnVisibilty, caregiversColumnVisibility } from "CommonData/CaregiversColumns";
import { useLoading } from "components/LoadingProvider";
import { useSnackbar } from "components/SnackbarProvider";
import Table from "components/Table";
import HomeLayout from "layout/HomeLayout";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetCaregiversQuery } from "services/caregiverApi";
import { setSelectedMenuItem } from "slices/globaslice";

function CaregiversListPage() {
  const { data: caregiversData, error, isLoading,refetch } = useGetCaregiversQuery();
  const showSnackbar = useSnackbar();
  const dispatch = useDispatch();
  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setSelectedMenuItem(5));
  }, []);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);

  return (
    <>
        <Table
          title="Caregivers"
          data={caregiversData || []}
          columns={caregiversColumns}
          rowClickUrl="/caregivers/"
          chipField="status"
          sorting={caregiversSorting}
          columnVisibility={caregiversColumnVisibility}
          refetch={refetch}
        />
    </>
  );
}

export default CaregiversListPage;
