import { useLoading } from "components/LoadingProvider";
import { useSnackbar } from "components/SnackbarProvider";
import Table from "components/Table";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {  billingsColumnVisibility,  billingsColumns,  billingsSorting} from "CommonData/billingsColumns";
import { useGetBillingsQuery } from "services/billingApi";
import { setSelectedMenuItem } from "slices/globaslice";

function BillingsListPage() {
  const { data: billings, error, isLoading, refetch } = useGetBillingsQuery();
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setSelectedMenuItem(8));
  }, []);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);

  const onAddClick = () => {
    const previousURL = encodeURIComponent(location.pathname);
    navigate(`/billings/new?redirect=${previousURL}`);
  };
  return (
    <Table
      data={billings || []}
      title="Billings"
      onAddClick={onAddClick}
      columns={billingsColumns}
      rowClickUrl="/billings/"
      chipField="status"
      sorting={billingsSorting}
      columnVisibility={billingsColumnVisibility}
      refetch={refetch}
    />
  );
}

export default BillingsListPage;
