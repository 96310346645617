import { Box, Chip } from "@mui/material";
import { getColor } from "common/utilities";

const billingsColumns = [
  {
    accessorKey: 'id',
    header: 'Billing Id',
    size: 20,
  },
  {
    accessorKey: 'client',
    header: 'Client',
    size: 150,
    Cell: ({ cell }) => {
      const client = cell.getValue(); // Assuming this returns the client object
      return (
        <Chip
          label={client ? `${client.lastName}, ${client.firstName}` : 'No Client'}
          component="a"
          href={client ? `/clients/${client.id}` : '#'}
          color="info"
          onClick={(event) => event.stopPropagation()}
          clickable
        />
      );
    },
  },
  {
    accessorKey: 'contract',
    header: 'Contract',
    size: 150,

    Cell: ({ cell }) => (
      <Chip
        label={`Contract ${cell.getValue()}`}
        component="a"
        href={`/contracts/${cell.getValue()}`}
        color="success"
        onClick={(event) => event.stopPropagation()}
        clickable
      />
    ),
  },
  {
    accessorKey: 'amount',
    header: 'Amount ($)',
    size: 150,
  },
  {
    accessorKey: 'payment_date',
    header: 'Payment Date',
    size: 150,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 150,

    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          fontSize: 12,
          backgroundColor: getColor(cell.getValue()),
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {cell.getValue()}
      </Box>
    ),
  },
  {
    accessorKey: 'method',
    header: 'Payment Method',
    size: 150,
  },
  {
    accessorKey: 'transaction_id',
    header: 'Transaction ID',
    size: 200,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    size: 150,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    size: 150,
  },
];

const billingsColumnVisibility = {
  transaction_id: false,
  created_at: false,
  updated_at: false,
};

const billingsSorting = [{ id: 'payment_date', desc: true }];

export { billingsColumns, billingsColumnVisibility, billingsSorting };
