import React from 'react';
import { styled } from '@mui/system';
import { Box, Button } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { isAuthenticated } from 'common/utilities';

const Root = styled('div')({
  height: '100vh',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)', // Adjust the opacity as needed
});

const BrandText = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 'clamp(24px, 6vw, 40px)', // Responsive font size
  fontFamily: 'Lora, serif',
}));

const NavBar = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 30px', // Adjusted padding for smaller screens
  boxSizing: 'border-box',
  zIndex: 1,
});

const ApplyButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: '5%', // Adjusted margin for smaller screens
}));

const LoginBtn = styled(Button)(({ theme }) => ({
  
}));

const LandingLayout = (props) => {
  const navigate = useNavigate();
  const handleApply = () => {
    navigate('/caregiver/new');
  };
  const login = () => {
    navigate('/login');
  };

  return (
    <Root>
      <NavBar>
      <Box component="img" alt="AtHomeGeriCare" sx={{ width: 120 }} />
        <ApplyButton variant="contained" color="primary" onClick={handleApply}>Join Us</ApplyButton>
        { 
        isAuthenticated() ?
          <Button variant="contained" color="primary" onClick={() => navigate("/dashboard")}>Dashboard</Button>
          :
          <Button variant="contained" color="primary" onClick={login}>Login</Button>
        }
        </NavBar>
      <Overlay />
      <Outlet/>
    </Root>
  );
};

export default LandingLayout;
