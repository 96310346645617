import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import theme from 'Theme'; // Assuming you have your theme imported
import ITextField from './ITextField';

const IAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-clearIndicator': {
    color: 'white', // Change clear icon color
  },
  '& .MuiAutocomplete-popupIndicator': {
    color: 'white', // Change popup icon color
  },
  '& .MuiAutocomplete-listbox': {
    backgroundColor: 'black', // Background color for dropdown
    color: 'white', // Text color for options
    border: '1px solid grey', // Border color for dropdown
    maxHeight: 224,
    width: 250,
  },
  '& .MuiAutocomplete-option': {
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.primary.main, // Background color for focused option
      color: 'white', // Text color for focused option
    },
  },
  '& .MuiAutocomplete-noOptions': {
    color: 'grey', // Text color for no options message
  },
  '& .MuiInputLabel-root': {
    color: 'grey', // Default label color
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main, // Label color when focused
  },
})); 

export default function ISelect({ options, label, value, onChange,onInputChange, ...otherProps }) {
  return (
    <IAutocomplete
      options={options}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
      value={value} // Ensure value prop is passed
      onChange={(event, newValue) => onChange(event, newValue)} // Handle change event and new value
      onInputChange={(event, newValue) => onInputChange && onInputChange(event, newValue)} // Handle change event and new value
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          {...otherProps} // Spread all other props
        />
      )}
    />
  );
}