import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EventIcon from "@mui/icons-material/Event";
import { Typography, Grid, Paper, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedMenuItem, setUserInfo } from "slices/globaslice";
import { useEffect } from "react";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import GroupIcon from "@mui/icons-material/Group";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import AccessibleRoundedIcon from '@mui/icons-material/AccessibleRounded';
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import DbChart from "components/DbChart";
import DbBarChart from "components/DbBarChart";
import AppointmentsOverviewChart from "components/AppointmentsOverviewChart";
import ClientsOverviewCard from "components/ClientsOverviewCard";
import { useSnackbar } from "components/SnackbarProvider";
import { useLoading } from "components/LoadingProvider";
import { useGetDashboardStatisticsQuery } from "services/dashboardApi";

function DashboardCard({ icon: Icon, bgColor, title, count }) {
  return (
    <Paper
      sx={{
        textAlign: "right", // Aligns the text to the right
        position: "relative",
        borderRadius: "13px",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: 2,
      }}
    >
      <Box
        sx={{
          height: "50px",
          width: "50px",
          position: "absolute",
          top: "-10px",
          backgroundColor: bgColor,
          borderRadius: "10%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon sx={{ color: "white", height: "25px", width: "25px" }} />
      </Box>
      <Box sx={{ paddingTop: "5px", pr:1,textAlign: "right" }}> {/* Aligns text to the right */}
        <Typography variant="subtitle1" sx={{ color: "black" }}>
          {title}
        </Typography>
        <Typography variant="h5" sx={{ color: bgColor }}>
          {count}
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: bgColor,
          width: "100%",
          marginTop: "auto",
          borderBottomLeftRadius: "13px",
          borderBottomRightRadius: "13px",
          padding: 1,
          textAlign: "right", // Aligns text to the right
        }}
      >
        <Typography variant="subtitle2" color="white"></Typography>
      </Box>
    </Paper>
  );
}

function DashboardPage() {
  const dispatch = useDispatch();
  
  const { data, error, isLoading } = useGetDashboardStatisticsQuery();
  
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();
  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      // console.log(d ata)
      stopLoading();
    }
  }, [isLoading]);


  useEffect(() => {
    if (error ) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);

  useEffect(() => {
    dispatch(setSelectedMenuItem(1));
  }, [dispatch]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;

  return (
    <>
      <Grid container rowSpacing={2} spacing={1} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard
            icon={GroupsRoundedIcon}
            bgColor="#3B95EE"
            title="Clients"
            count={data?.total_counts?.clients || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard
            icon={AccessibleRoundedIcon}
            bgColor="#61B665"
            title="Patients"
            count={data?.total_counts?.patients || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard
            icon={HailRoundedIcon}
            bgColor="#F39C12"
            title="Caregivers"
            count={data?.total_counts?.caregivers || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard
            icon={TravelExploreIcon}
            bgColor="#E83874"
            title="Appointments"
            count={data?.total_counts?.appointments || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DbChart
            data={data?.page_visits?.data}
            bgColor="#2ecc71"
            title="Website Visits"
            subtitle={data?.page_visits?.subtitle}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DbBarChart
            bgColor="#E83874"
            title="Client Interests"
            subtitle={data?.last_9_months?.clients?.subtitle || ""}
            data={data?.last_9_months?.clients?.data || []}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DbBarChart
            bgColor="#F39C12"
            title="Caregiver Interests"
            subtitle={data?.last_9_months?.caregivers?.subtitle || ""}
            data={data?.last_9_months?.caregivers?.data || []}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AppointmentsOverviewChart values={data?.appointments_by_type || {}}/>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ClientsOverviewCard values={data?.clients_by_status || {}}/>
        </Grid>
      </Grid>
    </>
  );
}

export default DashboardPage;
