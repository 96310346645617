// src/services/baseQueryWithReauth.js
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import {jwtDecode} from 'jwt-decode';

// import { setCredentials, logOut } from '../features/auth/authSlice'; // Adjust the path as necessary

// Base query with token attachment
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});
const getUserId = () =>{
  const token = localStorage.getItem('access_token');
    if (token) {
        const decodedToken = jwtDecode(token);
        return decodedToken.user_id
    }
    return null
}
const mutex = new Mutex();

// Base query with reauthentication logic
const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  if (args.method === 'POST' && !(args.body instanceof FormData)) {
    const userId = getUserId();
    if (userId) {
      args.body = {
        ...args.body,
        created_by: userId,
        updated_by: userId,
      };
    }
  }
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const refreshResult = await baseQuery(
          {
            url: 'token/refresh/',
            method: 'POST',
            body: { refresh_token: refreshToken },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          const { accessToken } = refreshResult.data;
          localStorage.setItem('access_token', accessToken);
          // api.dispatch(setCredentials({ accessToken }));
          result = await baseQuery(args, api, extraOptions);
        } else {
          window.location.href = '/login'; // Navigate to home page on error
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
        }
      } 
      catch {
        window.location.href = '/login';
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      }
      finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export { baseQuery, baseQueryWithReauth };
