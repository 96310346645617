import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const PersonalPreferencesCard = ({ caregiverDetails, handleChange, isEditing }) => {
  const genderPreferences = ['Any', 'Male', 'Female', 'Other'];
  const smokingPreferences = ['Any', 'Non-Smoking', 'Smoking'];
  const vegetarianPreferences = ['Any', 'Vegetarian', 'Non-Vegetarian'];
  const householdReligionPreferences = ['Any', 'Christianity', 'Hinduism', 'Islam', 'Sikhism', 'Other'];

  const [personalPreferences, setPersonalPreferences] = useState({
    gender_preference_of_clients: '',
    smoking_preferences: '',
    vegetarian_preference: '',
    household_religion_preference: '',
  });

  useEffect(() => {
    if (caregiverDetails?.personal_preferences) {
      setPersonalPreferences({
        ...personalPreferences,
        ...caregiverDetails?.personal_preferences,
      });
    }
  }, [caregiverDetails]);

  const handleFieldChange = (field, value) => {
    const updatedPreferences = { ...personalPreferences, [field]: value };
    setPersonalPreferences(updatedPreferences);
    handleChange('personal_preferences', updatedPreferences);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">Personal Preferences and Characteristics</Typography>
        <Grid container spacing={2}>
          {/* Gender Preference of Clients */}
          <Grid item xs={6}>
            {isEditing ? (
              <FormControl fullWidth>
                <InputLabel id="gender-preference-select-label">Gender Preference of Clients</InputLabel>
                <Select
                  labelId="gender-preference-select-label"
                  value={personalPreferences.gender_preference_of_clients}
                  onChange={(e) => handleFieldChange('gender_preference_of_clients', e.target.value)}
                  label="Gender Preference of Clients"
                >
                  {genderPreferences.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box>
                <Typography variant="subtitle1">Gender Preference of Clients</Typography>
                <Typography variant="body1">
                  {personalPreferences.gender_preference_of_clients || 'Not specified'}
                </Typography>
              </Box>
            )}
          </Grid>

          {/* Smoking or Non-Smoking Preferences */}
          <Grid item xs={6}>
            {isEditing ? (
              <FormControl fullWidth>
                <InputLabel id="smoking-preferences-select-label">Smoking or Non-Smoking Preferences</InputLabel>
                <Select
                  labelId="smoking-preferences-select-label"
                  value={personalPreferences.smoking_preferences}
                  onChange={(e) => handleFieldChange('smoking_preferences', e.target.value)}
                  label="Smoking or Non-Smoking Preferences"
                >
                  {smokingPreferences.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box>
                <Typography variant="subtitle1">Smoking or Non-Smoking Preferences</Typography>
                <Typography variant="body1">
                  {personalPreferences.smoking_preferences || 'Not specified'}
                </Typography>
              </Box>
            )}
          </Grid>

          {/* Vegetarian vs Non-Vegetarian Homes */}
          <Grid item xs={6}>
            {isEditing ? (
              <FormControl fullWidth>
                <InputLabel id="vegetarian-preference-select-label">Vegetarian vs Non-Vegetarian Homes</InputLabel>
                <Select
                  labelId="vegetarian-preference-select-label"
                  value={personalPreferences.vegetarian_preference}
                  onChange={(e) => handleFieldChange('vegetarian_preference', e.target.value)}
                  label="Vegetarian vs Non-Vegetarian Homes"
                >
                  {vegetarianPreferences.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box>
                <Typography variant="subtitle1">Vegetarian vs Non-Vegetarian Homes</Typography>
                <Typography variant="body1">
                  {personalPreferences.vegetarian_preference || 'Not specified'}
                </Typography>
              </Box>
            )}
          </Grid>

          {/* Household Religion Preference */}
          <Grid item xs={6}>
            {isEditing ? (
              <FormControl fullWidth>
                <InputLabel id="household-religion-preference-select-label">Household Religion Preference</InputLabel>
                <Select
                  labelId="household-religion-preference-select-label"
                  value={personalPreferences.household_religion_preference}
                  onChange={(e) => handleFieldChange('household_religion_preference', e.target.value)}
                  label="Household Religion Preference"
                >
                  {householdReligionPreferences.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box>
                <Typography variant="subtitle1">Household Religion Preference</Typography>
                <Typography variant="body1">
                  {personalPreferences.household_religion_preference || 'Not specified'}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PersonalPreferencesCard;
