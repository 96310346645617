// src/services/noteApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueryWithReauth";
import { tagTypes } from "./tags";

const noteApi = createApi({
  reducerPath: "noteApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Note", "Client"], // Ensure relevant tag types are included
  endpoints: (builder) => ({
    postNote: builder.mutation({
      query: (note) => ({
        url: `notes/`,
        method: "POST",
        body: note,
      }),
      invalidatesTags: (result, error, { client }) => client ? [{ type: 'Client', id: client }] : [],
    }),
  }),
});

export const { usePostNoteMutation } = noteApi;

export default noteApi;
