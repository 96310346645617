import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Box, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from 'common/utilities';
import { useMediaQuery } from '@mui/material';
import logo from 'assets/slog.png';

const buttonStyles = (isActive) => ({
  color: isActive ? 'orange' : 'grey',
  fontSize: {
    sx: 14,
    xl: 20,
  },
  fontWeight: 100,
  fontFamily: 'Arial, Helvetica, sans-serif',
  borderBottom: isActive ? '2px solid orange' : 'none',
  borderRadius: 0,
});

const Header = ({ aboutRef, servicesRef, teamsRef, storyRef }) => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeButton, setActiveButton] = useState(''); // Track the active button
  const sections = [
    { ref: aboutRef, label: 'About' },
    { ref: storyRef, label: 'Why Choose Us' },
    { ref: teamsRef, label: 'Meet the Minds' },
    { ref: servicesRef, label: 'Services' },
  ];
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional, for smooth scrolling
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // Current scroll position
      const windowHeight = window.innerHeight; // 100vh

      // Check which section is currently visible
      let currentActiveLabel = '';

      sections.forEach((section) => {
        if (section.ref.current) {
          const rect = section.ref.current.getBoundingClientRect();
          const isVisible = rect.top < windowHeight && rect.bottom >= 0;

          // If this section is visible, consider it active
          if (isVisible) {
            currentActiveLabel = section.label;
          }
        }
      });

      // Update the active button if a section is visible
      
    if (scrollPosition < windowHeight) {
      setActiveButton(null)
    } 
      else if (currentActiveLabel) {
        setActiveButton(currentActiveLabel);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Initial check to see which section is visible at load
    handleScroll();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.2) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const book = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setActiveButton('Contact');
    navigate('/clients/new');
  };

  const gotoAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    setActiveButton('About');
  };

  const gotoStory = () => {
    storyRef.current.scrollIntoView({ behavior: 'smooth' });
    setActiveButton('Why Choose Us');
  };

  const gotoTeam = () => {
    teamsRef.current.scrollIntoView({ behavior: 'smooth' });
    setActiveButton('Meet the Minds');
  };

  const gotoServices = () => {
    servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    setActiveButton('Services');
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleApply = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setActiveButton('Join');
    navigate('/caregiver/new');
  };

  const login = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setActiveButton('Login');
    navigate('/login');
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
        <IconButton onClick={handleDrawerToggle} sx={{ color: 'black' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        <ListItem onClick={gotoAbout}>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem onClick={gotoStory}>
          <ListItemText primary="Why Choose Us" />
        </ListItem>
        <ListItem onClick={gotoTeam}>
          <ListItemText primary="Meet the Minds" />
        </ListItem>
        <ListItem onClick={gotoServices}>
          <ListItemText primary="Services" />
        </ListItem>
        <ListItem onClick={book}>
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem onClick={handleApply}>
          <ListItemText primary="Career" />
        </ListItem>
        {isAuthenticated() ? (
          <ListItem onClick={() => navigate('/dashboard')}>
            <ListItemText primary="Dashboard" />
          </ListItem>
        ) : (
          <ListItem onClick={login}>
            <ListItemText primary="Login" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        height: {
          xs: isScrolled ? 55 : 64,
          xl: isScrolled ? 100 : 64,
        },
        top: 0,
        backgroundColor: 'white',
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ pt: 1, justifyContent: isSmallScreen ? 'flex-start' : 'flex-end' }}>
        {isSmallScreen ? (
          <>
            <Box component="img" 
              src={logo} 
              alt="AtHomeGeriCare" 
              onClick={scrollToTop}
              sx={{ pt: 1, height: 80, width: 200, ml: -5 , cursor:"pointer"}} />
            <IconButton
              edge="start"
              color="black"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ position: 'absolute', right: '10px' }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
              {drawerContent}
            </Drawer>
          </>
        ) : (
          <>
            <Box
              component="img"
              src={logo}
              alt="AtHomeGeriCare"
              onClick={scrollToTop}
              sx={{
                position: 'absolute',
                cursor:"pointer",
                top: 10,
                left: 10,
                transition: 'all 0.3s ease',
                height: {
                  xs: isScrolled ? 60 : 80,
                  xl: 120,
                },
                width: {
                  xs: isScrolled ? 150 : 200,
                  xl: 300,
                },
              }}
            />
            <Box sx={{ width: '65%', mb: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button color="inherit" onClick={gotoAbout} sx={buttonStyles(activeButton === 'About')}>
                About
              </Button>
              <Button color="inherit" onClick={gotoStory} sx={buttonStyles(activeButton === 'Why Choose Us')}>
                Why Choose Us
              </Button>
              <Button color="inherit" onClick={gotoTeam} sx={buttonStyles(activeButton === 'Meet the Minds')}>
                Meet the Minds
              </Button>
              <Button color="inherit" onClick={gotoServices} sx={buttonStyles(activeButton === 'Services')}>
                Services
              </Button>
              <Button color="inherit" onClick={book} sx={buttonStyles(activeButton === 'Contact')}>
                Contact
              </Button>
              <Button color="inherit" onClick={handleApply} sx={buttonStyles(activeButton === 'Join')}>
                Career
              </Button>
              {isAuthenticated() ? (
                <Button color="warning" sx={{ color: 'white' }} variant="contained" onClick={() => navigate('/dashboard')}>
                  Dashboard
                </Button>
              ) : (
                <Button color="inherit" onClick={login} sx={buttonStyles(activeButton === 'Login')}>
                  Login
                </Button>
              )}
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
