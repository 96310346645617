import React from 'react';
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Chip,
  IconButton,
  Box,
} from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import { formatOnlyDate, getColor } from 'common/utilities';
import { Link } from 'react-router-dom';

const ProfileCard = ({ patientProfile, newPatient }) => {
  return (
    <Card elevation={3} sx={{ height: "100%", width: "100%", position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <CardContent sx={{ textAlign: 'center' }}>
      <UserAvatar  sx= {{margin: '0 auto 10px'}} firstName={patientProfile?.firstName || "A"} 
      lastName={patientProfile?.lastName || "A"} />
        <Typography variant="h6">
          {patientProfile?.firstName}, {patientProfile?.lastName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {"#" + patientProfile?.id}
        </Typography>
        <Typography variant="body2" color="textSecondary">
        Member since: {formatOnlyDate(patientProfile?.created_at)}
        </Typography>
        {patientProfile?.status && <Chip label={ patientProfile?.status} size="large" sx={{ 
          mt: 2, 
          mr:0.5, bgcolor:getColor(patientProfile?.status) ,
          color:"white"
        }} 
        display="inline" 
        clickable
        component={Link}
        />}
        {patientProfile?.client && <Chip label={
          "Client: " + patientProfile?.client?.lastName + ", " + patientProfile?.client?.firstName
        } size="large" 
        sx={{ 
          mt: 2, 
          mr:0.5
        }}  
        color='info'
        display="inline" 
        clickable
        component={Link}
        to={`/clients/${patientProfile?.client?.id}`}

        />}
        {<Chip label=
        {
        patientProfile?.caregiver ? 
        "Caregiver: " + patientProfile?.caregiver.lastName + ", " + patientProfile?.caregiver.firstName : "No Caregiver"
        } size="large" color="warning" sx={{m:0.5, mt: 2 }} 
        display="inline" 
        clickable
        component={Link}
        to={`/caregivers/${patientProfile?.caregiver?.id}`}

        />}
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
