import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Chip,
  IconButton,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

const conditionsList = [
  "Arthritis",
  "Heart Disease",
  "Osteoporosis",
  "Diabetes",
  "Alzheimer's Disease and Dementia",
  "Parkinson's Disease",
  "Chronic Obstructive Pulmonary Disease (COPD)",
  "Cancer",
  "Stroke",
  "Vision and Hearing Loss",
  "Depression",
  "Incontinence",
  "Osteoarthritis",
  "Cataracts",
  "Age-related Macular Degeneration (AMD)",
  "Glaucoma",
  "Chronic Kidney Disease",
  "Benign Prostatic Hyperplasia (BPH)",
  "Diverticulitis",
  "Shingles (Herpes Zoster)",
  "Urinary Incontinence",
  "Insomnia",
  "Depressive Disorders",
  "Gastroesophageal Reflux Disease (GERD)",
  "Peripheral Artery Disease (PAD)",
  "Hearing Impairment",
  "Dementia",
];

const PatientMedicalInfo = ({ patientProfile, newPatient, onChange }) => {
  const [isEditing, setIsEditing] = useState(newPatient);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [otherConditions, setOtherConditions] = useState("");

  useEffect(() => {
    // Initialize selectedConditions with patientProfile data
    if (patientProfile?.medical_info) {
      setSelectedConditions(patientProfile.medical_info);
    }
  }, [patientProfile]);

  const handleToggleEdit = () => {
    setIsEditing((prevIsEditing) => {
      const newIsEditing = !prevIsEditing;
      if (!newIsEditing) {
        onChange({ medical_info: selectedConditions });
      }
      return newIsEditing;
    });

    if (isEditing) {
      // When switching from editing to viewing, add otherConditions to selectedConditions
      const otherConditionsArray = otherConditions
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item);
      setSelectedConditions([
        ...new Set([...selectedConditions, ...otherConditionsArray]),
      ]);
      setOtherConditions("");
    }
  };

  const handleConditionChange = (condition) => {
    setSelectedConditions((prev) =>
      prev.includes(condition)
        ? prev.filter((item) => item !== condition)
        : [...prev, condition]
    );
  };

  const handleOtherConditionsChange = (event) => {
    setOtherConditions(event.target.value);
  };

  return (
    <Card sx={{ width: "100%", height: "100%" }}>
      <CardHeader
        title="Medical Information"
        action={
          <IconButton onClick={handleToggleEdit} aria-label="edit">
            {isEditing ? (
              <DoneIcon color="primary" />
            ) : (
              <EditIcon color="primary" />
            )}
          </IconButton>
        }
      />
      <CardContent>
        {isEditing ? (
          <>
            <Grid container spacing={0.5}>
              {conditionsList.map((condition) => (
                <Grid item xs={12} sm={6} md={4} key={condition}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedConditions.includes(condition)}
                        onChange={() => handleConditionChange(condition)}
                      />
                    }
                    label={condition}
                  />
                </Grid>
              ))}
            </Grid>
            <TextField
              fullWidth
              placeholder="Enter other conditions, separated by commas"
              value={otherConditions}
              onChange={handleOtherConditionsChange}
              sx={{ mt: 2 }}
            />
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {selectedConditions.length > 0 ? (
                selectedConditions.map((condition) => (
                  <Chip key={condition} label={condition} sx={{ m: 0.5 }} />
                ))
              ) : (
                <Typography variant="body2">No conditions selected.</Typography>
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default PatientMedicalInfo;
