import { Box } from "@mui/material";
import { getColor } from "common/utilities";

const clientsColumns = [
  {
    accessorKey: 'id',
    header: 'Client Id',
    size: 20,
    
  },
  {
    accessorKey: 'firstName',
    header: 'First Name',
    size: 150,
  },
  {
    accessorKey: 'lastName',
    header: 'Last Name',
    size: 150,
   
  },
  {
    accessorKey: 'email',
    header: 'Email Id',
    size: 150,
   
  },
  {
    accessorKey: 'contactNumber',
    header: 'Contact No.',
    size: 150,
   
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 200,
  
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          fontSize:12,
          backgroundColor: getColor(cell.getValue()),
            // ["Interested"].includes(cell.getValue())
            //   ? theme.palette.success.main 
            //   : ["Scheduled"].includes(cell.getValue())
            //     ? theme.palette.warning.main
            //     : theme.palette.success.dark,
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {cell.getValue()}
      </Box>
    ),
  },
  {
    accessorKey: 'serviceType',
    header: 'Service Type',
    size: 150,
   
  },
  {
    accessorKey: 'serviceLocation',
    header: 'Service Location',
    size: 150,
   
  },
  {
    accessorKey: 'availabilityStartTime',
    header: 'Available From',
    size: 150,
    
  },
  {
    accessorKey: 'availabilityEndTime',
    header: 'Available To',
    size: 150,
   
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    size: 150,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    size: 150,
  },
];

const clientColumnVisibilty = {
  serviceLocation: false,
  availabilityStartTime: false,
  availabilityEndTime:false,
  updated_at: false,
  created_at: false,
};
const clientSorting = [{ id: 'updated_at', desc: true }]
export { clientsColumns, clientColumnVisibilty,clientSorting };
