import React, { useEffect, useState } from "react";
import { Button, Grid, Fab, darken, useTheme } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  useGetCaregiverByIdQuery,
  useUpdateCaregiverMutation,
  useFetchResumeFileQuery,
} from "services/caregiverApi";
import PersonalInfoCard from "components/Caregiver/PersonalInfoCard";
import ProfessionalBackgroundCard from "components/Caregiver/ProfessionalBackgroundCard";
import LegalComplianceCard from "components/Caregiver/LegalComplianceCard";
import HealthSafetyCard from "components/Caregiver/HealthSafetyCard";
import SkillsAbilitiesCard from "components/Caregiver/SkillsAbilitiesCard";
import AvailabilityCard from "components/Caregiver/AvailabilityCard";
import PersonalPreferencesCard from "components/Caregiver/PersonalPreferencesCard";
import ServicePreferencesCard from "components/Caregiver/ServicePreferencesCard";
import FinancialInfoCard from "components/Caregiver/FinancialInfoCard";
import ProfileCard from "components/ProfileCard";
import TrainingsCard from "components/Caregiver/TrainingsCard";
import { useDispatch } from "react-redux";
import { useSnackbar } from "components/SnackbarProvider";
import { useLoading } from "components/LoadingProvider";
import { CaregiverStatuses } from "CommonData/Common";

const CaregiverDetails = () => {
  const { caregiver_id } = useParams();
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const darkerPrimary = darken(primaryColor, 0.5);
  const {
    data: caregiverData,
    isLoading: isCaregiverLoading,
    error,
    refetch,
  } = useGetCaregiverByIdQuery(caregiver_id, { skip: !caregiver_id });
  const [
    updateCG,
    { isUpdating, isUpdateSuccess, isUpdateError, updateError },
  ] = useUpdateCaregiverMutation();
  const [caregiverDetails, setCaregiverDetails] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();

  const [downloadResume, setDownloadResume] = useState(false); // State to trigger resume download

  const schedule = () => {
    const previousURL = encodeURIComponent(location.pathname);
    navigate(`${location.pathname}/appointment?redirect=${previousURL}`);
  };

  // Fetch caregiver data on load
  useEffect(() => {
    if (caregiverData) refetch();
  }, [caregiverData]);

  // useFetchResumeFileQuery to get resume data based on downloadResume state
  const {
    data: resumeData,
    error: resumeError,
    isFetching: isFetchingResume,
  } = useFetchResumeFileQuery(
    downloadResume ? { fileUrl: caregiverData?.resumeFile } : null,
    { skip: !downloadResume }
  );
  // Loading state management
  useEffect(() => {
    if (isCaregiverLoading || isUpdating || isFetchingResume) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isCaregiverLoading, isUpdating, isFetchingResume]);

  // Error handling
  useEffect(() => {
    if (error || updateError) {
      showSnackbar(error?.status || updateError?.status, "error");
    }
  }, [error, updateError]);

  // Set caregiver details after fetching
  useEffect(() => {
    if (caregiverData) {
      const { created_at, updated_at, ...filteredData } = caregiverData;
      setCaregiverDetails(filteredData);
    }
  }, [caregiverData]);

  const handleChangeEvent = (e) => {
    setCaregiverDetails({
      ...caregiverDetails,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleUpdate = (obj) =>{
    console.log(obj)
    setCaregiverDetails({
      ...caregiverDetails,
      ...obj
    })
  }

  const handleChangeNameValue = (name, value) => {
    setCaregiverDetails({
      ...caregiverDetails,
      [name]: value,
    });
  };

  const handleEditUpdate = () => {
    setIsEditing((prevIsEditing) => {
      if (prevIsEditing) handleSave();
      return !prevIsEditing;
    });
  };

  const handleSave = async () => {
    startLoading();
    try {
      const formData = new FormData();
      const fieldsToAppend = [
        "firstName",
        "lastName",
        "contactNumber",
        "email",
        "resumeFile",
        "preferredLocation",
        "comments",
        "status",
      ];

      // fieldsToAppend.forEach((field) => {
      //   if (
      //     caregiverDetails.hasOwnProperty(field) &&
      //     caregiverDetails[field] !== null &&
      //     caregiverDetails[field] !== undefined
      //   ) {
      //     // Check if it's the 'resumeFile' field and if it's a string, skip appending
      //     // this is required because if resumefile is not changed (not a file object) it gives error
      //     //resumeFile is string wen retrieved from db and file object wen changed.
      //     if (
      //       field === "resumeFile" &&
      //       typeof caregiverDetails.resumeFile === "string"
      //     ) {
      //       return; // Skip appending the resumeFile if it's a string
      //     }

      //     const value = caregiverDetails[field];
      //     formData.append(field, value);
      //   }
      // });
      Object.entries(caregiverDetails).forEach(([field, value]) => {
        if (value !== null && value !== undefined) {
          if (typeof value === "object" && !Array.isArray(value)) {
            // Convert objects to JSON strings
            value = JSON.stringify(value);
          }
      
          // Skip 'resumeFile' if it's a string
          if (field === "resumeFile" && typeof value === "string") {
            return;
          }
      
          formData.append(field, value);
        }
      });
      
      const result = await updateCG({id: caregiverDetails.id, updatedCaregiver: formData}).unwrap();
      if (result.error) throw result.error;
      showSnackbar("Caregiver details updated successfully", "success");
    } catch (err) {
      console.error("Failed to update Caregiver details:", err);
      showSnackbar("Failed to update Caregiver details", "error");
    } finally {
      stopLoading();
      const params = new URLSearchParams(location.search);
      const previousURL = params.get("redirect");
      if (previousURL) {
        navigate(previousURL);
      } else {
        navigate("/caregivers");
      }
    }
  };

  // Trigger resume download
  const handleDownloadResume = () => {
    setDownloadResume(true); // Set state to trigger the download
  };

  useEffect(() => {
    if (resumeData && downloadResume) {
      // Create a blob URL and specify the correct MIME type if needed
      const url = window.URL.createObjectURL(
        new Blob([resumeData], {
          type: resumeData.type || "application/octet-stream",
        })
      );

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;

      // Set the file name for download using resumeFile or a default name
      const fileName =
        caregiverData?.resumeFile.split("/").pop() || "downloadedFile";
      link.setAttribute("download", fileName);

      // Append the link to the DOM
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link from the DOM
      link.parentNode.removeChild(link);

      // Revoke the object URL to free up memory
      window.URL.revokeObjectURL(url);

      // Reset download state
      setDownloadResume(false);
    } else if (resumeError) {
      // Show an error message if the download fails
      showSnackbar("Failed to download the resume", "error");
      setDownloadResume(false);
    }
  }, [resumeData, resumeError, caregiverData, downloadResume]);

  if (isCaregiverLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Fab
        variant="extended"
        sx={{ position: "fixed", top: 150, right: 50, zIndex: 1000 }}
        color="secondary"
        onClick={handleEditUpdate}
      >
        {isEditing ? (
          <>
            <UpdateIcon sx={{ mr: 1 }} />
            Update
          </>
        ) : (
          <>
            <EditIcon sx={{ mr: 1 }} />
            Edit
          </>
        )}
      </Fab>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            height: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            startIcon={<CalendarTodayIcon />}
            onClick={schedule}
            sx={{ bgcolor: darkerPrimary }}
          >
            Schedule Interview
          </Button>

          {/* Add a button to download the resume file */}
          {/* <Button
            variant="outlined"
            color="primary"
            onClick={handleDownloadResume}
            disabled={!caregiverData?.resumeFile}
          >
            Download Resume
          </Button> */}
        </Grid>

        <Grid item xs={3}>
          <ProfileCard
            profile={caregiverData}
            handleChange={handleChangeNameValue}
            statusOptions={CaregiverStatuses}
            isEditing={isEditing}
          />
        </Grid>
        <Grid item xs={9}>
          <PersonalInfoCard
            handleUpdate={handleUpdate}
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeEvent}
            handleChangeNameValue={handleChangeNameValue}
          />
        </Grid>
        <Grid item xs={12}>
          <ProfessionalBackgroundCard
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeNameValue}
          />
        </Grid>
        <Grid item xs={12}>
          <LegalComplianceCard
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeNameValue}
          />
        </Grid>
        <Grid item xs={12}>
          <HealthSafetyCard
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeNameValue}
          />
        </Grid>
        <Grid item xs={12}>
          <SkillsAbilitiesCard
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeNameValue}
          />
        </Grid>
        <Grid item xs={12}>
          <AvailabilityCard
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeNameValue}
          />
        </Grid>
        <Grid item xs={12}>
          <PersonalPreferencesCard
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeNameValue}
          />
        </Grid>
        <Grid item xs={12}>
          <ServicePreferencesCard
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeNameValue}
          />
        </Grid>
        <Grid item xs={12}>
          <FinancialInfoCard
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeNameValue}
          />
        </Grid>
        <Grid item xs={12}>
          <TrainingsCard
            caregiverDetails={caregiverDetails}
            isEditing={isEditing}
            handleChange={handleChangeNameValue}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CaregiverDetails;
