import { Box } from "@mui/material";
import { getColor } from "common/utilities";

const caregiversColumns = [
  {
    accessorKey: 'id',
    header: 'Caregiver Id',
    size: 20,
  },
  {
    accessorKey: 'firstName',
    header: 'First Name',
    size: 150,
  },
  {
    accessorKey: 'lastName',
    header: 'Last Name',
    size: 150,
  },
  {
    accessorKey: 'contactNumber',
    header: 'Contact No.',
    size: 150,
  },
  {
    accessorKey: 'email',
    header: 'Email Id',
    size: 200,
  },
  {
    accessorKey: 'permanent_address_line1',
    header: 'Address Line 1',
    size: 200,
  },
  {
    accessorKey: 'permanent_address_line2',
    header: 'Address Line 2',
    size: 200,
  },
  {
    accessorKey: 'gender',
    header: 'Gender',
    size: 100,
  },
  {
    accessorKey: 'shift_preference',
    header: 'Shift Preference',
    size: 150,
  },
  {
    accessorKey: 'marital_status',
    header: 'Marital Status',
    size: 150,
  },
  {
    accessorKey: 'religion',
    header: 'Religion',
    size: 100,
  },
  {
    accessorKey: 'preferredLocation',
    header: 'Preferred Location',
    size: 200,
  },
  {
    accessorKey: 'dateOfAvailability',
    header: 'Date of Availability',
    size: 150,
  },
  {
    accessorKey: 'dateOfBirth',
    header: 'Date of Birth',
    size: 150,
  },
  {
    accessorKey: 'availabilityStartTime',
    header: 'Availability Start Time',
    size: 150,
  },
  {
    accessorKey: 'availabilityEndTime',
    header: 'Availability End Time',
    size: 150,
  },
  {
    accessorKey: 'qualityScore',
    header: 'Quality Score',
    size: 100,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 150,
    
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          fontSize:12,
          backgroundColor: getColor(cell.getValue()),
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {cell.getValue()}
      </Box>
    ),
  },
  {
    accessorKey: 'experienceLevel',
    header: 'Experience Level',
    size: 150,
  },
  {
    accessorKey: 'comments',
    header: 'Comments',
    size: 250,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    size: 150,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    size: 150,
  },
];

const caregiversColumnVisibility = {
  permanent_address_line1: false,
  permanent_address_line2: false,
  dateOfAvailability:false,
  shift_preference:false,
  qualityScore:false,
  experienceLevel:false,
  marital_status: false,
  religion: false,
  preferredLocation: false,
  dateOfBirth: false,
  availabilityStartTime: false,
  availabilityEndTime: false,
  comments: false,
  updated_at: false,
  created_at: false,
};

const caregiversSorting = [{ id: 'updated_at', desc: true }];

export { caregiversColumns, caregiversColumnVisibility, caregiversSorting };
