import React from "react";
import { Card, CardContent, Typography, Box, LinearProgress } from "@mui/material";
import { ClientStatuses } from "CommonData/Common";

function LinearProgressWithLabel({ value, label, color }) {
  const lightenedBackgroundColor = color + "33";
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box width="40%" mr={1}>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </Box>
      <Box width="100%" mr={1}>  
        <LinearProgress
          variant="determinate"
          value={value}
          style={{ height: 10, borderRadius: 5, backgroundColor: lightenedBackgroundColor }}
          sx={{ "& .MuiLinearProgress-bar": { backgroundColor: color } }}
        />
      </Box>
      <Box minWidth={55}>
        <Typography variant="body2" color="textSecondary">
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function ClientsOverviewCard({values}) {
  
  const colorFills = [
    "#00c851", // Green
    "#33b5e5", // Blue
    "#ffbb33", // Yellow
    "#ff4444", // Red
    "#aa66cc" // Purple
  ];

  return (
    <Card sx={{ borderRadius: "13px", padding: 3 }}>
      <CardContent>
      <Box sx={{ textAlign: "center", marginBottom: 2 }}>
        <Typography variant="h6" >
          Clients Overview 
        </Typography>
      </Box>
      {Object.entries(values).slice(0, 5).map(([stage, value], index) => (
          <LinearProgressWithLabel
            key={stage}
            value={value}
            label={stage} // Fallback to stage if not found in labels
            color={colorFills[index] } // Fallback color if not found in colorFills
          />
        ))}
      </CardContent>
    </Card>
  );
}

export default ClientsOverviewCard;
