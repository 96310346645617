// src/services/billingApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueryWithReauth";
import { tagTypes } from "./tags";

const billingApi = createApi({
  reducerPath: "billingApi",
  baseQuery: baseQueryWithReauth,
  tagTypes,
  endpoints: (builder) => ({
    getBillings: builder.query({
      query: () => "billings/",
      providesTags: ["Billing"],
      keepUnusedDataFor: 300,
    }),
    getBillingById: builder.query({
      query: (id) => `billings/${id}/`,
      providesTags: (result, error, id) => [{ type: "Billing", id }],
    }),
    addBilling: builder.mutation({
      query: (newBilling) => ({
        url: "billings/",
        method: "POST",
        body: newBilling,
      }),
      invalidatesTags: ["Billing", "Client"],
    }),
    updateBilling: builder.mutation({
      query: ({ id, ...updatedBilling }) => ({
        url: `billings/${id}/`,
        method: "PATCH",
        body: updatedBilling,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Billing", id },
        "Billing",
        "Client",
      ],
    }),
    deleteBilling: builder.mutation({
      query: (id) => ({
        url: `billings/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "Billing", id },
        "Billing",
        "Client",
      ],
    }),
  }),
});

export const {
  useGetBillingsQuery,
  useGetBillingByIdQuery,
  useAddBillingMutation,
  useUpdateBillingMutation,
  useDeleteBillingMutation,
} = billingApi;

export default billingApi;
