import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const handleClose = () => {
    setOpen(false);
  };

  const showSnackbar = (message, severity = 'info') => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const hideSnackbar = () => {
    setMessage("");
    setSeverity("info");
    setOpen(false);
  };
  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}
        anchorOrigin={{ vertical:"top", horizontal: "center" }}
        >
          <div>
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
          </div>
        </Snackbar>
    </SnackbarContext.Provider>
  );
};
