import { Box, Chip } from "@mui/material";
import { getColor } from "common/utilities";

const appointmentsColumns = [
  {
    accessorKey: 'id',
    header: 'Appointment Id',
    size: 20,
  },
  {
    accessorKey: 'title',
    header: 'Title',
    size: 150,
  },
  {
    accessorKey: 'meeting_type',
    header: 'Meeting Type',
    size: 150,
  },
  {
    accessorKey: 'appointment_date',
    header: 'Appointment Date',
    size: 150,
  },
  {
    accessorKey: 'from_time',
    header: 'From Time',
    size: 150,
  },
  {
    accessorKey: 'to_time',
    header: 'To Time',
    size: 150,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 150,
    
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          fontSize: 12,
          backgroundColor: getColor(cell.getValue()),
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {cell.getValue()}
      </Box>
    ),
  },
  {
    accessorKey: 'client',
    header: 'Client',
    size: 150,
    
    Cell: ({ cell }) => (
      <Chip
        label={`Client ${cell.getValue()}`}
        component="a"
        href={`/clients/${cell.getValue()}`}
        color="info"
        onClick={(event) => event.stopPropagation()}
        clickable
      />
    ),
  },
  {
    accessorKey: 'invitation_text',
    header: 'Invitation Text',
    size: 200,
  },
  {
    accessorKey: 'notes',
    header: 'Notes',
    size: 250,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    size: 150,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    size: 150,
  },
];

const appointmentsColumnVisibility = {
  title:false,
  client:false,
  invitation_text: false,
  notes: false,
  updated_at: false,
  created_at: false,
};

const appointmentsSorting = [{ id: 'updated_at', desc: true }];

export { appointmentsColumns, appointmentsColumnVisibility, appointmentsSorting };
