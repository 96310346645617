import { styled, TextField } from '@mui/material';
import theme from 'Theme';

const ITextField = styled((props) => (
  <TextField
    {...props}
    InputLabelProps={{
      ...props.InputLabelProps,
      className: props.InputLabelProps?.className || '',
    }}
    InputProps={{
      ...props.InputProps,
      className: props.InputProps?.className || '',
    }}
  />
))({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'grey', // Outline color
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main, // Outline color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main, // Outline color when focused
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.paper} inset`,
      WebkitTextFillColor: theme.palette.text.primary,
    }
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main, // Label color when focused
  },
  '& .MuiInputLabel-root': {
    color: 'grey', // Change label color here
  },
  '& .MuiInputBase-input': {
    color: 'white', // Change input text color
  },
});

export default ITextField;
