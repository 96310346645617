import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Radio,
  Chip,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AccessibleRoundedIcon from "@mui/icons-material/AccessibleRounded";
import HailRoundedIcon from "@mui/icons-material/HailRounded";
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";
import {
  useGetClientByIdQuery,
  useGetClientsQuery,
  useUpdateClientMutation,
} from "services/clientApi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useAddAppointmentMutation, useUpdateAppointmentMutation } from "services/appointmentApi";
import { useSnackbar } from "./SnackbarProvider";
import { useLoading } from "./LoadingProvider";
import { useGetCaregiversQuery } from "services/caregiverApi";
import { useGetUsersQuery } from "services/userApi";
import { CaregiverStatuses, ClientStatuses, meetingTypes, PatientStatuses } from "CommonData/Common";

const UpdateAppointment = ({ updateAppt }) => {
  const [patients, setPatients] = useState([]);
  const [clients, setClients] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const { data: clientsData, error, isLoading } = useGetClientsQuery();
  const { data: cgs, cgserror, iscgsLoading } = useGetCaregiversQuery();
  const { data: staffsdata, staffserror, isstaffsLoading } = useGetUsersQuery();

  const {
    data: updateApptClient,
    isLoading: isupdateApptloading,
    isupdateAppterror,
  } = useGetClientByIdQuery(updateAppt.client, { skip: !updateAppt?.client });

  const [emails, setEmails] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedClient, setSelectedClient] = useState(null);
  const [emailContent, setEmailContent] = useState();
  const [anchorElPatient, setAnchorElPatient] = useState(null);
  const [anchorElClient, setAnchorElClient] = useState(null);
  const [anchorElCaregiver, setAnchorElCaregiver] = useState(null);
  const [anchorElStaff, setAnchorElStaff] = useState(null);
  const [selectedPatientids, setSelectedPatientids] = useState(updateAppt?.patients || []);
  const [selectedCaregiverids, setSelectedCaregiverids] = useState(updateAppt?.caregivers || []);
  const [staffs, setStaffs] = useState([]);
  const [selectedStaffids, setSelectedStaffids] = useState(updateAppt?.staffs || []);
  const [meetingType, setMeetingType] = useState(updateAppt?.meeting_type);
  const today = dayjs().format("YYYY-MM-DD");
  const [
    updateAppointment,
    { isLoading_addapp, isSuccess, isError, error_addapp },
  ] = useUpdateAppointmentMutation();
  const [updateClient, { isLoading: isUpdating, error: updateError }] =
    useUpdateClientMutation();

  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();

  const [timingFrom, setTimingFrom] = useState(dayjs());
  const [timingTo, setTimingTo] = useState(dayjs());

  
  // useEffect(() => {
  //   if (selectedClient) {
  //     // setTimingFrom(dayjs(client.availabilityStartTime, "HH:mm:ss"));
  //     // setTimingTo(dayjs(client.availabilityEndTime, "HH:mm:ss"));
  //   }
  // }, [selectedClient]);

  useEffect(() => {
    if (updateApptClient) {
      setSelectedClient(updateApptClient);
    }
  }, [updateApptClient]);

  useEffect(() => {
    if (clientsData) {
      // setDisableClientSelection(false);
      setClients(clientsData);
      
    }
  }, [clientsData]);
  useEffect(() => {
    if (staffsdata) {
      // setDisableClientSelection(false);
      setStaffs(staffsdata);      
    }
  }, [staffsdata]);

   
  useEffect(()=>{
    if (meetingType == meetingTypes.clientCaregiverInterview.name)
    {
      // setSelectedCaregiverids([]);
      const available_cgs = cgs?.filter(cg => cg.status == CaregiverStatuses.AVAILABLE.name)
      
      setCaregivers(available_cgs);
    }
    else{
      if(cgs){
        setCaregivers(cgs)
      }
    }
  }, [meetingType,cgs])

  const handleAddPatient = () => {
    setPatients([...patients, ""]);
  };

  useEffect(() => {
    if (isLoading || iscgsLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [iscgsLoading, isLoading]);

  useEffect(() => {
    if (error || cgserror) {
      showSnackbar(error?.status || cgserror?.status, "error");
    }
  }, [error, cgserror]);

  useEffect(() => {
    if (updateAppt) {
      setTitle(updateAppt.title);
      setDate(dayjs(updateAppt.appointment_date, "YYYY-MM-DD"));
      setTimingFrom(dayjs(updateAppt.from_time, "HH:mm:ss"));
      setTimingTo(dayjs(updateAppt.to_time, "HH:mm:ss"));
      setMeetingType(updateAppt.meeting_type);
      setEmailContent(updateAppt.invitation_text);
      if (updateAppt.emails) {
        setEmails(updateAppt.emails.join(";"));
      }
    }
  }, []);

  const handlePatientChange = (index, value) => {
    const newPatients = [...patients];
    newPatients[index] = value;
    setPatients(newPatients);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handlePopoverOpenPatient = (event) => {
    setAnchorElPatient(event.currentTarget);
  };

  const handlePopoverOpenCaregiver = (event) => {
    setAnchorElCaregiver(event.currentTarget);
  };
  const handlePopoverOpenStaff = (event) => {
    setAnchorElStaff(event.currentTarget);
  };

  const handlePopoverClosePatient = () => {
    setAnchorElPatient(null);
  };

  const handlePopoverCloseCaregiver = () => {
    setAnchorElCaregiver(null);
  };

  const handlePopoverCloseStaff = () => {
    setAnchorElStaff(null);
  };

  const handleTogglePatient = (value) => {
    const currentIndex = selectedPatientids.indexOf(value);
    const newSelectedPatients = [...selectedPatientids];

    if (currentIndex === -1) {
      newSelectedPatients.push(value);
    } else {
      newSelectedPatients.splice(currentIndex, 1);
    }

    setSelectedPatientids(newSelectedPatients);
  };

  const schedule = async () => {
    const data = {
      id: updateAppt.id,
      title: title,
      appointment_date: date.format("YYYY-MM-DD"),
      from_time: timingFrom.format("HH:mm"),
      to_time: timingTo.format("HH:mm"),
      meeting_type: meetingType,
      status: "scheduled",
      invitation_text: emailContent,
      client: selectedClient ? selectedClient.id : "",
      patients: selectedPatientids,
      emails: emails ? emails.split(/[;,]\s*/) : [],
      staffs:selectedStaffids,
      caregivers: selectedCaregiverids
    };
    // console.log(data)
    startLoading();
    try {
      if (meetingType.name == meetingTypes.initialClientMeet.name) {
        if (selectedClient) {
          data.client_status = ClientStatuses.DISCUSSION.name;
        }
      } else if (meetingType.name == meetingTypes.initialCaregiverMeet.name) {
        if (selectedCaregiverids.length > 0) {
          data.cg_status = CaregiverStatuses.INTERNAL_INTERVIEW.name;
        }
      } else if (
        meetingType.name == meetingTypes.clientCaregiverInterview.name
      ) {
        if (selectedCaregiverids.length > 0) {
          data.cg_status = CaregiverStatuses.CLIENT_INTERVIEW.name;
        }
        if (selectedPatientids.length > 0) {
          data.patient_status = PatientStatuses.CG_INTERVIEW.name;
        }
        if (selectedClient) {
          data.client_status = ClientStatuses.CG_INTERVIEW.name;
        }
      }
      const result = await updateAppointment(data);
      if (result.error) {
        throw result.error;
      }
      if (selectedClient) {
        const updateData = {
          id: selectedClient.id,
          status: ClientStatuses.DISCUSSION.name
        };
        const updateresult = await updateClient(updateData);
        if (updateresult.error) {
          throw updateresult.error;
        }
      }
      showSnackbar("Appointment updated successfully", "success");
    } catch (err) {
      console.error("Failed to update appointment:", err);
      showSnackbar("Failed to update appointment", "error");
    } finally {
      stopLoading();
      const params = new URLSearchParams(location.search);
      const previousURL = params.get('redirect');
      if (previousURL) {
        navigate(previousURL);
      } else {
        navigate('/clients'); // Fallback URL if no previous URL is stored
      }
    }
  };

  const handleToggleStaff = (value) => {
    const currentIndex = selectedStaffids.indexOf(value);
    const newSelectedStaffs = [...selectedStaffids];

    if (currentIndex === -1) {
      newSelectedStaffs.push(value);
    } else {
      newSelectedStaffs.splice(currentIndex, 1);
    }

    setSelectedStaffids(newSelectedStaffs);
  };

  const handleToggleCaregiver = (value) => {
    const currentIndex = selectedCaregiverids.indexOf(value);
    const newSelectedCaregivers = [...selectedCaregiverids];

    if (currentIndex === -1) {
      newSelectedCaregivers.push(value);
    } else {
      newSelectedCaregivers.splice(currentIndex, 1);
    }

    setSelectedCaregiverids(newSelectedCaregivers);
  };

  const handleOkClickPatient = () => {
    // setEmail(selectedPatients.join(", "));
    handlePopoverClosePatient();
  };

  const handleOkClickStaff = () => {
    // setEmail(selectedStaffids.join(", "));
    handlePopoverCloseStaff();
  };
  const handleOkClickCaregiver = () => {
    // setEmail(selectedCaregivers.join(", "));
    handlePopoverCloseCaregiver();
  };
  const handlePopoverOpenClient = (event) => {
    setAnchorElClient(event.currentTarget);
  };

  const handlePopoverCloseClient = () => {
    setAnchorElClient(null);
  };

  const handleSelectClient = (value) => {
    setSelectedClient(value);
    handlePopoverCloseClient();
  };
  const openPatient = Boolean(anchorElPatient);
  const openCaregiver = Boolean(anchorElCaregiver);
  const openStaff = Boolean(anchorElStaff);
  const openClient = Boolean(anchorElClient);
  const [date, setDate] = useState(dayjs());
  const [title, setTitle] = useState(
    "Scheduling a Meeting Regarding caretaking"
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
        <Typography variant="h6">Appointment Schedule</Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              label="Client"
              value={"Client Name: Shank"}
              fullWidth
              disabled
            />
          </Grid> */}
          <Grid item xs={4}>
            <DatePicker
              label="Date"
              value={date}
              onChange={(newValue) => setDate(newValue)}
              slotProps={{ textField: { fullWidth: true } }}
              // renderInput={(props) => <TextField {...props} fullWidth />}
            />
          </Grid>
          <Grid item xs={4}>
            <TimePicker
              label="Timing From"
              value={timingFrom}
              slotProps={{ textField: { fullWidth: true } }}
              onChange={(newValue) => setTimingFrom(newValue)}
              // renderInput={(props) => <TextField {...props} fullWidth />}
            />
          </Grid>
          <Grid item xs={4}>
            <TimePicker
              label="Timing To"
              value={timingTo}
              onChange={(newValue) => setTimingTo(newValue)}
              slotProps={{ textField: { fullWidth: true } }}
              // renderInput={(props) => <TextField {...props} fullWidth />}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              startIcon={
                isLoading ? <CircularProgress size={20} /> : <AddIcon />
              }
              fullWidth
              disabled={clients.length == 0}
              sx={{ borderRadius: "20px" }} // Adjust the border radius as needed
              onClick={handlePopoverOpenClient}
            >
              Client
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              fullWidth
              disabled={
                !(selectedClient && selectedClient.patients?.length > 0)
              }
              startIcon={<AddIcon />}
              sx={{ borderRadius: "20px" }} // Adjust the border radius as needed
              onClick={handlePopoverOpenPatient}
            >
              {selectedClient && selectedClient.patients?.length > 0
                ? "Patient"
                : "No Patients"}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              disabled={caregivers?.length == 0}
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handlePopoverOpenCaregiver}
              sx={{ borderRadius: "20px" }} // Adjust the border radius as needed
            >
              {
                caregivers?.length == 0 ? "No Caregivers" : "Caregiver"
              }
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handlePopoverOpenStaff}
              sx={{ borderRadius: "20px" }} // Adjust the border radius as needed
            >
              Staff
            </Button>
          </Grid>
          <Grid item xs={12}>
            {selectedClient && (
              <Chip
                label={
                  "Client: " +
                  selectedClient?.firstName +
                  ", " +
                  selectedClient?.lastName
                }
                onDelete={() => setSelectedClient(null)}
                color="warning"
                variant="contained"
                sx={{ m: 0.5 }}
              />
            )}

            {/* Selected Patients Chips */}
            {selectedClient?.patients && selectedPatientids.map((pid) => {
              const patient = selectedClient?.patients?.find(p => p.id == pid);
              return (
              <Chip
                key={pid}
                label={`Patient: ${patient?.firstName}, ${patient?.lastName}`}
                onDelete={() => handleTogglePatient(pid)}
                color="error"
                variant="contained"
                sx={{ m: 1 }}
              />
            )})}

            {/* Selected Caregivers Chips */}
            {selectedCaregiverids.map((cgid) => {
              const caregiver = cgs?.find(cg => cg.id === cgid);
              return (
                caregiver && (
                  <Chip
                    key={cgid}
                    label={`Caregiver: ${caregiver.firstName}, ${caregiver.lastName}`}
                    onDelete={() => handleToggleCaregiver(cgid)}
                    color="info"
                    variant="contained"
                    sx={{ m: 0.5 }}
                  />
                )
              );
            })}            {/* Selected Staff Chips */}
            {selectedStaffids.map((sid) => {
              const s = staffs.find(s => s.id === sid)
              return (
                s && (
              <Chip
                key={s.id}
                label={"Staff: " + s.username}
                onDelete={() => handleToggleStaff(s.id)}
                color="success"
                variant="contained"
                sx={{ m: 0.5 }}
              />
            )
          );
        })}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Other EmailIds (optional)"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              fullWidth
            />
            {/* <IconButton onClick={handlePopoverOpenPatient} color="primary">
              <AccessibleRoundedIcon />
            </IconButton>
            <IconButton onClick={handlePopoverOpenCaregiver} color="primary">
              <HailRoundedIcon />
            </IconButton> */}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              disabled={true}
              value={meetingType}
              onChange={(event, newValue) => {
                setMeetingType(newValue ?? "");
              }}
              inputValue={meetingType}
              onInputChange={(event, newInputValue) => {
                setMeetingType(newInputValue ?? "");
              }}
              options={Object.values(meetingTypes).map(type => type.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meeting Type"                  
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email Content"
              value={emailContent}
              multiline
              rows={10}
              onChange={(e) => setEmailContent(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="contained" type="submit" onClick={schedule}>
              Update Appointment
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Popover
        open={openPatient}
        anchorEl={anchorElPatient}
        onClose={handlePopoverClosePatient}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {selectedClient?.patients &&
            selectedClient.patients.map((p) => (
              <ListItem
                key={p.id}
                role={undefined}
                dense
                onClick={() =>
                  handleTogglePatient(p.firstName + ", " + p.lastName)
                }
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={
                      selectedPatientids.indexOf(
                        p.firstName + ", " + p.lastName
                      ) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={p.firstName + ", " + p.lastName} />
              </ListItem>
            ))}
        </List>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleOkClickPatient}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Popover>
      <Popover
        open={openClient}
        anchorEl={anchorElClient}
        onClose={handlePopoverCloseClient}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {clients.map((client) => (
            <ListItem
              key={client.id}
              role={undefined}
              dense
              style={{ cursor: "pointer" }}
              onClick={() => handleSelectClient(client)} // Handle selection directly
            >
              {/* <ListItemIcon>
                <Radio // Use Radio instead of Checkbox for single selection
                  checked={selectedClient === client.id} // Check if this client is selected
                  onChange={() => handleSelectClient(client.id)} // Handle selection
                />
              </ListItemIcon> */}
              <ListItemText
                primary={
                  client.firstName +
                  ", " +
                  client.lastName +
                  " (" +
                  client.id +
                  ")"
                }
              />
            </ListItem>
          ))}
        </List>
      </Popover>

      <Popover
        open={openCaregiver}
        anchorEl={anchorElCaregiver}
        onClose={handlePopoverCloseCaregiver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
      <List>
        {caregivers.map((cg) => (
          <ListItem
            key={cg.id}
            role={undefined}
            dense
            style={{ cursor: "pointer" }}
            onClick={() => handleToggleCaregiver(cg.id)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={selectedCaregiverids.indexOf(cg.id) !== -1}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={
                cg.firstName +
                ", " +
                cg.lastName +
                " (" +
                cg.id +
                ")"
              } />
          </ListItem>
        ))}
      </List>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleOkClickCaregiver}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Popover>

      <Popover
        open={openStaff}
        anchorEl={anchorElStaff}
        onClose={handlePopoverCloseStaff}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {staffs.map((s) => (
            <ListItem
              key={s.id}
              role={undefined}
              dense
              onClick={() => handleToggleStaff(s.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedStaffids.indexOf(s.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={
                  s.username +
                  " (" +
                  s.id +
                  ")"
                } />
            </ListItem>
          ))}
        </List>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleOkClickStaff}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Popover>
    </LocalizationProvider>
  );
};

export default UpdateAppointment;
