import React, { useRef } from 'react';
import { Typography, Box, styled } from '@mui/material';
import Header from './Header';
import SectionOne from './SectionOne';
import { Outlet, useLocation } from 'react-router-dom';
import LoginForm from 'components/LoginForm';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import MeetTheTeam from './MeetTheTeam';
import ServiceSection from './ServiceSection';
import Footer from './Footer';
import ReactGA from 'react-ga';


const NewLandingLayout = () => {
  const aboutRef = useRef(null); 
  const servicesRef = useRef(null); 
  const teamsRef = useRef(null); 
  const storyRef = useRef(null); 
  
  const location = useLocation();

  React.useEffect(() => {
    // Track page views on route change
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
  <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', overflowY: 'hidden' ,overflowX: 'hidden'}}>
    <Header aboutRef={aboutRef} servicesRef={servicesRef} teamsRef={teamsRef} storyRef={storyRef}/>
    <Box component="main" sx={{ flex: 1, overflowY: 'hidden' ,overflowX: 'hidden' }}>
      <Box sx={{ width: '100%', maxWidth: 'none', p:0 }}>
        <SectionOne /> 
        <SectionTwo aboutRef={aboutRef}/>
        <SectionThree storyRef={storyRef}/>
        <MeetTheTeam teamsRef={teamsRef}/>
        <ServiceSection servicesRef={servicesRef}/>
      </Box>
    </Box>
    <Footer />
  </Box>
)};

export default NewLandingLayout;
