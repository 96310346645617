// src/services/api.js
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueryWithReauth";
import { tagTypes } from "./tags";

const patientApi = createApi({
  reducerPath: "patientsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes, // Add the relevant tag types
  endpoints: (builder) => ({
    getPatients: builder.query({
      query: () => "patients/",
      providesTags: ["Patient"],
      keepUnusedDataFor: 300,
    }),
    getPatientById: builder.query({
      query: (id) => `patients/${id}/`,
      providesTags: (result, error, id) => [{ type: "Patient", id }],
    }),
    addPatient: builder.mutation({
      query: (newPatient) => ({
        url: "patients/",
        method: "POST",
        body: newPatient,
      }),
      invalidatesTags: (result, error, { client }) => [
        "Patient",
        ...(client ? [{ type: "Client", id: client }] : []),
      ],
    }),
    updatePatient: builder.mutation({
      query: ({ id, ...updatedPatient }) => ({
        url: `patients/${id}/`,
        method: "PATCH",
        body: updatedPatient,
      }),
      invalidatesTags: (result, error, { id, client }) => [
        { type: "Patient", id },
        "Patient",
        ...(client ? [{ type: "Client", id: client }] : []),
      ],
    }),
    deletePatient: builder.mutation({
      query: (id) => ({
        url: `patients/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "Patient", id },
        "Patient",
      ],
    }),
  }),
});

export const {
  useGetPatientsQuery,
  useGetPatientByIdQuery,
  useAddPatientMutation,
  useUpdatePatientMutation,
  useDeletePatientMutation,
} = patientApi;
export default patientApi;
