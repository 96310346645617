import { Box, Chip } from "@mui/material";
import { getColor } from "common/utilities";
import { Link } from "react-router-dom";

const patientsColumns = [
  {
    accessorKey: 'id',
    header: 'Patient Id',
    size: 20,
  },
  {
    accessorKey: 'firstName',
    header: 'First Name',
    size: 150,
  },
  {
    accessorKey: 'lastName',
    header: 'Last Name',
    size: 150,
  },
  {
    accessorKey: 'client',
    header: 'Client',
    size: 100,
    Cell: ({ cell }) => {
        const client = cell.getValue(); // Assuming this returns the client object
        return (
            <Chip
                label={client ? `${client.lastName}, ${client.firstName}` : 'No Client'}
                component={Link}
                to={client ? `/clients/${client.id}` : '#'} // Use client.id for the link
                clickable
                color="info"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                sx={{
                    color: '#fff',
                }}
            />
        );
    },
},
{
  accessorKey: 'caregiver',
  header: 'Assigned Caregiver',
  size: 100,
  Cell: ({ cell }) => {
      const caregiver = cell.getValue(); // Assuming this returns the caregiver object
      return (
          <Chip
              label={caregiver ? `${caregiver.lastName}, ${caregiver.firstName}` : "Not Assigned"}
              component={Link}
              to={caregiver ? `/caregivers/${caregiver.id}` : '#'} // Use caregiver.id for the link
              clickable
              disabled={!caregiver} // Disable chip if no caregiver is assigned
              color="success"
              onClick={(event) => {
                  event.stopPropagation();
              }}
              sx={{
                  color: '#fff',
              }}
          />
      );
  },
},

  {
    accessorKey: 'contactNumber',
    header: 'Contact No.',
    size: 150,
  },
  {
    accessorKey: 'email',
    header: 'Email Id',
    size: 150,
  },
  {
    accessorKey: 'address_line1',
    header: 'Address Line 1',
    size: 150,
  },
  {
    accessorKey: 'address_line2',
    header: 'Address Line 2',
    size: 150,
  },
  {
    accessorKey: 'city',
    header: 'City',
    size: 100,
  },
  {
    accessorKey: 'state',
    header: 'State',
    size: 100,
  },
  {
    accessorKey: 'country',
    header: 'Country',
    size: 100,
  },
  {
    accessorKey: 'zipcode',
    header: 'Zip Code',
    size: 100,
  },
  {
    accessorKey: 'age',
    header: 'Age',
    size: 100,
  },
  {
    accessorKey: 'gender',
    header: 'Gender',
    size: 100,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 100,
    
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          fontSize:12,
          backgroundColor: getColor(cell.getValue()),
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {cell.getValue()}
      </Box>
    ),
  },
  {
    accessorKey: 'diet',
    header: 'Diet',
    size: 100,
  },
  {
    accessorKey: 'religion',
    header: 'Religion',
    size: 100,
  },
  {
    accessorKey: 'relationshipToClient',
    header: 'Client Relation',
    size: 150,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    size: 150,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    size: 150,
  },
];

const patientsColumnVisibility = {
  address_line1: false,
  address_line2: false,
  lastName: false,
  state: false,
  country: false,
  email:false,
  zipcode: false,
  diet: false,
  religion: false,
  relationshipToClient:false,
  updated_at: false,
  created_at: false,
};

const patientsSorting = [{ id: 'updated_at', desc: true }];

export { patientsColumns, patientsColumnVisibility, patientsSorting };
