
// authUtils.js (create a new file for utility functions)
import {jwtDecode} from 'jwt-decode';


// src/utils/imagePreload.js

export const preloadImages = (imagePaths) => {
  imagePaths.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};


// Function to get the browser's timezone
function getTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  
  // Function to format a date string for display in browser's timezone
  export function formatdateForNote(dateTimeString) {
    const date = new Date(dateTimeString);
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true,
      timeZone: getTimezone(), // Using getTimezone function to get browser's timezone
    //   timeZoneName: 'short'
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    // return `${formattedDate} (${timezone})`;
    return formattedDate;
  }
  
  
  export function formatOnlyDate(dateTimeString) {
    try{
    const date = new Date(dateTimeString);
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      timeZone: getTimezone(), // Using getTimezone function to get browser's timezone
    //   timeZoneName: 'short'
    };
    //console.log(dateTimeString)
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    // return `${formattedDate} (${timezone})`;
    return formattedDate;
  }
  catch {
    return ""
  }

  }

  function decodeToken(token) {
    const payload = token.split('.')[1];
    const decoded = atob(payload);
    return JSON.parse(decoded);
  }
  
  function isTokenExpired(token) {
    if (!token) {
      return true;
    }
    const decoded = decodeToken(token);
    const expirationTime = decoded.exp * 1000; // Convert to milliseconds
    return Date.now() > expirationTime;
  }
  
  export const checkTokenExpiration = (accessToken) => {
    return isTokenExpired(accessToken);
  };

export const isAuthenticated = () => {
  const token = localStorage.getItem('access_token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // current time in seconds
      return decodedToken.exp > currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return false;
    }
  }
  return false;
};

export const getColor = (status) => {
  status = status ? status : ""
  switch (status.toLowerCase()) {
    case 'available':
      return '#5E72EB' // Blue
    case 'selected':
      return '#000000' // Blue
    case 'interview':
      return '#1C4E80' // Blue
    case 'rejected':
      return '#AC3E31' // Blue
    case 'scheduled':
      return '#3B95EE' // Blue
    case 'discussion':
      return '#61B665' // Blue
    case 'interested':
      return '#F39C12' // yellow
    case 'cancelled':
      return '#F44336' // Red
    case 'completed':
      return '#388e3c' // Green
    case 'active':
      return '#388e3c' 
    case 'internal interview':
      return '#B3C100' 
    case 'pending':
      return '#aa66cc' 
    case 'paid':
      return '#F39C12' // yellow
    case 'failed':
      return '#F44336' // Red
    case 'all':
      return '#E83874' // Grey
    case 'cg_interview':
    case 'cg interview':
      return '#CD7672' // Grey
    case 'client interview':
      return '#0091D5' // Grey
    case 'assigned':
      return '#aa66cc' 
    case 'cg selected':
      return '#aa66cc' 
    case 'inservice':
      return '#1824e1' 
    default:
      return '#7E9680' // Blue
  }
};

export function generateEmail(template, 
  RecipientName = "Valued Client",
  MeetingDate = "TBD",
  MeetingTime = "TBD"
) {
  // Define fixed variables
  const fixedVars = {
    MeetingLink: "https://meet.google.com/join/ikj8796jd",
    YourCompanyName: "AHGC Team"
  };

  // Get the template based on the key
  if (!template){
    return  { title : "", body: "" }
  }
  let { title, body } = template;

  // Create vars object with dynamic values and fixed values
  const vars = {
    RecipientName,
    MeetingDate,
    MeetingTime,
    ...fixedVars
  };

  // Replace the placeholders in the body with actual values from vars
  for (const key in vars) {
    const placeholder = new RegExp(`\\[${key}\\]`, 'g');
    body = body.replace(placeholder, vars[key]);
  }

  // Return the substituted title and body
  return { title, body };
}


