import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import PersonalInfo from "../../components/Patient/PersonalInfo";
import ProfileCard from "../../components/Patient/ProfileCard";
import PatientMedicalInfo from "components/PatientMedicalInfo";
import PatientAbilities from "components/PatientAbilities";
import CareNeeds from "components/CareNeeds";
import CaregiverPreference from "components/CaregiverPrefence";
import CaregiverLivingConditions from "components/CaregiverLivingConditions";
import {
  useAddPatientMutation,
  useGetPatientByIdQuery,
  useUpdatePatientMutation,
} from "services/patientApi";
import CohabitantsCard from "../../components/Patient/CohabitantsCard";
import { useLoading } from "components/LoadingProvider";
import { useSnackbar } from "components/SnackbarProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientStatuses } from "CommonData/Common";

const PatientDetails = ({ newPatient = false }) => {
  const [
    addPatient,
    { isLoading: isAdding, isAddsuccess, isAddError, addError },
  ] = useAddPatientMutation();
  const { patientId } = useParams();
  const {
    data: patientData,
    isLoading: gettingPatient,
    error: getError,
  } = useGetPatientByIdQuery(patientId, {
    skip: !patientId,
  });
  
  const [updatePatient, { isUpdating, isSuccess, isError, UpdateError }] =
    useUpdatePatientMutation();
  const [currentPatient, setCurrentPatient] = useState(null);

  useEffect(() => {
    if (patientData) {
      setCurrentPatient(patientData);
      setPatientInfo(patientData);
    }
  }, [patientData]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const client_id = params.get("client_id");

  const [patientInfo, setPatientInfo] = useState({
    client: client_id,
  });
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();

  const navigate = useNavigate();

  const isLoading = isAdding || isUpdating || gettingPatient;
  const error = addError || UpdateError || getError;

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);

  const handlePatientInfoChange = (info) => {
    setPatientInfo({ ...patientInfo, ...info });
  };

  const handleSave = async () => {
    try {
      startLoading();
      patientInfo.status = PatientStatuses.CREATED.name;
      const result = await addPatient(patientInfo);
      // console.log(patientInfo)
      if (result.error) {
        throw result.error;
      }
      showSnackbar("Patient added successfully", "success");
    } catch (err) {
      console.error("Failed to add patient", err);
      showSnackbar("Failed to add patient", "error");
    } finally {
      stopLoading();
      const previousURL = params.get("redirect");
      if (previousURL) {
        navigate(previousURL);
      } else {
        navigate("/patients"); // Fallback URL if no previous URL is stored
      }
    }
  };

  const handleUpdate = async () => {
    try {
      startLoading();
      // console.log(patientInfo)
      const result = await updatePatient(patientInfo);
      if (result.error) {
        throw result.error;
      }
      showSnackbar("Patient updated successfully", "success");
    } catch (err) {
      console.error("Failed to update patient", err);
      showSnackbar("Failed to update patient", "error");
    } finally {
      stopLoading();
      const previousURL = params.get("redirect");
      if (previousURL) {
        navigate(previousURL);
      } else {
        navigate("/patients"); // Fallback URL if no previous URL is stored
      }
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;
  return (
    <Grid container sx={{ mt: 2 }} spacing={0.5}>
      {newPatient ? (
        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "auto",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isAdding}
          >
            {isAdding ? "Creating..." : "Save"}
          </Button>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "auto",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdate}
            disabled={isAdding}
          >
            {isAdding ? "Updating..." : "Update"}
          </Button>
        </Grid>
      )}
      {!newPatient && (
        <Grid item xs={12} md={4}>
          <ProfileCard patientProfile={currentPatient} />
        </Grid>
      )}
      <Grid item xs={12} md>
        <PersonalInfo
          patientProfile={currentPatient}
          newPatient={newPatient}
          onChange={handlePatientInfoChange}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <PatientMedicalInfo
          patientProfile={currentPatient}
          newPatient={newPatient}
          onChange={handlePatientInfoChange}
        />
      </Grid>
      <Grid item xs={12}>
        <PatientAbilities
          patientProfile={currentPatient}
          newPatient={newPatient}
          onChange={handlePatientInfoChange}
        />
      </Grid>
      <Grid item xs={12}>
        <CareNeeds
          patientProfile={currentPatient}
          newPatient={newPatient}
          onChange={handlePatientInfoChange}
        />
      </Grid>
      <Grid item xs={12}>
        <CaregiverPreference
          patientProfile={currentPatient}
          newPatient={newPatient}
          onChange={handlePatientInfoChange}
        />
      </Grid>
      <Grid item xs={12}>
        <CaregiverLivingConditions
          patientProfile={currentPatient}
          newPatient={newPatient}
          onChange={handlePatientInfoChange}
        />
      </Grid>
      <Grid item xs={12}>
        <CohabitantsCard
          patientProfile={currentPatient}
          newPatient={newPatient}
          onChange={handlePatientInfoChange}
        />
      </Grid>
      {newPatient ? (
        <Grid
          item
          xs={12}
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "auto",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isAdding}
          >
            {isAdding ? "Creating..." : "Save"}
          </Button>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "auto",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdate}
            disabled={isAdding}
          >
            {isAdding ? "Updating..." : "Update"}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PatientDetails;
