import React from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ITextField from "./ITextField";
import theme from "Theme";
import { TextField } from "@mui/material";

function ITimePicker(props) {
  return (
    <TimePicker
      slots={{
        textField: TextField,
      }}
      slotProps={{
        textField: { fullWidth: true }, // Correct usage
      }}
      sx={{
        "& .MuiSvgIcon-root": {
          color: "grey",
        },
      }}
      {...props}
    />
  );
}

export default ITimePicker;
