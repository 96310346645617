import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

const PatientAbilities = ({ patientProfile, newPatient, onChange }) => {
  const [isEditing, setIsEditing] = useState(newPatient);
  const [abilities, setAbilities] = useState({
    hearing: patientProfile?.physical_abilities?.hearing || "No",
    vision: patientProfile?.physical_abilities?.vision || "No",
    communication: patientProfile?.physical_abilities?.communication || "No",
  });

  const abilityLabels ={
    hearing : "Hearing Difficulty",
    vision: "Vision Difficulty",
    communication: "Speech Difficulty"
  }

  useEffect(() => {
    // Update abilities state when patientProfile changes
    if (patientProfile) {
      setAbilities({
        hearing: patientProfile.physical_abilities?.hearing || "No",
        vision: patientProfile.physical_abilities?.vision || "No",
        communication: patientProfile.physical_abilities?.communication || "No",
      });
    }
  }, [patientProfile]);

  const handleToggleEdit = () => {
    setIsEditing((prevIsEditing) => {
      const newIsEditing = !prevIsEditing;
      if (!newIsEditing) {
        onChange({ physical_abilities: abilities });
      }
      return newIsEditing;
    });
  };

  const handleAbilityChange = (event) => {
    const { name, value } = event.target;
    setAbilities((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="Abilities"
        action={
          <IconButton onClick={handleToggleEdit} aria-label="edit">
            {isEditing ? (
              <DoneIcon color="primary" />
            ) : (
              <EditIcon color="primary" />
            )}
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {Object.keys(abilities).map((ability) => (
            <Grid item xs={12} sm={4} key={ability}>
              <Typography variant="subtitle2" color="orange" gutterBottom>
                {abilityLabels[ability]}
              </Typography>
              {isEditing ? (
                <RadioGroup
                  row
                  name={ability}
                  value={abilities[ability]}
                  onChange={handleAbilityChange}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              ) : (
                <Typography variant="body2">{abilities[ability]}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PatientAbilities;
