import React from "react";
import {
  Box,
  styled,
  Typography,
  Grid,
} from "@mui/material";
import s2image from "assets/s2image.jpg";

const Image = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "10%",
  left: 100,
  zIndex: 5,
  height: "70%",
  width: "100%",
  objectFit: "cover",
  borderTop: "15px solid white",
  borderLeft: "15px solid white",
  borderBottom: "15px solid white",
  [theme.breakpoints.down("sm")]: {
    objectFit: "fill",
    width: "90%",
    top: "20%",
    left: "0",
    borderLeft: "0px solid white",
    borderRight: "15px solid white",
    height: "250px",
  },
}));

const spanstyle = (theme) => ({
  color: "#212121",
  fontSize: {
    md:14,
    xl:20,
  },
  fontWeight: 400,
  lineHeight: 2,
  fontFamily: "raleway",
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
});

const FloatingBox = styled(Grid)(({ theme }) => ({
  position: "absolute",
  border: "15px solid white",
  top: "20%",
  left: "20%",
  height: "50%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "20px",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    left: "10%",
    width: "80%",
    height: "60%",
  },
  [theme.breakpoints.down("sm")]: {
    position: "relative",
    top: "30%",
    left: "auto",
    width: "90%",
    height: "auto",
    border: "10px solid white",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  lineHeight: 1.5,
  fontSize: 45,
  fontWeight: "bold",
  color: "#6b6b6b",
  fontFamily: "Roboto",
  "& span.other": {
    color: "black",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 30,
  },
}));

const SectionTwo = ({ aboutRef }) => {
  return (
    <Grid ref={aboutRef} container 
    sx={{
      height: {
        xs: 600, // height for extra small screens
        md: "110vh", // height for medium screens and above
      },
      position: "relative",
    }}>
      <Grid
        item
        xs={12}
        md={6}
        style={{ position: "relative", backgroundColor: "#c7c7c7" }}
      >
        {/* Image section */}
        <Image src={s2image} alt="description" />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ position: "relative", backgroundColor: "white" }}
      >
        {/* Floating box with text */}
        <FloatingBox item xs={12}>
          <div>
            <StyledTypography component="h1"
            sx={{
              fontSize: {
                xl: 70,  // Font size for large screens (desktops, larger laptops)
              },
            }}>
              ABOUT US
              <br />
              <br />
            </StyledTypography>
            <Typography sx={spanstyle} component="span">
              Welcome to AtHomeGeriCare, a service born from personal experience and a deep understanding of the challenges faced by NRIs in providing care for their elderly loved ones back in India.
            </Typography>
          </div>
        </FloatingBox>
      </Grid>
    </Grid>
  );
};

export default SectionTwo;
