import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const ReactMaterialTable = ({
  columns = [],
  data = [],
  handleChange,
  isEditing,
}) => {
  const [rows, setRows] = useState(data || []);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [newRow, setNewRow] = useState(
    columns.reduce((acc, col) => ({ ...acc, [col.field]: "" }), {})
  );

  useEffect(() => {
    setRows(data);
  }, [data]);

  const handleAddRow = () => {
    const updatedRows = [...rows, newRow];
    setRows(updatedRows);
    setNewRow(columns.reduce((acc, col) => ({ ...acc, [col.field]: "" }), {}));
    handleChange(updatedRows); // Notify parent component of data change
  };

  const handleEditRow = (index) => {
    setEditingRowIndex(index);
  };

  const handleDoneRow = (index) => {
    setEditingRowIndex(null);
    handleChange(rows); // Notify parent component of data change
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    handleChange(updatedRows); // Notify parent component of data change
  };

  const handleInputChange = (e, field, index) => {
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [field]: e.target.value };
    setRows(updatedRows);
  };

  const handleDateChange = (date, field, index) => {
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [field]: date };
    setRows(updatedRows);
  };

  const handleNewInputChange = (e, field) => {
    setNewRow({ ...newRow, [field]: e.target.value });
  };

  const handleNewDateChange = (date, field) => {
    setNewRow({ ...newRow, [field]: date });
  };

  return (
    <TableContainer component={Paper}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.field}>{col.headerName}</TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {columns.map((col) => (
                  <TableCell key={col.field}>
                    {isEditing && editingRowIndex === index ? (
                      col.type === "date" ? (
                        <DatePicker
                          inputFormat="MM/dd/yyyy"
                          value={
                            row?.[col.field]
                              ? dayjs(row[col.field], "YYYY-MM-DD")
                              : null
                          }
                          // value={row[col.field]}
                          onChange={(date) =>
                            handleDateChange(
                              date?.format("YYYY-MM-DD"),
                              col.field,
                              index
                            )
                          }
                          slotProps={{ textField: { fullWidth: true } }}
                        />
                      ) : (
                        <TextField
                          value={row[col.field]}
                          onChange={(e) =>
                            handleInputChange(e, col.field, index)
                          }
                        />
                      )
                    ) : (
                      <Typography>{row[col.field]}</Typography>
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  {isEditing ? (
                    editingRowIndex === index ? (
                      <IconButton onClick={() => handleDoneRow(index)}>
                        <DoneIcon color="secondary"/>
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleEditRow(index)}>
                        <EditIcon color="secondary"/>
                      </IconButton>
                    )
                  ) : null}
                  {isEditing ? (
                    <IconButton onClick={() => handleDeleteRow(index)}>
                      <DeleteIcon color="error"/>
                    </IconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
            {isEditing && (
              <TableRow>
                {columns.map((col) => (
                  <TableCell key={col.field}>
                    {col.type === "date" ? (
                      <DatePicker
                        inputFormat="MM/dd/yyyy"
                        // value={newRow[col.field]}
                        value={
                          newRow?.[col.field]
                            ? dayjs(newRow[col.field], "YYYY-MM-DD")
                            : null
                        }
                        onChange={(date) =>
                          handleNewDateChange(
                            date?.format("YYYY-MM-DD"),
                            col.field
                          )
                        }
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    ) : (
                      <TextField
                        value={newRow[col.field]}
                        onChange={(e) => handleNewInputChange(e, col.field)}
                      />
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleAddRow}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </LocalizationProvider>
    </TableContainer>
  );
};

export default ReactMaterialTable;
