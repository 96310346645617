import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import { Edit as EditIcon, Done as DoneIcon } from "@mui/icons-material";

const PersonalInfo = ({ newPatient = false, onChange, patientProfile }) => {
  const [isEditing, setIsEditing] = useState(newPatient);
  const [personalInfo, setPersonalInfo] = useState({});

  useEffect(()=>{
    setPersonalInfo(patientProfile)
  },[patientProfile])
  
  const handleEditClick = () => {
    setIsEditing((prevIsEditing) => {
      const newIsEditing = !prevIsEditing;
      if (!newIsEditing) {
        onChange(personalInfo);
      }
      return newIsEditing;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo({
      ...personalInfo,
      [name]: name === 'languages' ? value.split(',').map(lang => lang.trim()) : value,
    });
  };

  const renderTextField = (label, name, required=false) => (
    <Grid item xs={12} sm={4}>
      <Typography variant="subtitle2" color="orange">
        {label}
      </Typography>
      {isEditing ? (
        <TextField
          required={required}
          fullWidth
          variant="outlined"
          name={name}
          value={personalInfo?.[name]}
          onChange={handleInputChange}
        />
      ) : (
        <Typography variant="body2">
           {name === 'languages' 
            ? personalInfo?.languages?.length > 0 
              ? personalInfo.languages.join(', ') 
              : "Yet to Add" 
            : personalInfo?.[name] || "Yet to Add"}
        </Typography>
      )}
    </Grid>         
  );

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="Patient Information"
        action={
          <IconButton onClick={handleEditClick}>
            {isEditing ? (
              <DoneIcon color="primary" />
            ) : (
              <EditIcon color="primary" />
            )}
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {renderTextField("First Name (required)", "firstName")}
          {renderTextField("Last Name (required)", "lastName")}
          {renderTextField("Relationship to Client", "relationshipToClient")}
          {renderTextField("Contact Number", "contactNumber")}
          {renderTextField("Email ID", "email")}
          {renderTextField("Patient's Zipcode", "zipcode")}
          {renderTextField("Age", "age")}
          {renderTextField("Languages", "languages")}
          {renderTextField("Religion", "religion")}
          {renderTextField("Dietary Preference", "diet")}
          {renderTextField("Gender", "gender")}
          {renderTextField("Address Line 1", "address_line1")}
          {renderTextField("Address Line 2", "address_line2")}
          {renderTextField("City", "city")}
          {renderTextField("State", "state")}
          {renderTextField("Country", "country")}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PersonalInfo;
