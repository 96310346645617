import React, { useEffect, useState } from "react";
import {
  Box,
  Fade,
  styled,
  Typography,
  IconButton,
  Grow,
  Grid,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import s1image from "assets/s1image.jpg";
import Slide from "@mui/material/Slide";
import LoginForm from "components/LoginForm";
import { useLocation, useNavigate } from "react-router-dom";
import CaregiverApplyForm from "pages/Caregiver/CaregiverApplyForm";
import ClientInterestForm from "components/ClientInterestForm";

const Image = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "7%", // Default for larger screens
  zIndex: 5,
  height: "80%",
  width: "120%",
  objectFit: "cover",
  borderTop: "15px solid white",
  borderRight: "15px solid white",
  borderBottom: "15px solid white",
  [theme.breakpoints.down("sm")]: {
    top: "10%", // Change to 20% for xs screens
    height: "90%",
    // objectFit: "contain",
  },
}));

const FloatingBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  border: "15px solid white",
  top: "20%",
  zIndex: 7,
  left: "-90%",
  width: "40vw",
  height: "65vh",
  backgroundColor: "#e8e6e6",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "20px",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    left: "10%",
    top:-20,
    width: "90%",
    height: "90%",
    border: "10px solid white",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  lineHeight: 1.5,
  // fontSize: 45,
  fontWeight: "bold",
  color: "#6b6b6b",
  fontFamily: "Roboto",
  "& span.other": {
    color: "black",
  },
  [theme.breakpoints.down("sm")]: {
    // fontSize: 30,
  },
}));

const BookButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  cursor: "pointer",
  color: "#6b6b6b",
  fontSize: 18,
  fontWeight: "bold",
  marginBottom: "10px",
  "&:hover": {
    color: "black",
  },
});

const CenteredForm = styled(Box)({
  position: "absolute",
  transform: "translate(-50%, -50%)",
  zIndex: 1000,
  width: "100%",
  height:"100%",
  padding: 8,
});

const SectionOne = () => {
  const location = useLocation();
  const containerRef = React.useRef(null);
  const navigate = useNavigate();
  const [growIn, setGrowIn] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showApplyForm, setShowApplyForm] = useState(false);
  const [showClientForm, setShowClientForm] = useState(false);
  

  const book = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/clients/new");
  };

  useEffect(() => {
    if (location.pathname === "/clients/new") {
      setShowClientForm(true);
    } else {
      setShowClientForm(false);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/login") {
      setShowLoginForm(true);
    } else {
      setShowLoginForm(false);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/caregiver/new") {
      setShowApplyForm(true);
    } else {
      setShowApplyForm(false);
    }
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setGrowIn(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* Centered LoginForm with Grow effect */}
      <Grow in={showLoginForm} timeout={500}>
        <CenteredForm>
          <LoginForm />
        </CenteredForm>
      </Grow>
      {/* Centered Caregiver Apply Form with Grow effect */}
      <Grow in={showApplyForm} timeout={500}>
        <CenteredForm>
          <CaregiverApplyForm />
        </CenteredForm>
      </Grow>

      {/* Centered Client Interest Form with Grow effect */}
      <Grow in={showClientForm} timeout={500}>
        <CenteredForm>
          <ClientInterestForm />
        </CenteredForm>
      </Grow>

      <Grid
        container
        sx={{
          mt:"65px",
          height: {
            xs: 600, // height for extra small screens
            md: "110vh", // height for medium screens and above
          },
          position: "relative",
        }}
      >
        {" "}
        <Grid
          item
          xs={9}
          md={6}
          style={{ position: "relative", backgroundColor: "white" }}
        >
          {/* Image fade animation */}
          <Fade in={true} timeout={3000}>
            <Image src={s1image} alt="description" />
          </Fade>
        </Grid>
        <Grid
          item
          xs={3}
          md={3}
          style={{ position: "relative", backgroundColor: "#cbdbc5" }}
        />
        <Grid
          item
          xs={12}
          md={3}
          style={{ position: "relative", backgroundColor: "#cbdbc5" }}
        >
          {/* Floating box with text and button */}
          <Slide in={true} direction="left" timeout={1500}>
          <FloatingBox> 
            <Grow in={true} timeout={3000}>
                <StyledTypography 
                sx={{
                  fontSize: {
                    xs: 30,  // Font size for extra small screens (mobile)
                    md: 45,  // Font size for medium screens (tablets, small laptops)
                    xl: 60,  // Font size for large screens (desktops, larger laptops)
                  },
                }}
                ref={containerRef} variant="h1">
                  COMFORT,
                  <br />
                  COMPASSION &<br />
                  CARE - <span className="other">RIGHT</span>
                  <br />
                  <span className="other">AT HOME!</span>
                </StyledTypography>
              </Grow>
              <Grow in={growIn} timeout={3000}>
                <BookButton onClick={book}>
                  <Typography sx={{ fontFamily: "raleway" ,
                    fontSize: {
                      xl: 25, 
                    }
                  }}>
                    Book an Appointment
                  </Typography>
                  <IconButton>
                    <ArrowForwardIcon fontSize="medium" />
                  </IconButton>
                </BookButton>
              </Grow>
            </FloatingBox>
          </Slide>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionOne;
