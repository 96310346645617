import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField, FormControlLabel, Checkbox, Chip, Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const SkillsAbilitiesCard = ({ caregiverDetails, handleChange, isEditing }) => {
  const caregivingSkills = [
    'First Aid', 'CPR', 'Personal Hygiene Assistance', 'Medication Administration', 'Mobility Assistance'
  ];

  const softSkills = [
    'Communication', 'Empathy', 'Patience', 'Problem-Solving', 'Time Management'
  ];

  const [skillsAbilities, setSkillsAbilities] = useState({
    specific_caregiving_skills: [],
    soft_skills: [],
    other_skills: [],
    other_skills_input:''
  });

  useEffect(() => {
    if (caregiverDetails?.skills_abilities) {
      setSkillsAbilities({
        ...skillsAbilities,
        ...caregiverDetails?.skills_abilities,
      });
    }
  }, [caregiverDetails]);

  const handleSkillChange = (skillType, skill, checked) => {
    const updatedSkills = checked
      ? [...skillsAbilities[skillType], skill]
      : skillsAbilities[skillType].filter(s => s !== skill);

    const updatedSkillsAbilities = { ...skillsAbilities, [skillType]: updatedSkills };
    setSkillsAbilities(updatedSkillsAbilities);
    handleChange('skills_abilities', updatedSkillsAbilities);
  };
  const handleOtherSkillsBlur = (e) => {
    const updatedOtherSkills = e.target.value.split(',').map(skill => skill.trim());
    const updatedSkillsAbilities = { ...skillsAbilities, other_skills: updatedOtherSkills };
    setSkillsAbilities(updatedSkillsAbilities);
    handleChange('skills_abilities', updatedSkillsAbilities);
  };
  
  const handleOtherSkillsChange = (e) => {
    // This lets the user type freely without splitting immediately
    setSkillsAbilities({ ...skillsAbilities, other_skills_input: e.target.value });
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">Skills and Abilities</Typography>
        <Grid container spacing={2}>
          {/* Caregiving Skills */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Specific Caregiving Skills</Typography>
            {isEditing ? (
              caregivingSkills.map(skill => (
                <FormControlLabel
                  key={uuidv4()}
                  control={
                    <Checkbox
                      checked={skillsAbilities.specific_caregiving_skills.includes(skill)}
                      onChange={(e) => handleSkillChange('specific_caregiving_skills', skill, e.target.checked)}
                    />
                  }
                  label={skill}
                />
              ))
            ) : (
              <Grid container spacing={1}>
                {skillsAbilities.specific_caregiving_skills.map(skill => (
                  <Grid item key={skill}>
                    <Chip label={skill} color="primary" />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          {/* Soft Skills */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Soft Skills</Typography>
            {isEditing ? (
              softSkills.map(skill => (
                <FormControlLabel
                  key={uuidv4()}
                  control={
                    <Checkbox
                      checked={skillsAbilities.soft_skills.includes(skill)}
                      onChange={(e) => handleSkillChange('soft_skills', skill, e.target.checked)}
                    />
                  }
                  label={skill}
                />
              ))
            ) : (
              <Grid container spacing={1}>
                {skillsAbilities.soft_skills.map(skill => (
                  <Grid item key={skill}>
                    <Chip label={skill} color="info" />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          {/* Other Related Skills */}
          <Grid item xs={12}>
            {isEditing ? (
              <TextField
                fullWidth
                label="Other Related Skills"
                placeholder="Enter skills separated by commas"
                  value={skillsAbilities.other_skills_input || ''}
                  onChange={handleOtherSkillsChange}
                  onBlur={handleOtherSkillsBlur} // This splits and trims on blur
              />
            ) : (
              <Box>
                <Typography variant="subtitle1">Other Skills</Typography>
                {skillsAbilities.other_skills.map(skill => (
                  <Chip key={skill} label={skill} color="default" sx={{ m: 0.5 }} />
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SkillsAbilitiesCard;
