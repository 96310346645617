// context/LoadingContext.js
import React, { createContext, useState, useContext } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { tailChase } from 'ldrs'
import { useTheme } from '@emotion/react';

tailChase.register()


const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

const ProgressBar = () => {
    const { isLoading } = useLoading();
    const theme = useTheme();
    return isLoading ? 
    <>
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: to add a semi-transparent background
        zIndex: 9999, // Ensure it's above other content
      }}
    >
      
      <l-tail-chase
        size="100"
        speed="1.75" 
        color={theme.palette.primary.main}
      ></l-tail-chase>
      {/* <CircularProgress size={150}/> */}
    </Box>
    </> : null;
  };

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
      <ProgressBar />
      {children}
    </LoadingContext.Provider>
  );
};

