import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from "recharts";
import { Paper, Typography, Box } from "@mui/material";

function DbBarChart({ title, subtitle, bgColor, data=[] }) {
  return (
    <Paper
      sx={{
        borderRadius: "13px",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: 2,
        textAlign: "left",
        backgroundColor: "white",
        position: "relative",
      }}
    >
      <Box
        sx={{
          height: "200px",
          width: "90%",
          position: "absolute",
          top: -5,
          backgroundColor: bgColor,
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#D3D3D3" />
            <XAxis dataKey="name" stroke="#FFFFFF" />
            <YAxis stroke="#FFFFFF" />
            <Tooltip />
            <Bar dataKey="value" fill="#FFFFFF" barSize={4} /> {/* Bar width set to 1mm */}
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={{ paddingTop: "200px", textAlign: "left" }}>
        <Typography variant="subtitle1">
          {title}
        </Typography>
        <Typography variant="body2">
          {subtitle}
        </Typography>
      </Box>
    </Paper>
  );
}

export default DbBarChart;
