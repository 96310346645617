import {  clientColumnVisibilty,  clientsColumns,  clientSorting} from "CommonData/ClientsColumns";
import { useLoading } from "components/LoadingProvider";
import { useSnackbar } from "components/SnackbarProvider";
import Table from "components/Table";
import HomeLayout from "layout/HomeLayout";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetClientsQuery } from "services/clientApi";
import { setSelectedMenuItem } from "slices/globaslice";

function ClientsListPage() {
  const { data: clientsData, error, isLoading, refetch } = useGetClientsQuery();
  const showSnackbar = useSnackbar();
  const dispatch = useDispatch();
  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setSelectedMenuItem(3));
  }, []);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);

  return (
    <>
      {
        <Table
          title="Clients"
          data={clientsData || []}
          columns={clientsColumns}
          rowClickUrl="/clients/"
          chipField="status"
          sorting={clientSorting}
          columnVisibility={clientColumnVisibilty}
          refetch={refetch}
        />
      }
    </>
  );
}

export default ClientsListPage;
