import React, { useEffect, useState } from "react";
import zIndex from "@mui/material/styles/zIndex";
import {
  Grid,
  Box,
  FormHelperText,
  Container,
  Paper,
  Typography,
  Button,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import theme from "Theme";
import ISelect from "./SelectField";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ITimePicker from "./ITimePicker";
import { getNames } from "country-list";
import countryTelephoneData from "country-telephone-data"; // Import the library
import dayjs from "dayjs";
import indianCities from "./indianCities";
import { useCreateInterestedClientMutation } from "services/clientApi";
import { useLoading } from "./LoadingProvider";
import { useSnackbar } from "./SnackbarProvider";
import { ClientStatuses } from "CommonData/Common";

const ClientInterestForm = () => {
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();
  const [minEndTime, setMinEndTime] = useState(null);

  const countryList = Array.from(
    new Set(
      countryTelephoneData.allCountries.map((country) =>
        country.name.split("(")[0].trim()
      )
    )
  );
  function getPhoneCode(countryName) {
    const country = countryTelephoneData.allCountries.find(
      (country) => country.name.split("(")[0].trim() == countryName
    );
    return country ? country.dialCode : "";
  }
  const getMinEndTime = (value) => {
    return value ? dayjs(value).add(30, "minute") : null;
  };
  const handleCountryTypeChange = (name, value) => {
    handleInputChange(name, value);
    handleInputChange("phoneCode", "+" + getPhoneCode(value) + "-");
  };
  const [countryType, setCountryType] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [ic, setIc] = useState("");
  const [createInterestedClient] = useCreateInterestedClientMutation();
  const initialFormValue = {
    firstName: "",
    lastName: "",
    dayofchoice: "Anyday",
    email: "",
    countryType: "",
    contactNumber: "",
    serviceType: "",
    serviceLocation: "",
    availableFrom: null,
    availableTo: null,
    initialComment: "",
    phoneCode: "",
  }
  const [formValues, setFormValues] = useState(initialFormValue);
  const initialFormError = {
    firstName: "",
    lastName: "",
    email: "",
    countryType: "",
    contactNumber: "",
    //  serviceType: '',
    serviceLocation: "",
    availableFrom: "",
    availableTo: "",
    //   initialComment: ''
  }
  const [formErrors, setFormErrors] = useState(initialFormError);

  const handleInputChange = (name, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };
  
  const handleContactNumberChg = (name, value) => {
    const numericValue = value.replace(/[^0-9]/g, "");
  
    // Limit the input to 10 characters
    if (numericValue.length <= 10) {
      handleInputChange(name, numericValue);
    }
  };

  const handleAvailabilityChge = (name, value) => {
    handleInputChange(name, value);
    handleInputChange("availableTo", dayjs(value).add(30, "minute"));
    setMinEndTime(getMinEndTime(value));
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {
      firstName: !formValues.firstName.trim() ? "Full Name is required" : "",
      lastName: !formValues.lastName.trim() ? "Last Name is required" : "",
      email: !validateEmail(formValues.email) ? "Enter a valid email" : "",
      contactNumber: !formValues.contactNumber.trim()
        ? "Contact Number is required"
        : "",
      countryType: !formValues.countryType.trim() ? "Country is required" : "",
      // //     serviceType: !formValues.serviceType.trim() ? 'Service Type is required' : '',
      // serviceLocation: !formValues.serviceLocation.trim()
      //   ? "Service Location is required"
      //   : "",
      // availableFrom: !formValues.availableFrom?.isValid()
      //   ? "Available Time is required"
      //   : "",
      // availableTo: !formValues.availableTo?.isValid()
      //   ? "Available Time is required"
      //   : "",
      //    initialComment: !formValues.initialComment.trim() ? 'Initial Comment is required' : ''
    };

    setFormErrors(errors);

    const isFormValid = Object.values(errors).every((error) => error === "");

    if (isFormValid) {
      try {
        startLoading();
        // Send POST request to backend
        const data = {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          contactNumber:
            "+" +
            getPhoneCode(formValues.countryType) +
            "-" +
            formValues.contactNumber,
          email: formValues.email,
          country: formValues.countryType,
          serviceLocation: formValues.serviceLocation,
          serviceType: formValues.serviceType,
          dayofchoice: formValues.dayofchoice,
          availabilityStartTime: formValues.availableFrom?.format("HH:mm"),
          availabilityEndTime: formValues.availableTo?.format("HH:mm"),
          initialComment: formValues.initialComment,
          status: ClientStatuses.INTERESTED.name,
        };
        //console.log(data)

        const response = await createInterestedClient(data).unwrap();
        showSnackbar("Interest Sent for consideration ...", "success");
        // Handle response if needed
        //console.log('Response:', response);
      } catch (error) {
        showSnackbar(
          "Couldn't send your interest. Please retry after sometime",
          "error"
        );
        console.error("Error:", error);
      } finally {
        stopLoading();
        setFormValues(initialFormValue)
        setFormErrors(initialFormError)
        handleClose();
      }
    } else {
      //  console.log('invalid form');
      //  console.log(formErrors);
      //  console.log(formValues)
    }
  };

  const handleServiceTypeChange = (event) => {
    setServiceType(event.target.value);
  };
  const shouldDisableEndTime = (timeValue) => {
    if (!formValues.availableFrom) {
      return false;
    }
    const minEndTime = dayjs(formValues.availableFrom).add(30, "minute");
    return dayjs(timeValue).isBefore(minEndTime);
  };

  const style = {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    zIndex: 1,
  };

  const navigate = useNavigate();
  const handleClose = () => {
    
    setFormValues(initialFormValue)
    setFormErrors(initialFormError)
    navigate("/");
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        // maxHeight: {
        //   xs: "80vh", // Adjust as needed
        //   sm: "none", // No max height for small and larger screens
        // },
        // overflowY: {
        //   xs: "auto", // Enable vertical scroll for extra-small screens
        //   sm: "visible", // Disable scroll for small and larger screens
        // },
      }}
    >
      <Box position="relative">
        <Paper style={style} elevation={3} sx={{ padding: 2, borderRadius: 5 }}>
          <Typography variant="h5" gutterBottom>
            Appointment
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "red",
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="First Name"
                    name="firstName"
                    inputProps={{ maxLength: 35 }}
                    value={formValues.firstName}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.firstName && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.firstName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    inputProps={{ maxLength: 35 }}
                    value={formValues.lastName}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.lastName && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.lastName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    name="email"
                    value={formValues.email}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.email && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  {/* <InputLabel
                    style={{                      
                      color: countryType || countryType === '' ? 'grey' : theme.palette.primary.main,   
                    }}
                  >       Choose Living Country
                    </InputLabel> */}
                  <ISelect
                    options={countryList}
                    label="Country"
                    name="countryType"
                    value={formValues.countryType}
                    onChange={(e, newValue) =>
                      handleInputChange("countryType", newValue)
                    }
                    variant="outlined"
                    required
                  />
                  {/* {countryList.map((countryType) => (
                    <MenuItem key={countryType} value={countryType}>
                      {countryType}
                    </MenuItem>
                  ))}
                </ISelect> */}
                  {formErrors.countryType && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.countryType}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} className="custom-phone-input">
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ color: "white" }}
                          color="primary"
                        >
                          <Box component="span" sx={{ color: "grey" }}>
                            {formValues.countryType &&
                              "+" +
                                getPhoneCode(formValues.countryType) +
                                " - "}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    label="Contact Number"
                    name="contactNumber"
                    value={formValues.contactNumber}
                    onChange={(e) =>
                      handleContactNumberChg(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.contactNumber && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.contactNumber}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <ISelect
                    label="Service Location"
                    name="serviceLocation"
                    value={formValues.serviceLocation}
                    options={indianCities}
                    onChange={(e, newValue) =>
                      handleInputChange("serviceLocation", newValue)
                    }
                    variant="outlined"
                  />
                  {formErrors.serviceLocation && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.serviceLocation}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth>
                  {/* <InputLabel
                style={{
                  
                  color: serviceType || serviceType === '' ? 'grey' : theme.palette.primary.main,   
                }}
              >        Service Type (Optional)
                </InputLabel> */}
                  <ISelect
                    // onChange={handleServiceTypeChange}
                    label="Service Type(Optional)"
                    name="serviceType"
                    freesolo
                    value={formValues.serviceType}
                    options={["Day Care", "Night Care", "Stay at Home care"]}
                    onChange={(e, newValue) =>
                      handleInputChange("serviceType", newValue)
                    }
                    onInputChange={(e, newValue) =>
                      handleInputChange("serviceType", newValue)
                    }
                    variant="outlined"
                    // required
                  />
                  {/* <MenuItem value="Medication">Medication</MenuItem>
                  <MenuItem value="Nursing">Nursing</MenuItem>
                  <MenuItem value="Transportation">Transportation</MenuItem>
                  {/* Add more MenuItem components as needed 
                </ISelect>                 */}
                </FormControl>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={12} sm={4} fullWidth>
                  <ISelect
                    label="Availability (Day of choice)"
                    name="dayofchoice"
                    value={formValues.dayofchoice}
                    options={["Weekday", "Weekends", "Anyday"]}
                    onChange={(e, newValue) =>
                      handleInputChange("dayofchoice", newValue)
                    }
                    onInputChange={(e, newValue) =>
                      handleInputChange("dayofchoice", newValue)
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4} fullWidth>
                  <ITimePicker
                    label="Availability (From)"
                    name="availableFrom"
                    value={formValues.availableFrom}
                    onChange={(newValue) =>
                      handleAvailabilityChge("availableFrom", newValue)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} fullWidth>
                  <ITimePicker
                    label="Availability (To)"
                    name="availableTo"
                    value={formValues.availableTo}
                    minTime={minEndTime}
                    onChange={(newValue) =>
                      handleInputChange("availableTo", newValue)
                    }
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12}>
                <TextField
                  label="Initial Comments (If Any)"
                  multiline
                  rows={1}
                  value={formValues.initialComment}
                  onChange={(e) =>
                    handleInputChange("initialComment", e.target.value)
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                sm={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Book
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#FF0000",
                    "&:hover": {
                      bgcolor: "#CC0000", // Darker shade of red for hover effect
                    },
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default ClientInterestForm;
