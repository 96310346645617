import React, { useState, useEffect, useRef } from "react";
import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import dayCareImage from "assets/daycare.jpg";
import nightCareImage from "assets/nightcare.jpg";
import stayAtHomeImage from "assets/stayathome.jpg";

const ServiceSection = ({ servicesRef }) => {
  const services = [
    { name: "DAY CARE", image: dayCareImage },
    { name: "NIGHT CARE", image: nightCareImage },
    { name: "STAY AT HOME CARE", image: stayAtHomeImage },
  ];

  return (
    <Box
      ref={servicesRef}
      sx={{
        height: {
          xs: 2000, // Height for extra-small screens
          md: 700, // Height for medium screens
          xl:1000
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          margin: "0 auto",
          position: "relative",
          display: "flex",
          marginTop: "20px",
          backgroundColor: "#cbdbc5",
          height: "350px",
        }}
      >
        <Box sx={{ ml: 8, mt: 8, fontFamily: "Raleway", color: "#FFFFFF" }}>
          <Typography sx={{ fontSize: {
            xs:30,
            xl:45
          }, fontWeight: 400 }} component={"h1"}>
            SERVICES
          </Typography>
        </Box>
        <Grid
          // ref={containerRef} // Attach ref here
          container
          // spacing={3}
          sx={{
            width: "100%",
            position: "absolute",
            top: 150,
          }}
        >
          {services.map((service, index) => (
            <Grid
              key={index}
              sx={{
            p:2,
              }}
              item
              xs={12}
              md={4} // Responsive grid: full width on extra-small, half on small, third on medium+
            >
              <Card
                className="service-card"
                sx={{
                  height: {
                    xs:600,
                    md:500,
                    xl:800
                  },
                  position: "relative",
                }}
              >
                <CardMedia
                  component="img"
                  alt={service.name}
                  image={service.image}
                  sx={{ width: "100%", height: "100%" }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "80%",
                    height: "20%",
                    pt: 2,
                    pl: 2,
                    backgroundColor: "#d5a6bd",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    color: "white",
                  }}
                >
                  <Typography 
                  sx={{
                    fontSize:{
                      xl:25
                    }
                  }}
                  variant="body1">{service.name}</Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ServiceSection;
