import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Radio,
  Chip,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AccessibleRoundedIcon from "@mui/icons-material/AccessibleRounded";
import HailRoundedIcon from "@mui/icons-material/HailRounded";
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";
import clientApi, {
  useGetClientsQuery,
  useUpdateClientMutation,
} from "services/clientApi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useAddAppointmentMutation } from "services/appointmentApi";
import { useSnackbar } from "./SnackbarProvider";
import { useLoading } from "./LoadingProvider";
import caregiverApi, {
  useGetCaregiversQuery,
  useUpdateCaregiverMutation,
} from "services/caregiverApi";
import { useGetUsersQuery } from "services/userApi";
import {
  CaregiverStatuses,
  ClientStatuses,
  meetingTypes,
  PatientStatuses,
} from "CommonData/Common";
import { generateEmail } from "common/utilities";
import { useDispatch, useSelector } from "react-redux";

const ScheduleAppointment = () => {
  const [patients, setPatients] = useState([]);
  const [meetingType, setMeetingType] = useState(
    meetingTypes.initialClientMeet
  );
  const [clients, setClients] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const userInfo = useSelector((state) => state.global.userInfo);
  const [selectedStaffids, setSelectedStaffids] = useState([userInfo.user_id]);
  const {
    data: cgs,
    error: cgserror,
    isLoading: iscgsLoading,
  } = useGetCaregiversQuery();
  const {
    data: staffsdata,
    error: staffserror,
    isLoading: isstaffsLoading,
  } = useGetUsersQuery();
  const {
    data: clientsData,
    error: clienterror,
    isLoading: isclientLoading,
  } = useGetClientsQuery();
  const [emails, setEmails] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const client = location.state?.client || null;

  const [selectedClient, setSelectedClient] = useState(client);

  const [
    addAppointment,
    { isLoading: isLoading_addapp, isSuccess, isError, error: error_addapp },
  ] = useAddAppointmentMutation();
  const [updateClient, { isLoading: isUpdating, error: updateError }] =
    useUpdateClientMutation();

  const [updateCaregiver, { isLoading: updatingCG, error: updatecgerror }] =
    useUpdateCaregiverMutation();

  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();

  const isLoading =
    updatingCG ||
    isclientLoading ||
    iscgsLoading ||
    isstaffsLoading ||
    isLoading_addapp ||
    isUpdating;
  const error =
    updatecgerror ||
    clienterror ||
    cgserror ||
    error_addapp ||
    staffserror ||
    updateError;

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.data?.message, "error");
    }
  }, [error]);
  const { caregiver_id } = useParams();
  const { clientId } = useParams();
  const [title, setTitle] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [anchorElPatient, setAnchorElPatient] = useState(null);
  const [anchorElClient, setAnchorElClient] = useState(null);
  const [anchorElCaregiver, setAnchorElCaregiver] = useState(null);
  const [anchorElStaff, setAnchorElStaff] = useState(null);
  const [selectedPatientids, setSelectedPatientids] = useState([]);
  const [selectedCaregiverids, setSelectedCaregiverids] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState([]);
  const [disablePatientSelection, setDisablePatientSelection] = useState(true);
  const [disableClientSelection, setDisableClientSelection] = useState(true);
  const today = dayjs().format("YYYY-MM-DD");
  const dispatch = useDispatch();

  useEffect(()=>{
    if(meetingType.name == meetingTypes.clientCaregiverInterview.name){
      setSelectedPatientids(
        client?.patients?.length >0 ? [client.patients[0].id] :[])
    } else {
      setSelectedPatientids([])
    }

  },[meetingType])
  useEffect(() => {
    if (caregiver_id) {
      handleToggleCaregiver(caregiver_id);
      setMeetingType(meetingTypes.initialCaregiverMeet);
    }
  }, [caregiver_id]);

  const [appointmentDate, setAppointmentDate] = useState(dayjs().add(1, "day"));
  const [timingFrom, setTimingFrom] = useState(dayjs());
  const [timingTo, setTimingTo] = useState(dayjs());

  useEffect(() => {
    let recepient_name = "";
    if (meetingType.name == meetingTypes.initialClientMeet.name) {
      recepient_name = `${selectedClient?.firstName || ""}`;
    } else if (
      meetingType.name == meetingTypes.initialCaregiverMeet.name &&
      selectedCaregiverids.length == 1
    ) {
      const caregiver = cgs?.find((cg) => cg.id == selectedCaregiverids[0]);
      recepient_name = `${caregiver?.firstName || ""}`;
    }
    const { title, body } = generateEmail(
      meetingType.emailTemplate,
      recepient_name,
      appointmentDate.format("D MMM YYYY"),
      `${timingFrom.format("hh:mm A")} to ${timingTo.format("hh:mm A")}`
    );
    setEmailContent(body);
    setTitle(title);
  }, [
    meetingType,
    timingFrom,
    timingTo,
    appointmentDate,
    selectedClient,
    selectedCaregiverids,
  ]);

  useEffect(() => {
    if (selectedClient) {
      setTimingFrom(dayjs(selectedClient.availabilityStartTime, "HH:mm:ss"));
      setTimingTo(dayjs(selectedClient.availabilityEndTime, "HH:mm:ss"));
    }
    if (selectedClient?.status == ClientStatuses.AWAITING_CG_INTERVIEW.name) {
      setMeetingType(meetingTypes.clientCaregiverInterview);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (clientsData) {
      setClients(clientsData);
    }
  }, [clientsData]);

  useEffect(() => {
    if (staffsdata) {
      setStaffs(staffsdata);
    }
  }, [staffsdata]);

  useEffect(() => {
    if (meetingType.name == meetingTypes.clientCaregiverInterview.name) {
      setSelectedCaregiverids([]);
      const available_cgs = caregivers.filter(
        (cg) => cg.status == CaregiverStatuses.AVAILABLE.name
      );
      setCaregivers(available_cgs);
    } else {
      if (cgs) {
        setCaregivers(cgs);
      }
    }
  }, [meetingType]);

  useEffect(() => {
    if (cgs) {
      setCaregivers(cgs);
    }
  }, [cgs]);
  const handleAddPatient = () => {
    setPatients([...patients, ""]);
  };

  const handlePatientChange = (index, value) => {
    const newPatients = [...patients];
    newPatients[index] = value;
    setPatients(newPatients);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handlePopoverOpenPatient = (event) => {
    setAnchorElPatient(event.currentTarget);
  };

  const handlePopoverOpenCaregiver = (event) => {
    setAnchorElCaregiver(event.currentTarget);
  };
  const handlePopoverOpenStaff = (event) => {
    setAnchorElStaff(event.currentTarget);
  };

  const handlePopoverClosePatient = () => {
    setAnchorElPatient(null);
  };

  const handlePopoverCloseCaregiver = () => {
    setAnchorElCaregiver(null);
  };

  const handlePopoverCloseStaff = () => {
    setAnchorElStaff(null);
  };

  const handleTogglePatient = (value) => {
    // const currentIndex = selectedPatientids.indexOf(value);
    // const newSelectedPatients = [...selectedPatientids];

    // if (currentIndex === -1) {
    //   newSelectedPatients.push(value);
    // } else {
    //   newSelectedPatients.splice(currentIndex, 1);
    // }
    setSelectedPatientids([value]);
    // setSelectedPatientids(newSelectedPatients);
    // console.log(newSelectedPatients)
  };

  const schedule = async () => {
    
    const data = {
      title: title,
      appointment_date: appointmentDate.format("YYYY-MM-DD"),
      from_time: timingFrom.format("HH:mm"),
      to_time: timingTo.format("HH:mm"),
      meeting_type: meetingType.name,
      status: "scheduled",
      invitation_text: emailContent,
      client: selectedClient?.id || "",
      patients: selectedPatientids || [],
      caregivers: selectedCaregiverids,
      staffs: selectedStaffids,
      emails: emails ? emails.split(/[;,]\s*/) : [],
    };
    startLoading();
    try {
      if (meetingType.name == meetingTypes.initialClientMeet.name) {
        if (selectedClient) {
          data.client_status = ClientStatuses.DISCUSSION.name;
        }
      } else if (meetingType.name == meetingTypes.initialCaregiverMeet.name) {
        if (selectedCaregiverids.length > 0) {
          data.cg_status = CaregiverStatuses.INTERNAL_INTERVIEW.name;
        }
      } else if (
        meetingType.name == meetingTypes.clientCaregiverInterview.name
      ) {
        if (selectedCaregiverids.length > 0) {
          data.cg_status = CaregiverStatuses.CLIENT_INTERVIEW.name;
        }
        if (selectedPatientids.length > 0) {
          data.patient_status = PatientStatuses.CG_INTERVIEW.name;
        }
        if (selectedClient) {
          data.client_status = ClientStatuses.CG_INTERVIEW.name;
        }
      }
      const result = await addAppointment(data);
      if (result.error) {
        throw result.error;
      }
      dispatch(clientApi.util.invalidateTags(["Client"]));
      dispatch(caregiverApi.util.invalidateTags(["Caregiver"]));
      showSnackbar("Appointment scheduled successfully", "success");
    } catch (err) {
      showSnackbar("Failed to schedule appointment", "error");
    } finally {
      stopLoading();
      const params = new URLSearchParams(location.search);
      const previousURL = params.get("redirect");
      if (previousURL) {
        navigate(previousURL);
      } else {
        navigate("/clients"); // Fallback URL if no previous URL is stored
      }
    }
  };

  const handleToggleStaff = (value) => {
    if(value == userInfo.user_id){
      return
    }
    const currentIndex = selectedStaffids.indexOf(value);
    const newSelectedStaffs = [...selectedStaffids];
    if (currentIndex === -1) {
      newSelectedStaffs.push(value);
    } else {
      newSelectedStaffs.splice(currentIndex, 1);
    }
    setSelectedStaffids(newSelectedStaffs);
  };

  const handleToggleCaregiver = (value) => {
    const currentIndex = selectedCaregiverids.indexOf(value);
    const newSelectedCaregivers = [...selectedCaregiverids];

    if (currentIndex === -1) {
      newSelectedCaregivers.push(value);
    } else {
      newSelectedCaregivers.splice(currentIndex, 1);
    }
    setSelectedCaregiverids(newSelectedCaregivers);
  };

  const handleOkClickPatient = () => {
    // setEmail(selectedPatients.join(", "));
    handlePopoverClosePatient();
  };

  const handleOkClickStaff = () => {
    // setEmail(selectedStaffs.join(", "));
    handlePopoverCloseStaff();
  };
  const handleOkClickCaregiver = () => {
    // setEmail(selectedCaregivers.join(", "));
    handlePopoverCloseCaregiver();
  };
  const handlePopoverOpenClient = (event) => {
    setAnchorElClient(event.currentTarget);
  };

  const handlePopoverCloseClient = () => {
    setAnchorElClient(null);
  };

  const handleSelectClient = (value) => {
    setSelectedClient(value);
    handlePopoverCloseClient();
  };
  const openPatient = Boolean(anchorElPatient);
  const openCaregiver = Boolean(anchorElCaregiver);
  const openStaff = Boolean(anchorElStaff);
  const openClient = Boolean(anchorElClient);
  const meeting_type_options = Object.values(meetingTypes).map(
    (type) => type.name
  );

  function handleMeetingTypeChange(event, newValue) {
    const selectedMeetingType = Object.values(meetingTypes).find(
      (type) => type.name == newValue
    );
    setMeetingType(selectedMeetingType ?? {});
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
        <Typography variant="h6">Appointment Schedule</Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              label="Date"
              value={appointmentDate}
              onChange={(newValue) => setAppointmentDate(newValue)}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={4}>
            <TimePicker
              label="Timing From"
              value={timingFrom}
              slotProps={{ textField: { fullWidth: true } }}
              onChange={(newValue) => setTimingFrom(newValue)}
            />
          </Grid>
          <Grid item xs={4}>
            <TimePicker
              label="Timing To"
              value={timingTo}
              onChange={(newValue) => setTimingTo(newValue)}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              startIcon={
                isLoading ? <CircularProgress size={20} /> : <AddIcon />
              }
              fullWidth
              disabled={clients.length == 0 || caregiver_id || clientId}
              sx={{ borderRadius: "20px" }} // Adjust the border radius as needed
              onClick={handlePopoverOpenClient}
            >
              Client
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              disabled={
                !(selectedClient && selectedClient?.patients?.length > 0) ||
                meetingType.name == meetingTypes.initialClientMeet.name
              }
              startIcon={<AddIcon />}
              sx={{ borderRadius: "20px" }} // Adjust the border radius as needed
              onClick={handlePopoverOpenPatient}
            >
              {selectedClient && selectedClient?.patients?.length > 0
                ? "Patient"
                : "No Patients"}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<AddIcon />}
              disabled={
                caregivers.length == 0 ||
                caregiver_id ||
                meetingType.name == meetingTypes.initialClientMeet.name
              }
              onClick={handlePopoverOpenCaregiver}
              sx={{ borderRadius: "20px" }} // Adjust the border radius as needed
            >
              {caregivers?.length > 0 ? "Caregiver" : "No Available CGs"}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handlePopoverOpenStaff}
              sx={{ borderRadius: "20px" }} // Adjust the border radius as needed
            >
              Staff
            </Button>
          </Grid>
          <Grid item xs={12}>
            {selectedClient && (
              <Chip
                label={
                  "Client: " +
                  selectedClient.firstName +
                  ", " +
                  selectedClient.lastName
                }
                onDelete={clientId ? undefined: () => setSelectedClient(null) }
                color="warning"
                variant="contained"
                sx={{ m: 1 }}
              />
            )}
 
            {/* Selected Patients Chips */}
            {selectedClient?.patients &&
              selectedPatientids.map((pid) => {
                const patient = selectedClient?.patients?.find(
                  (p) => p.id == pid
                );
                return (
                  <Chip
                    key={pid}
                    label={`Patient: ${patient?.firstName}, ${patient?.lastName}`}
                    onDelete={clientId && meetingType.name == meetingTypes.clientCaregiverInterview.name ? undefined: () => handleTogglePatient(pid) }
                    color="error"
                    variant="contained"
                    sx={{ m: 1 }}
                  />
                );
              })}

            {/* Selected Caregivers Chips */}
            {selectedCaregiverids?.map((cgid) => {
              const caregiver = cgs?.find((cg) => cg.id == cgid);
              return (
                caregiver && (
                  <Chip
                    key={cgid}
                    label={`Caregiver: ${caregiver.firstName}, ${caregiver.lastName}`}
                    onDelete={() => handleToggleCaregiver(cgid)}
                    color="info"
                    variant="contained"
                    sx={{ m: 1 }}
                  />
                )
              );
            })}
            {/* Selected Staff Chips */}
            {selectedStaffids.map((sid) => {
              const staff = staffs.find((s) => s.id === sid);
              return (
                staff && (
                  <Chip
                    key={sid}
                    label={`Staff: ${staff.username}`}
                    onDelete={sid != userInfo.user_id ? () => handleToggleStaff(sid) : null}
                    color="success"
                    variant="contained"
                    sx={{ m: 1 }}
                  />
                )
              );
            })}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Other EmailIds (optional)"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              // freeSolo
              value={meetingType.name || ""}
              onChange={handleMeetingTypeChange}
              inputValue={meetingType.name || ""}
              onInputChange={handleMeetingTypeChange}
              options={meeting_type_options}
              renderInput={(params) => (
                <TextField {...params} label="Meeting Type" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email Content"
              value={emailContent}
              multiline
              rows={10}
              onChange={(e) => setEmailContent(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="contained" type="submit" onClick={schedule}>
              Schedule Appointment
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Popover
        open={openPatient}
        anchorEl={anchorElPatient}
        onClose={handlePopoverClosePatient}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {selectedClient &&
            selectedClient?.patients?.map((p) => (
              <ListItem
                key={p.id}
                role={undefined}
                dense
                style={{ cursor: "pointer" }}
                onClick={() => handleTogglePatient(p.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedPatientids.indexOf(p.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={p.firstName + ", " + p.lastName} />
              </ListItem>
            ))}
        </List>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleOkClickPatient}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Popover>
      <Popover
        open={openClient}
        anchorEl={anchorElClient}
        onClose={handlePopoverCloseClient}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {clients.map((client) => (
            <ListItem
              key={client.id}
              role={undefined}
              dense
              style={{ cursor: "pointer" }}
              onClick={() => handleSelectClient(client)} // Handle selection directly
            >
              <ListItemText
                primary={
                  client.firstName +
                  ", " +
                  client.lastName +
                  " (" +
                  client.id +
                  ")"
                }
              />
            </ListItem>
          ))}
        </List>
      </Popover>

      <Popover
        open={openCaregiver}
        anchorEl={anchorElCaregiver}
        onClose={handlePopoverCloseCaregiver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {caregivers.map((cg) => (
            <ListItem
              key={cg.id}
              role={undefined}
              dense
              style={{ cursor: "pointer" }}
              onClick={() => handleToggleCaregiver(cg.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedCaregiverids.indexOf(cg.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                primary={cg.firstName + ", " + cg.lastName + " (" + cg.id + ")"}
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleOkClickCaregiver}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Popover>

      <Popover
        open={openStaff}
        anchorEl={anchorElStaff}
        onClose={handlePopoverCloseStaff}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          {staffs.map((s) => (
            <ListItem
              key={s.id}
              role={undefined}
              dense
              style={{ cursor: "pointer" }}
              onClick={() => handleToggleStaff(s.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedStaffids.indexOf(s.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={s.username + " (" + s.id + ")"} />
            </ListItem>
          ))}
        </List>
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleOkClickStaff}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </Box>
      </Popover>
    </LocalizationProvider>
  );
};

export default ScheduleAppointment;
