import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './Theme'; // Import your custom theme
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'components/SnackbarProvider';
import { LoadingProvider } from 'components/LoadingProvider';
import store from 'slices/store';
import ReactGA from 'react-ga';

// Initialize Google Analytics
ReactGA.initialize('G-G10XSPLW5Y'); 
ReactGA.pageview(window.location.pathname + window.location.search); // Track initial page load

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LoadingProvider>
    <SnackbarProvider >
      <Provider store={store}>
      <App />
      </Provider>
      </SnackbarProvider>
      </LoadingProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
