import React, { useState, useEffect } from 'react';
import {
  Card, CardContent, Typography, Button, Grid, Box, Menu, MenuItem, Chip,
  TextField, InputAdornment,
  styled,
  CardHeader,
  Tab,
  Tabs,
} from '@mui/material';
import { useAddContractMutation, useUpdateContractMutation, useGetContractByIdQuery } from 'services/contractApi';
import { useGetClientByIdQuery, useGetClientsQuery } from 'services/clientApi';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'components/SnackbarProvider';
import { useLoading } from 'components/LoadingProvider';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getColor } from 'common/utilities';
import { render } from '@testing-library/react';
import { billingsColumns, billingsColumnVisibility, billingsSorting } from 'CommonData/billingsColumns';
import Table from 'components/Table';
import { useGetCaregiverByIdQuery } from 'services/caregiverApi';
import { CaregiverStatuses, ClientStatuses, PatientStatuses } from 'CommonData/Common';

const ContractCard = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const client_id = params.get('client_id');
  const { contractId } = useParams();
  const { clientId } = useParams();
  const [isEditing, setIsEditing] = useState(!contractId); // Start in editing mode if no contractId
  const [formData, setFormData] = useState({
    client: '',
    start_date: dayjs(),
    end_date: dayjs(),
    payment_terms: '',
    total_amount: '',
    status: 'active'
  });
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const { data: contract, error: contractError, isLoading: isContractLoading , refetch} = useGetContractByIdQuery(contractId, { skip: !contractId });
  const [addContract, { isLoading: isAddingContract, error: addContractError }] = useAddContractMutation();
  const [updateContract, { isLoading: isUpdatingContract, error: updateContractError }] = useUpdateContractMutation();
  const { data: clients, error: clientsError, isLoading: isClientsLoading } = useGetClientsQuery();

  const [contractableClients, setContractableClients] = useState([]);
  const { data: clientInContract , isLoading : isClntLoading, error: clntError, isFetching: isClntFetching } = useGetClientByIdQuery(
    selectedClientId, 
    {skip : !selectedClientId}
  );
  const [caregiverId, setCaregiverId] = useState(null);
  const [selectedCaregiver, setSelectedCaregiver] = useState(null);
  const { data: caregiverInContract, isLoading: isCaregiverLoading, error:cgerror, isFetching: iscgFetching } = useGetCaregiverByIdQuery(
    caregiverId, { skip: !caregiverId }
  );

  useEffect(()=>{
    if(contract){
    refetch()
    }
  },[])

  useEffect(()=>{
    if (clients){
      const ccs = clients.filter(c=> c.status == ClientStatuses.CG_SELECTED.name
        || c.status == ClientStatuses.ACTIVE.name
      )
      setContractableClients(ccs)
    }
  },[clients])

  const [anchorEl, setAnchorEl] = useState(null);
  const [patientAnchorEl, setPatientAnchorEl] = useState(null); // Anchor for the patient menu

  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();

  const isLoading = iscgFetching ||isCaregiverLoading||isClntFetching || isClntLoading || isContractLoading || isClientsLoading || isAddingContract || isUpdatingContract;
  const error = cgerror || clntError || clientsError || contractError || addContractError || updateContractError;


  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else { 
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.data?.message, "Operation failed");
    }
  }, [error]);
 
  useEffect(()=>{
    if (client_id){
       setSelectedClientId(client_id)
    }
  },[client_id])

  useEffect(() => {
    if (contract) {
      setFormData({
        client: contract.client,
        start_date: dayjs(contract.start_date),
        end_date: dayjs(contract.end_date),
        payment_terms: contract.payment_terms,
        total_amount: contract.total_amount,
        status: contract.status,
        ...contract
      });
      // if (clients){
        // const client = clients.find(client => client.id === contract.client);
        setSelectedClientId(contract?.client);
      // }
    }
  }, [contract]);
  // }, [contract, clients]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name) => (date) => {
    setFormData({ ...formData, [name]: date });
  };

  // useEffect(()=>{
  //   if(contract){
  //     console.log(contract.patient)
  //     handleParticipantChange(selectedClient, contract.patient)
  //   }
  // },[contract])
  const handleSubmit = async () => {
    try {
      startLoading();
      if (contractId) {
        const result = await updateContract({
           id: contractId, 
           ...formData, 
           client: selectedClient.id,
           caregiver: selectedCaregiver.id,
           patient: selectedPatient.id,
           caregiver_status: CaregiverStatuses.ACTIVE.name,
           client_status: ClientStatuses.ACTIVE.name,
           patient_status: PatientStatuses.ACTIVE.name,
           start_date: formData.start_date.format("YYYY-MM-DD"),
           end_date: formData.end_date.format("YYYY-MM-DD"),
        });
        if (result.error) {
          throw result.error;
        }
        showSnackbar("Contract updated successfully", "success");
      } else {
        const result = await addContract({
          ...formData,
          client: selectedClient?.id,
          caregiver: selectedCaregiver?.id,
          patient: selectedPatient?.id,
          caregiver_status: CaregiverStatuses.IN_SERVICE.name,
          client_status: ClientStatuses.ACTIVE.name,
          patient_status: PatientStatuses.ACTIVE.name,
          start_date: formData.start_date.format("YYYY-MM-DD"),
          end_date: formData.end_date.format("YYYY-MM-DD"),
        });
        if (result.error) {
          throw result.error;
        }
        showSnackbar("Contract added successfully", "success");
      }
      setIsEditing(false);
    } catch (err) {
      showSnackbar("Contract add/update failed", "error");
      console.error('Failed to save contract:', err);
    }finally {
      stopLoading();
      const params = new URLSearchParams(location.search);
      const previousURL = params.get("redirect");
      if (previousURL) {
        navigate(previousURL);
      } else {
        navigate("/contracts"); // Fallback URL if no previous URL is stored
      }
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePatientMenuClose = () => {
    setPatientAnchorEl(null);  
  };
  useEffect(()=>{
    if (clientInContract){
      handleParticipantChange(clientInContract, contract?.patient)
    }
  },[clientInContract])

  useEffect(()=>{
    if(caregiverId){
      setSelectedCaregiver(caregiverInContract)
    }
    else {
      setSelectedCaregiver(null)
    }
  },[caregiverInContract, caregiverId])

  const handleParticipantChange = (clientObj, patientid=null)=>{
    if (clientObj){
      setSelectedClient(clientObj)
    }
    const client = clientObj || selectedClient
    if (patientid){      
      const pat = client?.patients?.find(p => p.id == patientid)
      setSelectedPatient(pat)
      setCaregiverId(pat?.caregiver?.id)
    } else {
      const pat = client?.patients?.[0] || null
      setSelectedPatient(pat)
      setCaregiverId(pat?.caregiver?.id)
    }
  }
  const handlePatientSelect = (id) =>{
    // console.log(id)
    handleParticipantChange(null,id)
    handlePatientMenuClose()
  }
  const handleMenuClose = (clientid) => {
    // setSelectedClient(client);
    setSelectedClientId(clientid)
    // setSelectedPatient(client?.patient)
    setCaregiverId(null)
    setFormData({ ...formData, client: clientid });
    setAnchorEl(null);
  };
  const handlePatientButtonClick = (event) => {
    setPatientAnchorEl(event.currentTarget);
  };
  const renderEditCard = () => (
    <Card>
      <CardContent  sx={{ml:3 }}>
        <Typography variant="h5" gutterBottom>
          {contractId ? 'Contract' : 'Create Contract'}
        </Typography>
        <Grid container spacing={3}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item xs={12}>
  <Grid container spacing={1} alignItems="center">
    {/* Client Field */}
    <Grid item>
      <Button
        variant="contained"
        color="primary"
        disabled={
          contractId ||
          clientId ||
          contractableClients.length==0
        }
        onClick={handleButtonClick}
      >
        {contractableClients.length==0 ? "No Eligible Client" : selectedClient ? "Change Client" : "Select Client"}
      </Button>
    </Grid>
    {selectedClient && (
      <Grid item>
        <Chip
          label={
            (isClntFetching ? "Fetching..." : "") +
            `Client: ${selectedClient.firstName}, ${selectedClient.lastName} (${selectedClient.id})`
          }
          color="warning"          
          onClick={()=> navigate(`/clients/${selectedClient.id}`)}
          variant="contained"
          sx={{ m: 1 }}
        />
      </Grid>
    )}
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      {contractableClients?.map((client) => (
        <MenuItem key={client.id} onClick={() => handleMenuClose(client?.id)}>
          {client.firstName + ", " + client.lastName + " (" + client.id + ")"}
        </MenuItem>
      ))}
    </Menu>

    {/* Patient Field */}
    {selectedClient && (
      <Grid item>
          <Grid container spacing={1} alignItems="center">
        {selectedClient.patients && selectedClient.patients.length > 1 && (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="primary"                
                disabled={contractId}
                onClick={handlePatientButtonClick}
              >
                Select Patient
              </Button>
            </Grid>
            <Grid item>
              <Menu
                anchorEl={patientAnchorEl}
                open={Boolean(patientAnchorEl)}
                onClose={handlePatientMenuClose}
              >
                {selectedClient?.patients?.map((patient) => (
                  <MenuItem key={patient.id}
                   onClick={()=> handlePatientSelect(patient?.id)}
                  >
                    {patient?.firstName + ", " + patient?.lastName + " (" + patient?.id + ")"}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </>
        ) 
        }
        { selectedPatient ? (
          <Chip
            label={`Patient: ${selectedPatient?.firstName}, ${selectedPatient?.lastName} (${selectedPatient?.id})`}
            color="info"
            sx={{ m: 1 }}
            onClick={()=> navigate(`/patients/${selectedPatient.id}`)}
            />
        ) : (
          <Chip
            label="No patients available"
            color="info"
            sx={{ m: 1 }}
          />
        )}
        {/* Caregiver Field */}
        {selectedCaregiver ? (
          <Chip
            label={`Caregiver: ${selectedCaregiver?.firstName}, ${selectedCaregiver?.lastName} (${selectedCaregiver?.id})`}
            color="success"
            onClick={()=> navigate(`/caregivers/${selectedCaregiver.id}`)}
            sx={{ m: 1 }}
          />
        ): (
          <Chip
            label="No caregiver assigned"
            color="success"
            sx={{ m: 1 }}
          />
        )}
      </Grid>
      </Grid>
    )}
  </Grid>
</Grid>

        {/* Date Fields */}
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Start Date"
            sx={{ width: "100%" }}
            value={dayjs(formData.start_date)}
            onChange={handleDateChange("start_date")}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="End Date"
            sx={{ width: "100%" }}
            value={dayjs(formData.end_date)}
            onChange={handleDateChange("end_date")}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Total Amount"
            name="total_amount"
            type="number"
            fullWidth
            value={formData.total_amount}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Status"
            name="status"
            select
            fullWidth
            value={formData.status}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
          >
            <option value="active">Active</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Payment Terms"
            name="payment_terms"
            fullWidth
            multiline
            rows={5}
            value={formData.payment_terms}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}   
        display="flex" 
        justifyContent="center" 
        alignItems="center">
          
            <Button variant="contained" sx={{mr:1}} color="primary" onClick={handleSubmit}
            disabled={!selectedClient}
            >
              {contractId ? "Save" : "Create"}
            </Button>
            {contractId && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={toggleEdit}
              >
                Cancel
              </Button>
            )}
        </Grid>
      </LocalizationProvider>
      </Grid>
      </CardContent>
    </Card>
  );
  
  const ViewContract = () => (
    
    <Grid container sx={{m:2}} spacing={3}>
    {/* Client Field */}
    <Grid item xs={4} bgcolor={"white"} borderRadius={3} container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>Client Name:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {selectedClient ? `${selectedClient.firstName}, ${selectedClient.lastName} (${selectedClient.id})` : 'None'}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>Start Date:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {dayjs(formData.start_date).format('YYYY-MM-DD')}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>End Date:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {dayjs(formData.end_date).format('YYYY-MM-DD')}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>Total Amount:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {formData.total_amount} $
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <strong>Status:</strong>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Chip
          label={formData.status}
          color="info"
          size="small"
          sx={{ ml: 1, bgcolor: getColor(formData?.status) }}
        />
      </Grid>

      {formData.client && (
        <>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <strong>Client:</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Chip
              label={formData.client.lastName + ", " + formData.client.firstName}
              color="info"
              size="small"
              sx={{ ml: 1 }}
              clickable
              component={Link}
              to={`/clients/${formData?.client.id}`}
            />
          </Grid>
        </>
      )}

      {formData.patient && (
        <>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <strong>Patient:</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Chip
              label={formData.patient.lastName + ", " + formData.patient.firstName}
              color="success"
              size="small"
              sx={{ ml: 1 }}
              component={Link}
              clickable
              to={`/patients/${formData?.patient.id}`}
            />
          </Grid>
        </>
      )}

      {formData.caregiver && (
        <>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <strong>Caregiver:</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Chip
              label={formData.caregiver.lastName + ", " + formData.caregiver.firstName}
              color="warning"
              size="small"
              clickable
              sx={{ ml: 1 }}
              component={Link}
              to={`/caregivers/${formData?.caregiver.id}`}
            />
          </Grid>
        </>
      )}
    </Grid>

    <Grid item xs={8}>
      <Typography variant="body1"  sx={{ mb: 2 }}>
        <strong>Terms and Conditions:</strong>
      </Typography>
      <Typography variant="body1"  sx={{ mb: 2 }}>
        {formData.payment_terms}
      </Typography>
    </Grid>
    <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Button variant="contained" color="primary" onClick={toggleEdit}>
        Edit
      </Button>
    </Grid>
    </Grid>
  )
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  
  const onAddClick = () => {
    const previousURL = encodeURIComponent(location.pathname);
    navigate(`${location.pathname}/billing?redirect=${previousURL}`);
  };
  const renderViewCard = () => (
    <Box display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
    <Card sx={{width: "100%" , borderRadius:5}}>
      <CardHeader
        title="Payment Contract"
        sx={{
          backgroundColor: 'primary.main', // Background color for the header
          color: 'white', // Text color for the header
        }}
      />
      <CardContent  sx={{bgcolor:"whitesmoke" ,m:3}}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="contract details tabs">
          <Tab label="Details" />
          <Tab label="Billings" />
        </Tabs>
        <Box sx={{ p: 3 }}>
          {currentTab === 0 && (
            <ViewContract/>
          )}
          {currentTab === 1 && (
              <Table
                data={contract?.billings || []}
                title="Billings"
                onAddClick={onAddClick}
                columns={billingsColumns}
                rowClickUrl="/billings/"
                chipField="status"
                sorting={billingsSorting}
                columnVisibility={billingsColumnVisibility}
              />
          )}
        </Box>
      </CardContent>
    </Card>
    </Box>
  );

  return (
    <>
    {isEditing ? renderEditCard() : renderViewCard()}
    </>
  );
};

export default ContractCard;