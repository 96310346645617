import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  patients: [],
  clientPatients: null,
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatients(state, action) {
      state.patients = action.payload;
    },
    setclientPatients(state, action) {
      state.clientPatients = action.payload;
    },
  },
});

export const { setPatients, setclientPatients } = patientSlice.actions;

const selectPatients = (state) => state.patient.patients;
const selectClientPatients = (state) => state.patient.clientPatients;

export const selectPatientById = (patientId) =>
    createSelector([selectPatients], (patients) =>
      patients.find((patient) => patient.id === patientId) || null
);


export const selectClientPatientById = (patientId) =>
  createSelector([selectClientPatients], (patients) =>
    patients.find((patient) => patient.id == patientId) || null
);
  
  
export default patientSlice.reducer;
