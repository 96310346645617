// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import clientApi from 'services/clientApi';
import ClientReducer from './clientslice';
import PatientReducer from './patientslice';
import globalReducer from './globaslice'
import authApi from 'services/authApi';
import appointmentApi from 'services/appointmentApi';
import patientApi from 'services/patientApi';
import caregiverApi from 'services/caregiverApi';
import userApi from 'services/userApi';
import noteApi from 'services/noteApi';
import contractApi from 'services/contractApi';
import billingApi from 'services/billingApi';
import { dashboardApi } from 'services/dashboardApi';

const store = configureStore({
  reducer: {
    [clientApi.reducerPath]: clientApi.reducer,
    [caregiverApi.reducerPath]: caregiverApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [appointmentApi.reducerPath]: appointmentApi.reducer,
    [patientApi.reducerPath]: patientApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [noteApi.reducerPath]: noteApi.reducer,
    [contractApi.reducerPath]: contractApi.reducer,
    [billingApi.reducerPath]: billingApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    client: ClientReducer,
    global: globalReducer,
    patient: PatientReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      clientApi.middleware,
      authApi.middleware,
      appointmentApi.middleware,
      patientApi.middleware,
      caregiverApi.middleware,
      userApi.middleware,
      noteApi.middleware,
      contractApi.middleware,
      billingApi.middleware,
      dashboardApi.middleware,
    ),
});

setupListeners(store.dispatch);

export default store;
