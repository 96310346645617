import React, { useState, useEffect } from 'react';
import {
  Card, CardContent, Typography, Button, Grid, Box, Menu, MenuItem, Chip,
  TextField, InputAdornment, styled, CardHeader
} from '@mui/material';
import { useAddBillingMutation, useUpdateBillingMutation, useGetBillingByIdQuery } from 'services/billingApi';
import { useGetClientsQuery } from 'services/clientApi';
import { useGetContractByIdQuery, useGetContractsQuery } from 'services/contractApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'components/SnackbarProvider';
import { useLoading } from 'components/LoadingProvider';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ClientStatuses } from 'CommonData/Common';

const BillingCard = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const client_id = params.get('client_id');
  const { billingId } = useParams();
  const { contractId } = useParams();
  const [isEditing, setIsEditing] = useState(!billingId); // Start in editing mode if no billingId
  const [formData, setFormData] = useState({
    client: '',
    contract: '',
    amount: '',
    payment_date: dayjs(),
    status: 'pending',
    method: 'credit_card',
    transaction_id: ''
  });

  const { data: billing, error: billingError, isLoading: isBillingLoading } = useGetBillingByIdQuery(billingId, { skip: !billingId });
  const [addBilling, { isLoading: isAddingBilling, error: addBillingError }] = useAddBillingMutation();
  const [updateBilling, { isLoading: isUpdatingBilling, error: updateBillingError }] = useUpdateBillingMutation();
  const { data: clients, error: clientsError, isLoading: isClientsLoading } = useGetClientsQuery();
  const { data: contracts, error: contractsError, isLoading: isContractsLoading } = useGetContractsQuery();
  const { data: contract, error: contractError, isLoading: isContractLoading } = useGetContractByIdQuery(contractId, { skip: !contractId });
  const [selectedClientContracts, setSelectedClientContracts] = useState([]);
  const [anchorElClient, setAnchorElClient] = useState(null);
  const [anchorElContract, setAnchorElContract] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [billableClients, setBillableClients] = useState([]);

  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();
  const isLoading = isContractLoading || isBillingLoading || isClientsLoading || isContractsLoading || isAddingBilling || isUpdatingBilling;
  const error = contractError || billingError || clientsError ||contractsError  || addBillingError || updateBillingError;
  const navigate = useNavigate();

  useEffect(()=>{
    if (clients){
      const ccs = clients.filter(c=> c.status == ClientStatuses.ACTIVE.name)
      setBillableClients(ccs)
    }
  },[clients])

  useEffect(()=>{
    if(contract){
      setSelectedContract(contract)
      const client = clients.find(client => client.id == contract.client);
      setSelectedClient(client || null);

    }
  },[contract])

  
  useEffect(() => {
    if (client_id && clients) {      
      const client = clients.find(client => client.id == client_id);
      setSelectedClient(client || null);
    }
  }, [clients, client_id]);

  useEffect(()=>{
    if(selectedClient && contracts){
      const clntContracts = contracts?.filter(c => c.client?.id == selectedClient.id);
      setSelectedClientContracts(clntContracts || [])
      // console.log(clntContracts)
    }
  }, [selectedClient, contracts])

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.data?.message || 'Operation failed', 'error');
    }
  }, [error]);

  useEffect(() => {
    if (billing) {
      setFormData({
        client: billing.client,
        contract: billing.contract,
        amount: billing.amount,
        payment_date: dayjs(billing.payment_date),
        status: billing.status,
        method: billing.method,
        transaction_id: billing.transaction_id
      });
      const client = clients?.find(client => client.id === billing.client);
      const contract = selectedClientContracts?.find(contract => contract.id === billing.contract);
      setSelectedClient(client || null);
      setSelectedContract(contract || null);
    }
  }, [billing, clients, selectedClientContracts]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, payment_date: date });
  };

  const handleSubmit = async () => {
    try {
      startLoading();
      if (billingId) {
        const result = await updateBilling({
          id: billingId,
          ...formData,
          payment_date: formData.payment_date.format("YYYY-MM-DD")
        });
        if (result.error) {
          throw result.error;
        }
        showSnackbar("Billing updated successfully", "success");
      } else {
        const result = await addBilling({
          ...formData,
          client: selectedClient.id,
          contract: selectedContract.id,
          payment_date: formData.payment_date.format("YYYY-MM-DD")
        });
        if (result.error) {
          throw result.error;
        }
        showSnackbar("Billing added successfully", "success");
      }
      setIsEditing(false);
    } catch (err) {
      showSnackbar("Billing add/update failed", "error");
      console.error('Failed to save billing:', err);
    } finally {
      stopLoading();
      const params = new URLSearchParams(location.search);
      const previousURL = params.get("redirect");
      if (previousURL) {
        navigate(previousURL);
      } else {
        navigate("/billings"); // Fallback URL if no previous URL is stored
      }
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleButtonClickClient = (event) => {
    setAnchorElClient(event.currentTarget);
  };

  const handleButtonClickContract = (event) => {
    setAnchorElContract(event.currentTarget);
  };

  const handleMenuCloseClient = (client) => {
    setSelectedClient(client);
    setFormData({ ...formData, client: client.id });
    setAnchorElClient(null);
  };

  const handleMenuCloseContract = (contract) => {
    setSelectedContract(contract);
    setFormData({ ...formData, contract: contract.id });
    setAnchorElContract(null);
  };

  const renderEditCard = () => (
    <Card>
      <CardContent sx={{ ml: 3 }}>
        <Typography variant="h5" gutterBottom>
          {billingId ? 'Billing' : 'Create Billing'}
        </Typography>
        <Grid container spacing={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* Client Field */}
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleButtonClickClient}
                    disabled={contractId ||
                      billableClients?.length == 0

                    }
                  >
                    {billableClients.length==0 ? "No Eligible Client" : selectedClient ? "Change Client" : "Select Client"}
                  </Button>
                </Grid>
                {selectedClient && (
                  <Grid item>
                    <Chip
                      label={`${selectedClient.firstName}, ${selectedClient.lastName} (${selectedClient.id})`}
                      color="warning"
                      variant="contained"
                      sx={{ m: 1 }}
                    />
                  </Grid>
                )}
              </Grid>
              <Menu
                anchorEl={anchorElClient}
                open={Boolean(anchorElClient)}
                onClose={() => setAnchorElClient(null)}
              >
                {billableClients?.map((client) => (
                  <MenuItem key={client.id} onClick={() => handleMenuCloseClient(client)}>
                    {client.firstName + ", " + client.lastName + " (" + client.id + ")"}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>

            {/* Contract Field */}
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Button
                    variant="contained"
                    disabled={
                      selectedClientContracts.length == 0 ||
                      contractId
                    }
                    color="primary"
                    onClick={handleButtonClickContract}
                  >
                    {selectedContract ? "Change Contract" : selectedClientContracts.length != 0 ? "Select Contract" : "No Contracts"}
                  </Button>
                </Grid>
                {selectedContract && (
                  <Grid item>
                    <Chip
                      label={`Contract ${selectedContract.id}`}
                      color="warning"
                      variant="contained"
                      sx={{ m: 1 }}
                    />
                  </Grid>
                )}
              </Grid>
              <Menu
                anchorEl={anchorElContract}
                open={Boolean(anchorElContract)}
                onClose={() => setAnchorElContract(null)}
              >
                {selectedClientContracts?.map((contract) => (
                  <MenuItem key={contract.id} onClick={() => handleMenuCloseContract(contract)}>
                    {`Contract ID ${contract.id}`}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>

            {/* Date Field */}
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Payment Date"
                sx={{ width: "100%" }}
                value={formData.payment_date}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount"
                name="amount"
                type="number"
                fullWidth
                value={formData.amount}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Status"
                name="status"
                select
                fullWidth
                value={formData.status}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="pending">Pending</option>
                <option value="paid">Paid</option>
                <option value="failed">Failed</option>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Payment Method"
                name="method"
                select
                fullWidth
                value={formData.method}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="credit_card">Credit Card</option>
                <option value="paypal">PayPal</option>
                <option value="bank_transfer">Bank Transfer</option>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Transaction ID"
                name="transaction_id"
                fullWidth
                value={formData.transaction_id}
                onChange={handleChange}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isAddingBilling || isUpdatingBilling || !selectedContract}
          >
            {billingId ? 'Update Billing' : 'Add Billing'}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={toggleEdit}
            sx={{ ml: 2 }}
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
  const renderViewCard = () => (
    <Box sx={{width:"100%"}} display={"flex"} justifyContent={"center"}>
    <Card sx={{width:"80%", borderRadius:5}} >
    <CardHeader
        title="Billing Information"
        sx={{
          backgroundColor: 'primary.main', // Background color for the header
          color: 'white', // Text color for the header
        }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {/* Client */}
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Client</Typography>
            <Typography variant="body1">
              {selectedClient ? `${selectedClient.firstName} ${selectedClient.lastName}` : 'N/A'}
            </Typography>
          </Grid>
          {/* Contract */}
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Contract</Typography>
            <Typography variant="body1">
              {selectedContract ? `Contract ${selectedContract.id}` : 'N/A'}
            </Typography>
          </Grid>
          {/* Amount */}
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Amount</Typography>
            <Typography variant="body1">${formData.amount}</Typography>
          </Grid>
          {/* Payment Date */}
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Payment Date</Typography>
            <Typography variant="body1">{formData.payment_date.format('YYYY-MM-DD')}</Typography>
          </Grid>
          {/* Status */}
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Status</Typography>
            <Typography variant="body1">{formData.status}</Typography>
          </Grid>
          {/* Payment Method */}
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Payment Method</Typography>
            <Typography variant="body1">{formData.method}</Typography>
          </Grid>
          {/* Transaction ID */}
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Transaction ID</Typography>
            <Typography variant="body1">{formData.transaction_id}</Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleEdit}
              sx={{ mt: 2 }}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </Box>
  );
  return isEditing ? renderEditCard() : renderViewCard();
};

export default BillingCard;
