import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import logo from 'assets/slog.png';
import {jwtDecode} from 'jwt-decode';
import DeleteIcon from '@mui/icons-material/Delete';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccessibleRoundedIcon from '@mui/icons-material/AccessibleRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { BorderColor } from '@mui/icons-material';
import Table from 'components/Table';
import { lighten, darken } from '@mui/system';

import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Tooltip } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useLoading } from 'components/LoadingProvider';
import { useSnackbar } from 'components/SnackbarProvider';
import { selectedMenu, setSelectedMenuItem, setUserInfo } from 'slices/globaslice';
import { useDispatch, useSelector } from 'react-redux';
import { useLogoutMutation } from 'services/authApi';
import AppBreadcrumbs from 'components/Patient/AppBreadCrumb';
import UserAccountMenu from 'components/UserAccountMenu';
import { menuitems } from 'CommonData/Common';

const drawerWidth = 240;


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MenuButton = ({ item, open, selectedMenuItem, handleMenuItemClick }) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <Tooltip title={item.name}
    placement="right" 
    arrow 
    enterDelay={500} 
    disableHoverListener={open}
    // leaveDelay={200}
    >
    <ListItem disablePadding sx={{ py: 0.2 }} key={item.id}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
          color: '#637381',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: 2,
            // opacity:0.5,
            color: theme.palette.primary.dark,
            '& .MuiListItemIcon-root': {
              color: theme.palette.primary.dark,
            },
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            borderRadius: 2,
            '& .MuiListItemIcon-root': {
              color: theme.palette.primary.contrastText,
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.primary.contrastText,
              borderRadius: 2,
            },
          },
        }}
        onClick={() => handleMenuItemClick(item.id, item.url)}
        selected={selectedMenuItem === item.id}
      >
        <ListItemIcon
          sx={{
            color: 'inherit', // This will inherit the color from the parent
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
            flexDirection: 'column', // Stack icon and text vertically
            alignItems: 'center', // Center align icon and text
          }}
        >
          {item.icon}
          {!open && (
            <Typography
              variant="caption"
              sx={{
                fontSize: 8, // Small text size
                // color: theme.palette.primary.contrastText, // Text color
                mt: 0.5, // Add margin to create spacing between icon and text
              }}
            >
              {item.name}
            </Typography>
          )}
        </ListItemIcon>
        <ListItemText
          primary={item.name}
          sx={{
            opacity: open ? 1 : 0,
          }}
        />
      </ListItemButton>
    </ListItem>
    </Tooltip>
  );
};


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function HomeLayout(props) {
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const selectedMenuItem = useSelector(selectedMenu);
  const showSnackbar = useSnackbar();
  const [logoutMutation] = useLogoutMutation();
  const [initialLoad, setInitialLoad] = React.useState(false)

  const { isLoading, startLoading, stopLoading } = useLoading();

  const location = useLocation();

  React.useEffect(() => {
    // Track page views on route change
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  React.useEffect(()=>{
    if (!initialLoad && isLoading){
      setInitialLoad(true)
    }
    if(initialLoad && !isLoading && selectedMenuItem==1){
      const timer = setTimeout(() => {
        setOpen(false);
    }, 1000); 
    return () => clearTimeout(timer);

    }
  },[initialLoad, isLoading])

  React.useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
        const decodedToken = jwtDecode(token);
        dispatch(setUserInfo({
          user_id: decodedToken.user_id,
          username: decodedToken.name,
          firstName: decodedToken.first_name,
          lastName: decodedToken.last_name,
          email: decodedToken.email,
          isStaff: decodedToken.is_staff,
          isSuperuser: decodedToken.is_superuser,
        }));
    }
  }, []);

  const navigate = useNavigate();
  const logout = async () => {
    startLoading();

    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      const data = { 
        token: accessToken,
        refresh_token: refreshToken 
      }
      await logoutMutation(data);
      showSnackbar('Logged Out successfully', 'success');
    } catch (error) {
      console.error('Logged out with error', error);
      showSnackbar('Logged out with error', 'error');
      navigate('/'); // Navigate to login page on error
    } finally {
      localStorage.clear();
      navigate('/');
      stopLoading();
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (id, url) => {
    dispatch(setSelectedMenuItem(id));
    navigate(url);
  };

  return (
  <Box  bgcolor={"#f5f5f5"} sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
  <CssBaseline />
  <AppBar position="fixed" open={open}>
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{
          marginRight: 5,
          ...(open && { display: 'none' }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <AppBreadcrumbs/>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <UserAccountMenu logout={logout}/>
      </Box>
    </Toolbar>
  </AppBar>
  <Drawer variant="permanent" open={open}>
    <Box sx={{ backgroundColor: "white", minWidth: 50, height: "100%" }}>
      <DrawerHeader>
        <Box component="img" 
          src={logo} 
          alt="AtHomeGeriCare"
          onClick={() => navigate('/')} 
          sx={{position:"absolute",left:10, pt: 1, height:70, width: 150, cursor: "pointer" }} />
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon color="primary" />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List sx={{ px: 0.5 }}>
        <ListItem disablePadding sx={{ px: 2 }}>
          <ListItemText primaryTypographyProps={{ fontSize: '12px' }} primary="OVERVIEW" sx={{ color: "#637381", opacity: open ? 1 : 0 }} />
        </ListItem>
        {menuitems.slice(0, 1).map((item, index) => (
          <MenuButton key={item.id} item={item} open={open} selectedMenuItem={selectedMenuItem} handleMenuItemClick={handleMenuItemClick} />
        ))}
        <ListItem disablePadding sx={{ px: 2 }}>
          <ListItemText primaryTypographyProps={{ fontSize: '12px' }} primary="MANAGEMENT"
            sx={{ color: "#637381", display: open ? "flex" : "none" }} />
        </ListItem>
        {menuitems.slice(1).map((item, index) => (
          <MenuButton key={item.id}  item={item} open={open} selectedMenuItem={selectedMenuItem} handleMenuItemClick={handleMenuItemClick} />
        ))}
      </List>
    </Box>
  </Drawer>
  <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden'}}>
    <Box sx={{ display: "flex", maxWidth: "100%", pt: 8 }}>
      {/* {props.children} */}
      <Outlet/>
    </Box>
  </Box>
</Box>

  );
}
