import React, { useState } from "react";
import { Fab, Grid } from "@mui/material";
import ProfileCard from "./ProfileCard";
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import PersonalInfo from "./PersonalInfo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetClientByIdQuery, useUpdateClientMutation } from "services/clientApi";
import { ClientStatuses } from "CommonData/Common";
import { useSnackbar } from "./SnackbarProvider";
import { useLoading } from "./LoadingProvider";
import dayjs from "dayjs";

const ClientProfileView = () => {
  const { clientId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [updateClient] = useUpdateClientMutation(); // Initialize the update mutation

  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();

  const { data: currentClient, isLoading, error } = useGetClientByIdQuery(clientId);
  const [clientProfile, setClientProfile] = useState(currentClient);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientProfile({ ...clientProfile, [name]: value });
};

const handleChangeNameValue = (name,value) => {
  setClientProfile({ ...clientProfile, [name]: value });
};
const handleDateChange = (date, name) => {
    setClientProfile({ ...clientProfile, [name]: date });
};

const save = async () => {
  // setIsEditing(false);
  startLoading();
  try {
      const formattedProfile = {
          ...clientProfile,
          expectedServiceStart: clientProfile?.expectedServiceStart
              ? dayjs(clientProfile?.expectedServiceStart).format('YYYY-MM-DD')
              : undefined,
      };
      const result = await updateClient(formattedProfile); // Send the updated profile data
      if (result.error) {
          throw result.error;
        }
        showSnackbar("Client Updated successfully", "success");
  } catch (error) {
      showSnackbar("Client Update Failed", "error");
      console.error('Failed to update client:', error);
  } finally { 
      stopLoading();
  }
};

  const onAddClick = () => {
    const previousURL = encodeURIComponent(location.pathname);
    navigate(
      `/clients/${currentClient?.id}/patients/new?redirect=${previousURL}&client_id=${currentClient?.id}`
    );
  };

  const handleEditUpdate = () => {
    setIsEditing((prevIsEditing) => {
      if (prevIsEditing) {
        //save here
        save();
      }
      return !prevIsEditing;
    });
  };
  return (
    <Grid container spacing={0.5}>
    <Fab variant="extended" 
     sx={{
      position: 'fixed',
      top: 200, // Adjust as needed
      right: 50, // Adjust as needed
      zIndex: 1000,
    }}
    color="secondary"
    onClick={handleEditUpdate}
    >
      {isEditing ? (
        <>
          <UpdateIcon sx={{ mr: 1 }} />
          Update
        </>
      ) : (
        <>
          <EditIcon sx={{ mr: 1 }} />
          Edit
        </>
      )}
    </Fab>
      {/* Main Profile Card */}
      <Grid
        item
        xs={12}
        md={3}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
      <ProfileCard
        profile={currentClient}
        handleChange={handleChangeNameValue}
        statusOptions={ClientStatuses}
        isEditing={isEditing}
      />
      </Grid>
      {/* Official and Personal Information Card */}
      <Grid item xs={12} md={9}>
        <PersonalInfo
         isEditing={isEditing}
         clientProfile={clientProfile}
         handleChange={handleChange}
         handleDateChange={handleDateChange}
         />
      </Grid>
    </Grid>
  );
};

export default ClientProfileView;
