import React from 'react';
import { Grid, Card, CardContent, Typography, Avatar, Divider, Box, IconButton, CardHeader, Chip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { differenceInDays } from "date-fns"; // To calculate the difference in days
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetClientByIdQuery } from 'services/clientApi';
import { useSelector } from 'react-redux';
import UserAvatar from './UserAvatar';
import { formatdateForNote, formatOnlyDate, getColor } from 'common/utilities';
import dayjs from 'dayjs';

var EmptyData = "Yet to be added";
// ProfileInformation Component
const ProfileInformation = ({setTabIndex}) => {
 
  const { clientId } = useParams();
  const { data: currentClient, isLoading, error } = useGetClientByIdQuery(clientId);
  return (
      <Card elevation={3} sx={{ height:"100%", width:"100%",  position: 'relative' }}>
      <IconButton onClick={()=> setTabIndex(1)} sx={{ position: 'absolute', top: 8, right: 8 }}>
        <VisibilityRoundedIcon fontSize='small' sx={{color:"white"}}/>
      </IconButton>
      <CardHeader
        title="Profile Information" 
        sx={{ bgcolor: 'primary.main', color: 'white' }}
      />
      <CardContent>
        <Typography variant="body1" component="div"   sx={{ mb: 0.5 }}>
          <strong>First Name:</strong> {currentClient?.firstName}
        </Typography>
        <Typography variant="body1" component="div"   sx={{ mb: 0.5 }}>
          <strong>Last Name:</strong> {currentClient?.lastName}
        </Typography>
        <Typography variant="body1" component="div"   sx={{ mb: 0.5 }}>
          <strong>Contact Number:</strong> {currentClient?.contactNumber}
        </Typography>
        <Typography variant="body1" component="div"   sx={{ mb: 0.5 }}>
          <strong>Email:</strong> {currentClient?.email}
        </Typography>
        <Typography variant="body1" component="div"   sx={{ mb: 0.5 }}>
          <strong>Country:</strong> {currentClient?.country}
        </Typography>
        <Typography variant="body1" component="div"   sx={{ mb: 0.5 }}>
          <strong>Expected Service Start:</strong> {currentClient?.expectedServiceStart || "TBD"}
        </Typography>
        <Typography variant="body1" component="div"   sx={{ mb: 0.5 }}>
          <strong>Member Since:</strong> {currentClient && formatOnlyDate(currentClient?.updated_at)}
        </Typography>
      </CardContent>
    </Card> 
  );
};
// RecentUpdate Component
function RecentUpdate ({setTabIndex}) {
  const { clientId } = useParams();
  const { data: currentClient, isLoading, error } = useGetClientByIdQuery(clientId);
  const lastNote = currentClient?.notes?.slice(-1)[0];
  return (
    <Card 
    elevation={3} sx={{ position: 'relative' }}>
      <IconButton onClick={()=> setTabIndex(6)} sx={{ color: "primary.main", position: 'absolute', right: 5, top: 5 }}>
        <AddCircleRoundedIcon  fontSize="small" />
      </IconButton>
      <CardContent> 
        <Typography variant="h5" display="inline">Recent Update
          <Typography variant="body2" color="textSecondary" component="p">
          {lastNote ? lastNote.note : ""}
          </Typography>
        </Typography>
        <Typography variant="caption" color="textSecondary" component="p" sx={{ mt: 1 }}>
        {lastNote ? formatdateForNote(lastNote.updated_at)  : ""}
        </Typography>
      </CardContent>
    </Card>
  );
};


// Individual Info Card Component
const InfoCard = ({ title, content,onClick, contentColor = "black" }) => {

  return (
  <Card elevation={3} 
  sx={{ bgcolor: "whitesmoke", borderRadius: 2, height: '100%' ,cursor: 'pointer', ':hover': {
    cursor: 'pointer',
  }
  }}
  onClick={onClick}
  >
    <CardContent sx={{ padding: '8px' }}>
      <Typography variant="subtitle2" color="textSecondary" sx={{ textAlign: "center", fontSize: 12 }}>{title}</Typography>
      <Typography variant="body2" sx={{ textAlign: "center", fontWeight: "bold", color: contentColor, fontSize: 16 }}>{content}</Typography>
    </CardContent>
  </Card>)
};

function AccountCard({setTabIndex}) {

  
  const { clientId } = useParams();
  const { data: currentClient, isLoading, error } = useGetClientByIdQuery(clientId);
  const getContractDisplayInfo = () => {
    if (!currentClient || !currentClient?.contracts || currentClient?.contracts.length === 0) {
      return { contractContent: 'NA', contractColor: 'error.main' };
    }
  
    const latestContract = currentClient?.contracts.reduce((latest, contract) => {
      const contractEndDate = dayjs(contract.end_date); // Parsing the end date
      if (!latest || contractEndDate.isAfter(dayjs(latest.end_date))) {
        return contract;
      }
      return latest;
    }, null);
  
    if (!latestContract) {
      return { contractContent: 'NA', contractColor: 'error.main' };
    }
  
    const daysLeft = dayjs(latestContract.end_date).diff(dayjs(), 'day'); // Calculating days left
  
    if (isNaN(daysLeft) || daysLeft <= 0) {
      return { contractContent: 'Expired', contractColor: 'error.main' };
    }
  
    let contractColor = 'success.main';
    if (daysLeft <= 30) {
      contractColor = 'warning.main';
    }
  
    return {
      contractContent: `${daysLeft}d Left`,
      contractColor,
    };
  };
  
  const { contractContent, contractColor } = getContractDisplayInfo();
  const getBillingDisplayInfo = () => {
    if (!currentClient || !currentClient?.billings || currentClient?.billings.length === 0) {
      return { billingContent: 'NA', billingColor: 'error.main' };
    }
  
    // Find the latest billing by payment_date
    const latestBilling = currentClient?.billings.reduce((latest, billing) => {
      const billingDate = dayjs(billing.payment_date);
      if (!latest || billingDate.isAfter(dayjs(latest.payment_date))) {
        return billing;
      }
      return latest;
    }, null);
  
    if (!latestBilling) {
      return { billingContent: 'NA', billingColor: 'error.main' };
    }
  
    // Set the content and color based on the billing status
    let billingColor = 'success.main';
    let billingContent = latestBilling.status;
  
    switch (latestBilling.status.toLowerCase()) {
      case 'pending':
        billingColor = 'warning.main';
        break;
      case 'failed':
        billingColor = 'error.main';
        break;
      // Add more cases if there are other possible statuses
      default:
        billingColor = 'success.main';
        break;
    }
  
    return {
      billingContent: billingContent.charAt(0).toUpperCase() + billingContent.slice(1), // Capitalize first letter
      billingColor,
    };
  };
  
  const { billingContent, billingColor } = getBillingDisplayInfo();
  
  const  appointments  = currentClient ? currentClient?.appointments : [];
  const appointmentDates = appointments.map(a => dayjs(`${a.appointment_date} ${a.from_time}`)).sort((a, b) => b.diff(a));
  const latestDate = appointmentDates.length > 0 ? appointmentDates[0] : null;

  const now = dayjs();
  let connectContent = "TBD";
  let connectTitle = "Last Connect";
  if (latestDate) {
    const diffDays = latestDate.diff(now, 'day');
    const isSameDay = latestDate.isSame(now, 'day');

    if (latestDate.isAfter(now, 'minute')) {
      connectContent = isSameDay ? "Today" : diffDays <= 1 ? "Tomorrow" : latestDate.format('DD MMM YYYY');
      connectTitle = "Next Meet";
    } else {
      connectContent = isSameDay ? "Today" : diffDays >= -1 ? "Yesterday" : latestDate.format('DD MMM YYYY');
      connectTitle = "Last Connect";
    }
  }
  return (
    
    <Card elevation={3}>
    <CardContent sx={{ position: "relative" }}>
      {/* First Row */}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center" marginBottom={1}>
            <UserAvatar firstName={currentClient?.firstName || "A"} lastName={currentClient?.lastName || "A"} />
            <Box>
              <Typography variant="h5" display="inline">{currentClient?.firstName + ", " + currentClient?.lastName}
                <Chip label={currentClient?.status} size="small" display="inline"
                sx={{
                  color:"white",
                  ml:1, mb:0.5,
                  bgcolor: getColor(currentClient?.status)}}
                />
                </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <EmailRoundedIcon color="primary" sx={{ fontSize: 12, mr: 0.5 }} />
                    <Typography variant="body2" color="textSecondary">{currentClient?.email}</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <PhoneRoundedIcon color="primary"  sx={{ fontSize: 12, ml: 3, mr: 0.5 }} />
                    <Typography variant="body2" color="textSecondary">{currentClient?.contactNumber}</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <LocationOnRoundedIcon  color="primary" sx={{ fontSize: 12, ml: 3, marginRight: 0.5 }} />
                    <Typography variant="body2" color="textSecondary">{currentClient?.country || EmptyData}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* Divider */}
      <Divider sx={{ my: 1 }} />
      {/* Second Row */}
      <Grid container spacing={0.5} sx={{ display: 'flex', alignItems: 'stretch' }}>
        <Grid item xs={6} md={2}>
          <InfoCard title="ID" onClick={()=> setTabIndex(1)} content={"#" + currentClient?.id}/>
        </Grid>
        <Grid item xs={6} md={2}>
          <InfoCard title={connectTitle} onClick={()=> setTabIndex(2)}  content={connectContent} />
        </Grid>
        <Grid item xs={6} md={2}>
          <InfoCard title="Patients"  onClick={()=> setTabIndex(3)}  content={currentClient?.patients?.length } />
        </Grid>
        <Grid item xs={6} md={2}>
          <InfoCard title="Pat. Location"  onClick={()=> setTabIndex(3)} content={currentClient?.serviceLocation} /> 
        </Grid>
        <Grid item xs={6} md={2}>
        <InfoCard title="Contract"  onClick={()=> setTabIndex(4)} content={contractContent} contentColor={contractColor} />
        </Grid>
        <Grid item xs={6} md={2}>
          <InfoCard title="Billings" onClick={()=> setTabIndex(5)} content={billingContent} contentColor={billingColor} />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
  )
}

// ClientOverview Component
const ClientOverview = ({setTabIndex}) => {
  return (
    <Grid container spacing={0.5}>
      {/* First Column */}
      <Grid item lg={8}>
        <Grid container spacing={0.5}>
          <Grid item lg={12} >
            <AccountCard setTabIndex={setTabIndex}/>
          </Grid>
          <Grid item lg={12}>
            <RecentUpdate setTabIndex={setTabIndex}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4}>
        <ProfileInformation  setTabIndex={setTabIndex}/>
      </Grid>
    </Grid>
  );
};

export default ClientOverview;
