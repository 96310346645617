import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Grid, Typography, Radio, RadioGroup, FormControlLabel, IconButton, TextField, FormControl, Select, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';

const CaregiverLivingConditions = ({ patientProfile, newPatient = false, onChange }) => {
  const [isEditing, setIsEditing] = useState(newPatient);
  const [values, setValues] = useState({
    sleepingArrangement: '',
    toilet: '',
    foodProvided: '',
    accessToKitchen: '',
    internet: '',
    laundry: '',
    // downtime: '',
    // permissionToLeave: '',
    accessToAmenities: '',
    householdRules: ''
  });

  useEffect(() => {
    if (patientProfile && patientProfile.caregiver_livingconditions) {
      setValues({
        sleepingArrangement: patientProfile.caregiver_livingconditions.sleepingArrangement || '',
        toilet: patientProfile.caregiver_livingconditions.toilet || '',
        foodProvided: patientProfile.caregiver_livingconditions.foodProvided || '',
        accessToKitchen: patientProfile.caregiver_livingconditions.accessToKitchen || '',
        internet: patientProfile.caregiver_livingconditions.internet || '',
        laundry: patientProfile.caregiver_livingconditions.laundry || '',
        // downtime: patientProfile.caregiver_livingconditions.downtime || '',
        // permissionToLeave: patientProfile.caregiver_livingconditions.permissionToLeave || '',
        accessToAmenities: patientProfile.caregiver_livingconditions.accessToAmenities || '',
        householdRules: patientProfile.caregiver_livingconditions.householdRules || ''
      });
    }
  }, [patientProfile]);

  const handleToggleEdit = () => {
    setIsEditing(prevIsEditing => {
      const newIsEditing = !prevIsEditing;
      if (!newIsEditing) {
        onChange({"caregiver_livingconditions": values});
      }
      return newIsEditing;
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="Caregiver Living Conditions"
        action={
          <IconButton onClick={handleToggleEdit} aria-label="edit">
            {isEditing ? <DoneIcon color='primary'/> : <EditIcon color='primary'/>}
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {Object.keys(values).map((value) => (
            <Grid item xs={12} sm={4} key={value}>
              <Typography variant="subtitle2" color="orange" gutterBottom>
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </Typography>
              {isEditing ? (
                // value === 'foodProvided' || value === 'accessToKitchen' || value === 'internet' || value === 'permissionToLeave' ? (
                  value === 'foodProvided' || value === 'accessToKitchen' || value === 'internet'  ? (
                  <FormControl fullWidth margin="normal">
                    <Select
                      name={value}
                      value={values[value]}
                      onChange={handleChange}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    name={value}
                    label={value}
                    value={values[value]}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                )
              ) : (
                <Typography variant="body2">{values[value]}</Typography>
              )}
            </Grid>
          ))}
          {/* {isEditing && (
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2" color="orange" gutterBottom>
                Downtime
              </Typography>
              <RadioGroup row name="downtime" value={values.downtime} onChange={handleChange}>
                <FormControlLabel value="1 hour" control={<Radio />} label="1 hour" />
                <FormControlLabel value="2 hours" control={<Radio />} label="2 hours" />
                <FormControlLabel value="3 hours" control={<Radio />} label="3 hours" />
              </RadioGroup>
            </Grid>
          )} */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CaregiverLivingConditions;
