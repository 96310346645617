import React from 'react';
import { Card, styled, CardContent, Typography, CardMedia, Grid, Box } from '@mui/material';

// Import images
import smithaPhoto from 'assets/smitha.png';
import subaPhoto from 'assets/suba.png';

// Styled components
const TeamCard = styled(Card)`
  max-width: '35%';
  margin: 0 auto;
  font-family: Raleway;
`;

const TeamCardMedia = styled(CardMedia)`
  height: 320px;
`;

const TeamDescription = styled(Typography)`
  font-size: 14px;
  color: #212121;
  font-weight: 400;
`;

const TeamName = styled(Typography)`
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;  // Space between name and position
`;

const TeamPosition = styled(Typography)`
  color: #212121;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;  // Space between position and description
`;

const TeamHeader = styled(Box)`
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 12px;
  margin-top: 20px;
`;

const TeamHeaderLine = styled(Box)`
  height: 2px;
  width: 80px;
  background-color: #212121;
  margin: 0 auto;
`;

const teamMembers = [
  {
    name: 'Smitha Goyal',
    position: 'CEO',
    description: 'California BayArea resident with 15+ yrs of experience in Customer Service & Success',
    photo: smithaPhoto,
  },
  {
    name: 'Ms Subhashini',
    position: 'COO',
    description: 'Thought Leader in Healthcare, India',
    photo: subaPhoto,
  },
];

const MeetTheTeam = ({teamsRef}) => {
  return (
    <>
      <Box ref={teamsRef} sx={{zIndex: -1, bgcolor: "#fafafa", position: "absolute", height: "130%", width: "100%"}}></Box>
      <TeamHeader sx={{
        fontSize:{
          xl:40
        }
      }}>
        Meet the Team
      </TeamHeader>
      <TeamHeaderLine sx={{
        height:{
          xl:4
        }
      }}/>
      <Grid
        container
        sx={{ mt: 5 }}
        spacing={4}
        justifyContent="center"
      >
        {teamMembers.map((member, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <TeamCard sx={{m:2}}>
              <TeamCardMedia
              sx={{
                height:{
                  xs:450,
                  md:350,
                  xl:500
                }
              }}
                image={member.photo}
                title={member.name}
              />
              <CardContent
                sx={{
                  bgcolor: "#ffffff",
                  height: 200,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '16px'
                }}
              >
                <TeamName  sx={{
                  fontSize:{
                    xl:25
                  }
                }}
                variant="h6" component="div">
                  {member.name}
                </TeamName>
                <TeamPosition  sx={{
                  fontSize:{
                    xl:30
                  }
                }} variant="body2" component="div">
                  {member.position}
                </TeamPosition>
                <TeamDescription  sx={{
                  fontSize:{
                    xl:20
                  }
                }}
                  variant="body2">
                  {member.description}
                </TeamDescription>
              </CardContent>
            </TeamCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default MeetTheTeam;
