import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import UserAvatar from './UserAvatar';
import { formatOnlyDate, getColor } from 'common/utilities';

const ProfileCard = ({ profile, isEditing= false ,handleChange, statusOptions=[]}) => {
    const [status, setStatus] = useState({});
    const [selectedStatus, setSelectedStatus]= useState("");

    useEffect(() => {
        if (profile?.status && statusOptions) {
            // Find the status object based on the profile status name
            const statusObject = Object.values(statusOptions).find(
                status => status.name == profile.status
            );
            if (statusObject) {
                setStatus(statusObject);
                setSelectedStatus(statusObject.to[0])
            }
        }
    }, [profile?.status]);

    const handleStatusChange = (event) => {
        const selectedKey = event.target.value;
        const selectedStatus = statusOptions[selectedKey];
    
        if (selectedStatus && handleChange) {
            // Pass the name of the selected status
            handleChange("status", selectedStatus.name);
            setSelectedStatus(selectedKey);
        }
    };

    return (
        <Card elevation={3} sx={{ height: "100%", width: "100%", position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <CardContent sx={{ textAlign: 'center' }}>
                <UserAvatar sx={{ margin: '0 auto 10px' }} firstName={profile?.firstName} lastName={profile?.lastName} />
                <Typography variant="h6">
                    {profile?.firstName} {profile?.lastName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    ID: #{profile?.id}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Member since: {formatOnlyDate(profile?.created_at)}
                </Typography>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 16 }}>
                    {isEditing ? (
                        <Select
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            sx={{ minWidth: 120 }}
                        >
                            {status?.to?.map((statusKey) => (
                                <MenuItem key={statusKey} value={statusKey}>
                                    {statusKey}
                                </MenuItem>
                            ))}
                            {/* Add more status options as needed */}
                        </Select>
                    ) : (
                        <Chip
                            label={status?.name}
                            size="large"
                            sx={{ color: "white", bgcolor: getColor(status?.name) }}
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default ProfileCard;
