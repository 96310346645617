import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Autocomplete, TextField, Chip, Button, Popper, ClickAwayListener, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CustomPopper = ({ anchorEl, children, open, onClose }) => {
  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom-start" sx={{ zIndex: (theme) => theme.zIndex.modal }}>
      <ClickAwayListener onClickAway={onClose}>
        <Paper sx={{ backgroundColor: 'white', padding: 1, boxShadow: 3 }}>
          {children}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

const ServicePreferencesCard = ({ caregiverDetails, handleChange, isEditing }) => {
  const theme = useTheme();
  const [anchorElService, setAnchorElService] = useState(null);
  const [openService, setOpenService] = useState(false);
  const [anchorElConditions, setAnchorElConditions] = useState(null);
  const [openConditions, setOpenConditions] = useState(false);

  // Define the options for autocomplete
  const serviceOptions = [
    'Personal Care', 'Housekeeping', 'Companionship', 'Meal Preparation', 'Medication Assistance'
  ];
  const conditionsOptions = [
    'Alzheimer’s', 'Dementia', 'Parkinson’s', 'Cancer', 'Diabetes'
  ];

  const handleServiceChange = (event, value) => {
    handleChange('service_preferences', { ...caregiverDetails?.service_preferences, types_of_services: value });
  };

  const handleConditionChange = (event, value) => {
    handleChange('service_preferences', { ...caregiverDetails?.service_preferences, willingness_to_work: value });
  };

  const handleServiceOpen = (event) => {
    setAnchorElService(event.currentTarget);
    setOpenService(true);
  };

  const handleServiceClose = () => {
    setOpenService(false);
    setAnchorElService(null);
  };

  const handleConditionsOpen = (event) => {
    setAnchorElConditions(event.currentTarget);
    setOpenConditions(true);
  };

  const handleConditionsClose = () => {
    setOpenConditions(false);
    setAnchorElConditions(null);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">Service Preferences</Typography>
        <Grid container spacing={2}>
          {/* Types of Services */}
          <Grid item xs={12}>
            {isEditing ? (
              <Autocomplete
                multiple
                freeSolo
                disableCloseOnSelect
                open={openService}
                onOpen={handleServiceOpen}
                onClose={handleServiceClose}
                options={serviceOptions}
                value={caregiverDetails?.service_preferences?.types_of_services || []}
                onChange={handleServiceChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Types of Services Willing to Provide"
                    variant="outlined"
                    onClick={handleServiceOpen}
                  />
                )}
                PopperComponent={(props) => (
                  <CustomPopper {...props} open={openService} anchorEl={anchorElService} onClose={handleServiceClose}>
                    <div {...props} />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleServiceClose}
                      sx={{ marginTop: theme.spacing(1) }}
                    >
                      OK
                    </Button>
                  </CustomPopper>
                )}
              />
            ) : (
              <Typography>
                Types of Services: {caregiverDetails?.service_preferences?.types_of_services?.map((service, index) => (
                  <Chip key={index} label={service} sx={{ margin: theme.spacing(0.5) }} />
                ))}
              </Typography>
            )}
          </Grid>

          {/* Willingness to Work with Clients with Specific Conditions */}
          <Grid item xs={12}>
            {isEditing ? (
              <Autocomplete
                multiple
                freeSolo
                disableCloseOnSelect
                open={openConditions}
                onOpen={handleConditionsOpen}
                onClose={handleConditionsClose}
                options={conditionsOptions}
                value={caregiverDetails?.service_preferences?.willingness_to_work || []}
                onChange={handleConditionChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Willingness to Work with Clients with Specific Conditions"
                    variant="outlined"
                    onClick={handleConditionsOpen}
                  />
                )}
                PopperComponent={(props) => (
                  <CustomPopper {...props} open={openConditions} anchorEl={anchorElConditions} onClose={handleConditionsClose}>
                    <div {...props} />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleConditionsClose}
                      sx={{ marginTop: theme.spacing(1) }}
                    >
                      OK
                    </Button>
                  </CustomPopper>
                )}
              />
            ) : (
              <Typography>
                Willingness to Work with Clients with Specific Conditions: {caregiverDetails?.service_preferences?.willingness_to_work?.map((condition, index) => (
                  <Chip key={index} label={condition} sx={{ margin: theme.spacing(0.5) }} />
                ))}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ServicePreferencesCard;
