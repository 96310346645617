
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AccessibleRoundedIcon from '@mui/icons-material/AccessibleRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DescriptionIcon from '@mui/icons-material/Description';
// meetingTypes.js
export const meetingTypes = {
  initialClientMeet: {
    name: "Initial Client Meet",
    emailTemplate: {
      title: "Initial Client Meeting Scheduled",
      body: `Hi [RecipientName],

We are excited to inform you that your Initial Client Meeting has been scheduled. This meeting is an important step in understanding your requirements and ensuring we provide the best possible service.

**Meeting Details:**
- **Date:** [MeetingDate]
- **Time:** [MeetingTime]
- **Location:** Google Meet

Please use the following link to join the meeting: [MeetingLink]

We are eager to get started and look forward to meeting you.

Best regards,
[YourCompanyName]`
    }
  },
  initialCaregiverMeet: {
    name: "Initial Caregiver Meet",
    emailTemplate: {
      title: "Initial Caregiver Meeting Scheduled",
      body: `Hi [RecipientName],

We are pleased to schedule your Initial Caregiver Meeting. This session will help us understand your background and preferences as we work together to provide the best care.

**Meeting Details:**
- **Date:** [MeetingDate]
- **Time:** [MeetingTime]
- **Location:** Google Meet

Please use the following link to join the meeting: [MeetingLink]

Thank you for your time, and we look forward to speaking with you.

Best regards,
[YourCompanyName]`
    }
  },
  clientCaregiverInterview: {
    name: "Client-Caregiver Interview",
    emailTemplate: {
      title: "Client-Caregiver Interview Scheduled",
      body: `Hi [RecipientName],

We are pleased to inform you that a Client-Caregiver Interview has been scheduled. This meeting will allow both parties to discuss the caregiving needs and expectations.

**Meeting Details:**
- **Date:** [MeetingDate]
- **Time:** [MeetingTime]
- **Location:** Google Meet

Please use the following link to join the meeting: [MeetingLink]

We look forward to your participation.

Best regards,
[YourCompanyName]`
    }
  },
  otherAppointments: {
    name: "Other Appointments",
    emailTemplate: {
      title: "Appointment Scheduled",
      body: `Hi [RecipientName],

We are writing to confirm your appointment. Please see the details below:

**Meeting Details:**
- **Date:** [MeetingDate]
- **Time:** [MeetingTime]
- **Location:** Google Meet

Please use the following link to join the meeting: [MeetingLink]

We look forward to seeing you.

Best regards,
[YourCompanyName]`
    }
  }
};

export const menuitems = [
  {
    id: 1,
    name: 'Dashboard',
    icon: <DashboardRoundedIcon />,
    url: '/dashboard'
  },
  // {
  //   id: 2,
  //   name: 'Roadmap',
  //   icon: <AccountTreeRoundedIcon />,
  //   url: '/clients'
  // },
  {
    id: 3,
    name: 'Clients',
    icon: <GroupsRoundedIcon />,
    url: '/clients'
  },
  {
    id: 4,
    name: 'Patients',
    icon: <AccessibleRoundedIcon />,
    url: '/patients'
  },
  {
    id: 5,
    name: 'Caregivers',
    icon: <HailRoundedIcon />,
    url: '/caregivers'
  },
  {
    id: 6,
    name: 'Appointments',
    icon: <CalendarMonthRoundedIcon />,
    url: '/appointments'
  },
  {
    id: 7,
    name: 'Contracts',
    icon: <DescriptionIcon />,
    url: '/contracts'
  },
  {
    id: 8,
    name: 'Billings',
    icon: <AttachMoneyRoundedIcon />,
    url: '/billings'
  },
];

export const ClientStatuses = Object.freeze({
  INTERESTED: {
      name: "Interested",
      to: ["INTERESTED", "DISCUSSION"]
  },
  DISCUSSION: {
      name: "Discussion",
      to: ["DISCUSSION", "AWAITING_CG_INTERVIEW"]
  },
  AWAITING_CG_INTERVIEW: {
      name: "Awaiting_CG_Interview",
      to: ["AWAITING_CG_INTERVIEW", "CG_INTERVIEW"]
  },
  CG_INTERVIEW: {
      name: "CG Interview",
      to: ["CG_INTERVIEW", "CG_SELECTED"]
  },
  CG_SELECTED: {
      name: "CG Selected",
      to: ["CG_SELECTED", "ACTIVE", "CG_CHANGE_REQUEST"]
  },
  ACTIVE: {
      name: "Active",
      to: ["ACTIVE", "CG_CHANGE_REQUEST", "DISCONTINUED"] // Added CG Change Request and Discontinued transitions
  },
  CG_CHANGE_REQUEST: {
      name: "CG Change Request",
      to: ["CG_CHANGE_REQUEST", "ACTIVE", "DISCONTINUED"] // Can transition back to active or to discontinued
  },
  DISCONTINUED: {
      name: "Discontinued",
      to: ["DISCONTINUED"] // No further transitions, but still include itself for consistency
  }
});


  export const CaregiverStatuses = Object.freeze({
    INTERESTED: {
        name: "Interested",
        to: ["INTERESTED", "INTERNAL_INTERVIEW"]
    },
    INTERNAL_INTERVIEW: {
        name: "Internal Interview",
        to: ["INTERNAL_INTERVIEW", "SELECTED", "REJECTED", "ONHOLD"]
    },
    SELECTED: {
        name: "Selected",
        to: ["SELECTED", "AVAILABLE", "ONHOLD", "REJECTED"]
    },
    REJECTED: {
        name: "Rejected",
        to: ["REJECTED"] // No further transitions, but still include itself for consistency
    },
    ONHOLD: {
        name: "On Hold",
        to: ["ONHOLD", "AVAILABLE", "REJECTED"]
    },
    AVAILABLE: {
        name: "Available",
        to: ["AVAILABLE", "CLIENT_INTERVIEW", "TERMINATED", "RESIGNED"]
    },
    CLIENT_INTERVIEW: {
        name: "Client Interview",
        to: ["CLIENT_INTERVIEW", "ASSIGNED", "AVAILABLE"]
    },
    ASSIGNED: {
        name: "Assigned",
        to: ["ASSIGNED", "IN_SERVICE"]
    },
    IN_SERVICE: {
        name: "InService",
        to: ["IN_SERVICE", "TERMINATED", "RESIGNED"]
    },
    TERMINATED: {
        name: "Terminated",
        to: ["TERMINATED"] // No further transitions, but still include itself for consistency
    },
    RESIGNED: {
        name: "Resigned",
        to: ["RESIGNED"] // No further transitions, but still include itself for consistency
    }
});


export const PatientStatuses = Object.freeze({
  CREATED: {
    name: "Created",
    to: ["CREATED", "CG_INTERVIEW"]
  },
  CG_INTERVIEW: {
    name: "CG Interview",
    to: ["CG_INTERVIEW", "CG_ASSIGNED"]
  },
  CG_ASSIGNED: {
    name: "CG Assigned",
    to: ["CG_ASSIGNED", "ACTIVE"]
  },
  ACTIVE: {
    name: "Active",
    to: ["ACTIVE", "CG_CHANGE_REQUEST","REMOVED"]
  },
  CG_CHANGE_REQUEST: {
    name: "CG Change Request",
    to: ["CG_CHANGE_REQUEST", "ACTIVE", "REMOVED"]
  },
  REMOVED: {
    name: "Removed",
    to: ["REMOVED"] // No further transitions, only includes itself
  }
});
