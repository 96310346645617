import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Tab,
  Tabs,
  Button,
  Tooltip,
  IconButton,
  useTheme,
  darken,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ClientOverview from "../../components/ClientOverview";
import ClientProfileView from "../../components/ClientProfileView";
import Table from "../../components/Table";
import NotesHistory from "../../components/NotesHistory";
import {
  patientsColumns,
  patientsColumnVisibility,
  patientsData,
  patientsSorting,
} from "CommonData/PatientsColumns";
import { useGetClientByIdQuery, useGetClientsQuery } from "services/clientApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "../../components/SnackbarProvider";
import { useLoading } from "../../components/LoadingProvider";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentClient } from "slices/clientslice";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import {
  setclientPatients,
  setCurrentPatient,
  setCurrentPatients,
} from "slices/patientslice";
import {
  contractsColumns,
  contractSorting,
  contractColumnVisibility,
  contractsSorting,
  contractsColumnVisibility,
} from "CommonData/contractsColumns";
import {
  appointmentColumns,
  appointmentsColumns,
  appointmentsColumnVisibility,
  appointmentsSorting,
} from "CommonData/AppointmentsColumns";
import { setSelectedMenuItem } from "slices/globaslice";
import {
  billingsColumns,
  billingsColumnVisibility,
  billingsSorting,
} from "CommonData/billingsColumns";
import { ClientStatuses } from "CommonData/Common";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 1, // Optional: Adds rounded corners
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

export default function ClientDetails() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const { clientId } = useParams();
  const {
    data: client,
    isLoading,
    error,
    isFetching,
    refetch,
  } = useGetClientByIdQuery(clientId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const darkerPrimary = darken(primaryColor, 0.5);

  useEffect(() => {
    // Trigger refetch on component mount
    if (client){
    refetch();
    }
  }, []);

  useEffect(() => {
    if (client) {
      dispatch(setCurrentClient(client));
      dispatch(setclientPatients(client?.patients));
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setSelectedMenuItem(3));
  }, []);

  useEffect(() => {
    if (isLoading || isFetching) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading || isFetching]);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const path = location.pathname;

    // Determine the tabIndex based on the ending part of the pathname
    if (path.endsWith("appointments")) {
      setTabIndex(2); // Set the tabIndex for 'details'
    } else if (path.endsWith("patients")) {
      setTabIndex(3); // Set the tabIndex for 'appointments'
    } else if (path.endsWith("contracts")) {
      setTabIndex(4); // Set the tabIndex for 'notes'
    } else if (path.endsWith("billings")) {
      setTabIndex(5); // Set the tabIndex for 'notes'
    } else if (path.endsWith("notes")) {
      setTabIndex(6); // Set the tabIndex for 'notes'
    } else {
      setTabIndex(0);
    }
  }, [location.pathname]);
  const onNotesAddClick = () => {
    setTabIndex(6);
  };

  const onPatientAddClick = () => {
    const previousURL = encodeURIComponent(location.pathname);
    navigate(
      `/clients/${client?.id}/patients/new?redirect=${previousURL}&client_id=${client.id}`
    );
  };
  const onContractAddClick = () => {
    const previousURL = encodeURIComponent(location.pathname);
    navigate(
      `/clients/${client?.id}/contracts/new?redirect=${previousURL}&client_id=${client.id}`
    );
  };
  const onBillingsAddClick = () => {
    const previousURL = encodeURIComponent(location.pathname);
    navigate(
      `/clients/${client?.id}/billings/new?redirect=${previousURL}&client_id=${client.id}`
    );
  };
  const schedule = (client) => {
    const previousURL = encodeURIComponent(location.pathname);
    navigate(
      `/clients/${client?.id}/appointments/new?redirect=${previousURL}`,
      {
        state: { client },
      }
    );
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;
  return (
    <>
      {!isLoading && !error && (
        <Grid
          container
          spacing={0.5}
          sx={{ px: 1, height: "100%", flexGrow: 1 }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "10%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 2, // Adds space between buttons
            }}
          >
            <Button
              variant="contained"
              disabled={!client}
              startIcon={<CalendarTodayIcon />}
              onClick={() => schedule(client)}
              sx={{ bgcolor: darkerPrimary }}
            >
              Schedule Appointment
            </Button>
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              disabled={client.status == ClientStatuses.INTERESTED.name}
              sx={{ bgcolor: darkerPrimary }}
              onClick={onPatientAddClick} // Replace with your function to add a patient
            >
              Add Patient
            </Button>
            <Button
              variant="contained"
              sx={{ bgcolor: darkerPrimary }}
              startIcon={<AssignmentIcon />}
              disabled={
                client.status != ClientStatuses.CG_SELECTED.name
                && client.status != ClientStatuses.ACTIVE.name
              }
              onClick={onContractAddClick} // Replace with your function to add a contract
            >
              Add Contract
            </Button>
            <Button
              disabled={
                client.status != ClientStatuses.ACTIVE.name
              }
              variant="contained"
              sx={{ bgcolor: darkerPrimary }}
              startIcon={<MonetizationOnIcon />}
              onClick={onBillingsAddClick} // Replace with your function to add a contract
            >
              Add Billing
            </Button>
            <Button
              variant="contained"
              startIcon={<NoteAddIcon />}
              sx={{ bgcolor: darkerPrimary }}
              onClick={onNotesAddClick} // Replace with your function to add notes
            >
              Add Notes
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Elevation effect
              borderRadius: "8px", // Optional: Adds rounded corners
              bgcolor: "#FAFAFA",
              mt: 1,
              height: "auto",
              flex: "1 1 auto",
            }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Overview" />
              <Tab label="Profile" />
              <Tab label="Appointments" />
              <Tab label="Patients" />
              <Tab label="Contracts" />
              <Tab label="Billings" />
              <Tab label="Notes" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <ClientOverview setTabIndex={setTabIndex} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <ClientProfileView />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <Table
                title="Appointments"
                data={client.appointments}
                columns={appointmentsColumns}
                onAddClick={() => schedule(client)}
                rowClickUrl={`/clients/${client?.id}/appointments/`}
                chipField="status"
                sorting={appointmentsSorting}
                columnVisibility={appointmentsColumnVisibility}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Table
                    data={client.patients}
                    title="Patients"
                    onAddClick={onPatientAddClick}
                    columns={patientsColumns}
                    rowClickUrl={`/clients/${client?.id}/patients/`}
                    sorting={patientsSorting}
                    columnVisibility={patientsColumnVisibility}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Table
                    data={client.contracts}
                    title="Contracts"
                    onAddClick={
                    client.status == ClientStatuses.CG_SELECTED.name
                    || client.status == ClientStatuses.ACTIVE.name
                    ?  onContractAddClick : null}
                    columns={contractsColumns}
                    rowClickUrl={`/clients/${client?.id}/contracts/`}
                    chipField="status"
                    sorting={contractsSorting}
                    columnVisibility={contractsColumnVisibility}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Table
                    data={client.billings}
                    title="Billings"
                    
                    onAddClick={
                      client.status == ClientStatuses.ACTIVE.name
                      ?  onBillingsAddClick : null}
                    columns={billingsColumns}
                    rowClickUrl={`/clients/${client?.id}/billings/`}
                    chipField="status"
                    sorting={billingsSorting}
                    columnVisibility={billingsColumnVisibility}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={6}>
              <NotesHistory clientid={clientId} />
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </>
  );
}
