import React from 'react';
import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Content = styled('div')({
  zIndex: 1,
  textAlign: 'center',
  color: 'white',
});

const ButtonStyled = styled(Button)({
  marginTop: '16px',
});

const LandingPage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/clients/new');
  };

  return (
    <>      
      <Content>
        <Typography style={{fontFamily:"Roboto Slab, sans-serif", paddingBottom:20}} variant="h2">Every wrinkle tells a story</Typography>
        <Typography variant="h5" style={{fontFamily:"Roboto Slab, sans-serif"}}>In the quest of caring your beloved? Your search ends here...</Typography>
        <ButtonStyled variant="contained" color="primary" onClick={handleClick}>
          Book an Appointment
        </ButtonStyled>
      </Content>
     </>
  );
};

export default LandingPage;
