import { Box, Chip } from "@mui/material";
import { getColor } from "common/utilities";

const contractsColumns = [
  {
    accessorKey: 'id',
    header: 'Contract Id',
    size: 20,
  },
  {
    accessorKey: 'client',
    header: 'Client',
    size: 150,
    Cell: ({ cell }) => {
      const client = cell.getValue(); // Assuming this returns the client object
      return (
        <Chip
          label={client ? `${client.lastName}, ${client.firstName}` : 'No Client'}
          component="a"
          href={client ? `/clients/${client.id}` : '#'}
          color="info"
          onClick={(event) => event.stopPropagation()}
          clickable
        />
      );
    },
  },
  {
    accessorKey: 'caregiver',
    header: 'Caregiver',
    size: 150,
    Cell: ({ cell }) => {
      const caregiver = cell.getValue(); // Assuming this returns the caregiver object
      return (
        caregiver ? (
          <Chip
            label={`${caregiver.lastName}, ${caregiver.firstName}`}
            component="a"
            href={`/caregivers/${caregiver.id}`} // Fixed spelling from 'caregviers' to 'caregivers'
            color="success"
            onClick={(event) => event.stopPropagation()}
            clickable
          />
        ) : (
          <Chip
            label="Not Assigned"
            color="success"
            onClick={(event) => event.stopPropagation()}
            clickable
            disabled
          />
        )
      );
    },
  },
  {
    accessorKey: 'patient',
    header: 'Patient',
    size: 150,
    Cell: ({ cell }) => {
      const patient = cell.getValue(); // Assuming this returns the patient object
      return (
        patient ? (
          <Chip
            label={`${patient.lastName}, ${patient.firstName}`}
            component="a"
            href={`/patients/${patient.id}`}
            color="warning"
            onClick={(event) => event.stopPropagation()}
            clickable
          />
        ) : (
          <Chip
            label="No Patient"
            color="warning"
            onClick={(event) => event.stopPropagation()}
            clickable
            disabled
          />
        )
      );
    },
  },
  {
    accessorKey: 'start_date',
    header: 'Start Date',
    size: 150,
  },
  {
    accessorKey: 'end_date',
    header: 'End Date',
    size: 150,
  },
  {
    accessorKey: 'payment_terms',
    header: 'Payment Terms',
    size: 250,
  },
  {
    accessorKey: 'total_amount',
    header: 'Total Amount',
    size: 150,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 150,
    Cell: ({ cell }) => (
      <Box
        component="span"
        sx={(theme) => ({
          fontSize: 12,
          backgroundColor: getColor(cell.getValue()),
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {cell.getValue()}
      </Box>
    ),
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    size: 150,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    size: 150,
  },
];

const contractsColumnVisibility = {
  payment_terms: false,
  created_at: false,
  updated_at: false,
};

const contractsSorting = [{ id: 'created_at', desc: true }];

export { contractsColumns, contractsColumnVisibility, contractsSorting };
