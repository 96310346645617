import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clients: [], // Add clients array to the state
  currentClient: null,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClients(state, action) {
      state.clients = action.payload;
    },
    setCurrentClient(state, action) {
      state.currentClient = action.payload;
    },
  },
});

export const { setClients, setCurrentClient } = clientSlice.actions;

export default clientSlice.reducer;
