import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Switch, FormControlLabel, Box } from '@mui/material';

const HealthSafetyCard = ({ caregiverDetails, handleChange, isEditing }) => {
  const immunizationFields = [
    { label: 'Hepatitis B Immunization', name: 'hepatitis_b' },
    { label: 'Measles Immunization', name: 'measles' },
    { label: 'Mumps Immunization', name: 'mumps' },
    { label: 'Rubella Immunization', name: 'rubella' },
    { label: 'Tetanus Immunization', name: 'tetanus' },
    { label: 'COVID-19 Vaccination Status', name: 'covid_19_vaccination_status' },
  ];

  const [healthSafetyDetails, setHealthSafetyDetails] = useState({
    hepatitis_b: false,
    measles: false,
    mumps: false,
    rubella: false,
    tetanus: false,
    covid_19_vaccination_status: false,
  });

  useEffect(() => {
    if (caregiverDetails?.health_safety_details) {
      setHealthSafetyDetails({
        ...healthSafetyDetails,
        ...caregiverDetails?.health_safety_details,
      });
    }
  }, [caregiverDetails]);

  const handleSwitchChange = (name) => {
    const updatedDetails = { ...healthSafetyDetails, [name]: !healthSafetyDetails?.[name] };
    setHealthSafetyDetails(updatedDetails);
    handleChange("health_safety_details", updatedDetails);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">Health and Safety</Typography>
        <Grid container spacing={2}>
          {immunizationFields.map(field => (
            <Grid item xs={12} sm={6} key={field.name}>
              {isEditing ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={healthSafetyDetails?.[field.name]}
                      onChange={() => handleSwitchChange(field.name)}
                      name={field.name}
                      color="primary"
                    />
                  }
                  label={field.label}
                />
              ) : (
                <Box>
                  <Typography variant="subtitle1">{field.label}</Typography>
                  <Typography variant="body1">
                    {healthSafetyDetails?.[field.name] ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HealthSafetyCard;
