// src/services/userApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueryWithReauth";
import { tagTypes } from "./tags";

const userApi = createApi({
  reducerPath: "usersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "users/",
      providesTags: ["User"],
    }),
  }),
});

export const {
  useGetUsersQuery,
} = userApi;

export default userApi;
