import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';

const AvailabilityCard = ({ caregiverDetails, handleChange, isEditing }) => {
  const availabilityOptions = [
    'Morning',
    'Night',
    'Live in',
    'Both'
  ];

  const [availabilityDetails, setAvailabilityDetails] = useState({
    availability: '',
    planned_personal_time_off: '',
  });

  useEffect(() => {
    if (caregiverDetails?.availability_details) {
      setAvailabilityDetails({
        ...availabilityDetails,
        ...caregiverDetails?.availability_details,
      });
    }
  }, [caregiverDetails]);

  const handleFieldChange = (field, value) => {
    const updatedDetails = { ...availabilityDetails, [field]: value };
    setAvailabilityDetails(updatedDetails);
    handleChange('availability_details', updatedDetails);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">Availability</Typography>
        <Grid container spacing={2}>
          {/* Availability Field */}
          <Grid item xs={12}>
            {isEditing ? (
              <FormControl fullWidth>
                <InputLabel id="availability-select-label">Availability</InputLabel>
                <Select
                  labelId="availability-select-label"
                  value={availabilityDetails.availability}
                  onChange={(e) => handleFieldChange('availability', e.target.value)}
                  label="Availability"
                >
                  {availabilityOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box>
                <Typography variant="subtitle1">Availability</Typography>
                <Typography variant="body1">
                  {availabilityDetails.availability || 'Not specified'}
                </Typography>
              </Box>
            )}
          </Grid>

          {/* Planned Personal Time Off */}
          <Grid item xs={12}>
            {isEditing ? (
              <TextField
                fullWidth
                label="Planned Personal Time Off"
                value={availabilityDetails.planned_personal_time_off}
                onChange={(e) => handleFieldChange('planned_personal_time_off', e.target.value)}
              />
            ) : (
              <Box>
                <Typography variant="subtitle1">Planned Personal Time Off</Typography>
                <Typography variant="body1">
                  {availabilityDetails.planned_personal_time_off || 'Not specified'}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AvailabilityCard;
