// src/services/clientApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueryWithReauth";
import { tagTypes } from "./tags";

const clientApi = createApi({
  reducerPath: "clientsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes,
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => "clients/",
      providesTags: ["Client"],
      keepUnusedDataFor: 300,
    }),
    getClientById: builder.query({
      query: (id) => `clients/${id}/`,
      providesTags: (result, error, id) => [{ type: "Client", id}],
    }),
    createInterestedClient: builder.mutation({
      query: (newClient) => ({
        url: "public/clients/interested-client/",
        method: "POST",
        body: newClient,
      }),
      invalidatesTags: ["Client"],
    }),
    addClient: builder.mutation({
      query: (newClient) => ({
        url: "clients",
        method: "POST",
        body: newClient,
      }),
      invalidatesTags: ["Client"],
    }),
    updateClient: builder.mutation({
      query: ({ id, ...updatedClient }) => ({
        url: `clients/${id}/`,
        method: "PATCH",
        body: updatedClient,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Client", id:id },
        "Client",
      ],
    }),
    deleteClient: builder.mutation({
      query: (id) => ({
        url: `clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Client", id }],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientByIdQuery,
  useCreateInterestedClientMutation,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientApi;

export default clientApi;
