// api/authApi.js

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueryWithReauth';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (user) => ({
        url: '/token/',
        method: 'POST',
        body: user,
      }),
    }),
    logout: builder.mutation({
      query: (data) => ({
        url: '/logout/', // Adjust logout endpoint as per your API
        method: 'POST',
        body: data
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
export default authApi;
