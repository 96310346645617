import {
  appointmentColumns,
  appointmentColumnVisibility,
  appointmentsColumns,
  appointmentsColumnVisibility,
  appointmentSorting,
  appointmentsSorting,
} from "CommonData/AppointmentsColumns";
import { useLoading } from "components/LoadingProvider";
import { useSnackbar } from "components/SnackbarProvider";
import Table from "components/Table";
import HomeLayout from "layout/HomeLayout";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAppointmentsQuery } from "services/appointmentApi";

function AppointmentsListPage() {
  const {
    data: appointmentsData,
    error,
    isLoading, refetch
  } = useGetAppointmentsQuery();
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);

  const onAddClick = () => {
    navigate("/appointments/new");
  };
  return (
    <>
        <Table
          title="On Deck"
          data={appointmentsData || []}
          columns={appointmentsColumns}
          rowClickUrl="/appointments/"
          onAddClick={onAddClick}
          chipField="status"
          sorting={appointmentsSorting}
          columnVisibility={appointmentsColumnVisibility}
          refetch={refetch}
        />
      
    </>
  );
}

export default AppointmentsListPage;
