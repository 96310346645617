import { createTheme, darken, lighten } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2BDCD1', // Main primary color (teal)
      light: lighten('#2BDCD1',0.5 ), // Lighter variant of the primary color
      dark: darken('#2BDCD1',0.5 ), // Darker variant of the primary color
      contrastText: '#ffffff', // Text color for primary elements
    },
    secondary: {
      main: '#003366', // Dark navy as the secondary color
      light: '#335f8f', // Lighter variant of the secondary color
      dark: '#001933', // Darker variant of the secondary color
      contrastText: '#ffffff', // Text color for secondary elements
    }
  },
  typography: {
    fontFamily: 'Public Sans, sans-serif', // Custom font
  },
  // Add more customizations as needed
});

export default theme;
