import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetClientByIdQuery, useUpdateClientMutation } from 'services/clientApi';
import { useSnackbar } from './SnackbarProvider';
import { useLoading } from './LoadingProvider';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

const PersonalInfo = ({isEditing,clientProfile, handleChange, handleDateChange}) => {
  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card elevation={3} sx={{ height: "100%" }}>
          <CardHeader
            title="Personal Information"
            // action={
            //   <IconButton
            //     color="primary"
            //     aria-label={isEditing ? "save" : "edit"}
            //     onClick={isEditing ? handleSaveClick : handleEditClick}
            //   >
            //     {isEditing ? <CheckIcon /> : <EditRoundedIcon />}
            //   </IconButton>
            // }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">First Name</Typography>
                {isEditing ? (
                  <TextField
                    name="firstName"
                    value={clientProfile?.firstName}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                ) : (
                  <Typography variant="body2">
                    {clientProfile?.firstName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">Last Name</Typography>
                {isEditing ? (
                  <TextField
                    name="lastName"
                    value={clientProfile?.lastName}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                ) : (
                  <Typography variant="body2">
                    {clientProfile?.lastName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">Contact Number</Typography>
                {isEditing ? (
                  <TextField
                    name="contactNumber"
                    value={clientProfile?.contactNumber}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                ) : (
                  <Typography variant="body2">
                    {clientProfile?.contactNumber}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">Email</Typography>
                {isEditing ? (
                  <TextField
                    name="email"
                    value={clientProfile?.email}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                ) : (
                  <Typography variant="body2">{clientProfile?.email}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">Address Line 1</Typography>
                {isEditing ? (
                  <TextField
                    name="address_line1"
                    label="Line 1"
                    value={clientProfile?.address_line1 || ""}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    sx={{ marginBottom: 1 }}
                  />
                ) : (
                  <Typography variant="body2">
                    {clientProfile?.address_line1 || "Yet to add"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">Address Line 2</Typography>
                {isEditing ? (
                  <TextField
                    name="address_line2"
                    label="Line 2"
                    value={clientProfile?.address_line2 || ""}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                ) : (
                  <Typography variant="body2">
                    {clientProfile?.address_line2 || "Yet to add"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">State</Typography>
                {isEditing ? (
                  <TextField
                    name="state"
                    value={clientProfile?.state || ""}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                ) : (
                  <Typography variant="body2">{clientProfile?.state || "Yet to add"}</Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">Country</Typography>
                {isEditing ? (
                  <TextField
                    name="country"
                    value={clientProfile?.country || ""}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                ) : (
                  <Typography variant="body2">
                    {clientProfile?.country || "Yet to add"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">Availability</Typography>
                {isEditing ? (
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        name="availabilityStartTime"
                        value={clientProfile?.availabilityStartTime}
                        onChange={handleChange}
                        label="From"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="availabilityEndTime"
                        value={clientProfile?.availabilityEndTime}
                        onChange={handleChange}
                        label="To"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant="body2">
                    {clientProfile?.availabilityStartTime} to{" "}
                    {clientProfile?.availabilityEndTime}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">
                  Expected Service Start
                </Typography>
                {isEditing ? (
                  <DatePicker
                    name="expectedServiceStart"
                    value={
                      clientProfile?.expectedServiceStart
                        ? dayjs(clientProfile?.expectedServiceStart, "YYYY-MM-DD")
                        : null
                    }
                    slotProps={{ textField: { fullWidth: true } }}
                    onChange={(date) =>
                      handleDateChange(date, "expectedServiceStart")
                    }
                  />
                ) : (
                  <Typography variant="body2">
                    {clientProfile?.expectedServiceStart
                      ? dayjs(clientProfile?.expectedServiceStart).format('DD MMM YYYY')
                      : "TBD"}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </LocalizationProvider>
    );
};

export default PersonalInfo;
