import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import ReactMaterialTable from './ReactMaterialTable';

const TrainingsCard = ({ caregiverDetails, handleChange, isEditing }) => {
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    if (caregiverDetails && caregiverDetails.trainings_completed) {
      setTrainings(caregiverDetails.trainings_completed);
    }
  }, [caregiverDetails]);

  const columns = [
    { field: 'training_name', headerName: 'Training Name' },
    { field: 'training_date', headerName: 'Training Date', type: 'date' },
  ];

  const handleTableChange = (updatedRows) => {
    if (handleChange) {
      handleChange({ ...caregiverDetails, trainings_completed: updatedRows });
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">Trainings Completed</Typography>
        <ReactMaterialTable
          columns={columns}
          data={trainings}
          handleChange={handleTableChange}
          isEditing={isEditing}
        />
      </CardContent>
    </Card>
  );
};

export default TrainingsCard;
