import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, Avatar, Box } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import UserAvatar from './UserAvatar';
import { useSelector } from 'react-redux';

const UserAccountMenu = ({logout}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const userInfo = useSelector((state) => state.global.userInfo);
  // const [userInfo, setUserInfo] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleAdmin = () => {
    // Redirect to admin page
    // navigate('/admin')
    window.open(process.env.REACT_APP_ADMIN_URL, '_blank');
    handleClose();
  };

  return (
    <Box >
      <IconButton onClick={handleClick}>
        <UserAvatar firstName={userInfo?.firstName} lastName={ userInfo?.lastName} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <Box display="flex" justifyContent="flex-end" p={1}>
          <IconButton sx= {{position:"absolute", top:1, right: 1}}onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box> */}
        <MenuItem sx={{mt:1}} onClick={handleAdmin}>
          <AdminPanelSettingsIcon style={{ marginRight: '8px' }} />
          Admin
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon style={{ marginRight: '8px' }} />
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserAccountMenu;
