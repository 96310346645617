import React from 'react';
import { Box, Typography } from '@mui/material';

// Common styles
const boxStyle = {
  margin: '0.5rem',
  width: '80%',
};

const titleStyle = {
  color: '#212121',
  fontWeight: 700,
  fontSize: {
    xs:14,
    xl:25
  },
  marginBottom: '0.5rem',
};

const bodyStyle = {
  color: '#212121',
  fontWeight: 400,
  fontSize: {
    xs:14,
    xl:20
  },
};

const SectionThree = ({storyRef}) => {
  const boxContents = [
    { title: 'Our Story', content: 'I found this company based on my own journey of caring for my grandmother and mother-in-law from thousands of miles away. The complexities of finding the right care and the constant worry about their well-being were overwhelming. Through these personal trials, I realized that there had to be a better way—one that provided peace of mind to families and ensured the comfort of our loved ones.' },
    { title: 'Our Philosophy', content: 'I believe that elderly care should not mean uprooting our loved ones from the comfort of their home. While care homes can sometimes be a solution, we need to understand that it’s often difficult for the elderly to adjust to new environments. At AtHomeGeriCare, we know that the heart heals best at home. That\'s why we focus on providing in-home care, allowing your loved ones to age gracefully in the place they know and love.' },
    { title: 'Our Expertise', content: 'AtHomeGeriCare is built on a foundation of trust, compassion, and professional excellence. Through my journey I met with Subha, a leader in healthcare services with 30 years of experience in hospital management. Together, we have combined our personal and professional experiences to create a service that is more than just care—it’s a lifeline for families.' },
    { title: 'Our Caregivers', content: 'We are committed to providing only the highest quality care. Our caregivers are thoroughly vetted, with 100% background checks and validated certifications, ensuring that your loved ones are in safe, skilled hands. Each caregiver is carefully matched to meet the specific needs of your family, creating a bond that goes beyond mere assistance.' },
    { title: 'Our Commitment', content: 'At AtHomeGeriCare, we are dedicated to walking this journey with you. We understand the unique challenges faced by NRIs and have crafted our services to offer not just solutions but also peace of mind. With us by your side, you can be confident that your loved ones are receiving the care they deserve—compassionate, personalized, and right at home. Let’s navigate this path together, ensuring that your loved ones live with dignity and comfort. We\'re here for you, every step of the way.' },
  ];

  return (
    <Box
      ref={storyRef}
      sx={{
        fontFamily: 'Raleway, sans-serif',
        padding: 2,
        backgroundColor: '#E8F5E9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {boxContents.map((item, index) => (
        <Box key={index} sx={boxStyle}>
          <Typography variant="h6" component="h2" sx={titleStyle}>
            {item.title}
          </Typography>
          <Typography variant="body1" sx={bodyStyle}>
            {item.content}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SectionThree;
