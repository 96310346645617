import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useLoading } from 'components/LoadingProvider';

function AdminPage() {
  const [loading, setLoading] = useState(true);
  const handleIframeLoad = () => {
    setLoading(false);  // Hide the loader once the iframe has loaded
  };

  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    if (loading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [loading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',     // Ensure container takes full width
        height: '100vh',   // Adjust to fit full height of the viewport or parent container
        // overflow: 'hidden', // Prevent scrollbars from showing
      }}
    >
      {/* Other layout elements like header, sidebar, etc. can go here */}

      {/* Box for iframe, flexible to take the rest of the space */}
      <Box
        sx={{
          flexGrow: 1,          // Make the iframe container grow to fill available space
          display: 'flex',
        }}
      >
        <iframe
          src={process.env.REACT_APP_ADMIN_URL}  // Replace with your desired URL
          width="100%"
          height="100%"
          onLoad={handleIframeLoad}
          title="Admin Page"
          style={{
            flexGrow: 1,        // Make iframe fill the parent Box
            borderRadius: '8px', // Optional styling like border radius
          }}
        />
      </Box>
    </Box>
  );
}

export default AdminPage;
