import { useLoading } from "components/LoadingProvider";
import { useSnackbar } from "components/SnackbarProvider";
import Table from "components/Table";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { contractsColumns, contractSorting, contractColumnVisibility, contractsColumnVisibility, contractsSorting } from "CommonData/contractsColumns";
import { useGetContractsQuery } from "services/contractApi";
import { setSelectedMenuItem } from "slices/globaslice";

function ContractsListPage() {
  const { data: contracts, error, isLoading, refetch } = useGetContractsQuery();
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setSelectedMenuItem(7));
  }, []);

  useEffect(() => {
    if (error) {
      showSnackbar(error?.status, "error");
    }
  }, [error]);

  const onContractAddClick = () => {
    const previousURL = encodeURIComponent(location.pathname);
    navigate(`/contracts/new?redirect=${previousURL}`);
  };
  return (
    <Table
      data={contracts || []}
      title="Contracts"
      onAddClick={onContractAddClick}
      columns={contractsColumns}
      rowClickUrl="/contracts/"
      chipField="status"
      sorting={contractsSorting}      
      columnVisibility={contractsColumnVisibility}
      refetch={refetch}
    />
  );
}

export default ContractsListPage;
