import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  List,
  Typography,
  Paper,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useDispatch, useSelector } from 'react-redux';
import { usePostNoteMutation } from 'services/noteApi';
import { useSnackbar } from './SnackbarProvider';
import { useLoading } from './LoadingProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetClientByIdQuery } from 'services/clientApi';
import { setCurrentClient } from 'slices/clientslice';

function Note({ text, date }) {
  const formatDate = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(date).toLocaleString(undefined, options);
  };

  return (
    <Paper sx={{ padding: 2, marginBottom: 2, backgroundColor: '#f5f5f5' }}>
      <Typography variant="body1" component="span">
        {text}
      </Typography>
      <Typography
        variant="body2"
        component="span"
        sx={{ display: 'block', marginTop: 0.5, color: 'gray' }}
      >
        {formatDate(date)}
      </Typography>
    </Paper>
  );
}

function NotesHistory({clientid}) {
  const { clientId } = useParams();
  const { data: currentClient } = useGetClientByIdQuery(clientId);
  const dispatch = useDispatch();
  
  const { data: ctClient, isLoading: isLoadingClient, error: clientError, refetch,isFetching } = useGetClientByIdQuery(clientid);
  const [notes, setNotes] = useState(() => {
    return currentClient?.notes ? [...currentClient.notes].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) : [];
  });
 const location = useLocation();
 const navigate = useNavigate();
  const [newNote, setNewNote] = useState('');
  const [isAdding, setIsAdding] = useState(true);

  useEffect(()=>{
    if (ctClient){
      dispatch(setCurrentClient(ctClient));
      setNotes(ctClient?.notes ? [...ctClient.notes].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) : [])
    }
  },[ctClient])
  const [postNote,
    { isLoading, isSuccess, isError, error},
  ] = usePostNoteMutation();

  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();
  const textFieldRef = useRef(null);

  useEffect(() => {
    // Focus the TextField when the component mounts
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, []); 
  useEffect(() => {
    if (isLoading || isLoadingClient ||isFetching ) {
      startLoading();
    } else { 
      stopLoading();
    }
  }, [isLoading , isLoadingClient, isFetching]);

  useEffect(() => {
    if (error ||clientError ) {
      showSnackbar(error?.data?.message  || clientError?.status, "error");
    }
  }, [error,clientError]);

  const handleButtonClick = async () => {
    if (newNote.trim() === '') return;

    try {
      startLoading();
      const postnote = {
        client: currentClient.id, 
        note: newNote
      }
      const result = await postNote(postnote);
      if (result.error) {
        throw result.error;
      }      
      await refetch();
      showSnackbar("Note added successfully", "success");
    } catch (err) {
      showSnackbar("Failed to add note", "error");
    } finally {
      stopLoading();
      setNewNote('')
    }
  };

  return (
    <Card elevation={3} sx={{ margin: 2 }}>
      <CardHeader title="Notes History" />
      <CardContent>
        <Box sx={{ width: '100%', margin: '0 auto', padding: 2 }}>
          {isAdding && (
            <TextField
              fullWidth
              inputRef={textFieldRef}
              variant="outlined"
              label="Add a note"
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              multiline
              rows={2}
              disabled={isLoading}
              sx={{ marginBottom: 2 }}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              startIcon={<AddCircleRoundedIcon />}
              onClick={handleButtonClick}
            >
              {isAdding ? 'Submit Note' : 'Add Note'}
            </Button>
          </Box>
          <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 1 }}>
            History
          </Typography>
          <List>
            {notes.map((note, index) => (
              <Note key={index} text={note.note} date={note.updated_at} />
            ))}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
}

export default NotesHistory;
