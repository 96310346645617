import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, TextField } from '@mui/material';

const FinancialInfoCard = ({ caregiverDetails, handleChange, isEditing }) => {
  const [financialInfo, setFinancialInfo] = useState({
    bank_name: '',
    ifsc_code: '',
    account_number: '',
    branch: '',
  });

  useEffect(() => {
    if (caregiverDetails?.bank_info) {
      setFinancialInfo(caregiverDetails.bank_info);
    }
  }, [caregiverDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFinancialInfo = { ...financialInfo, [name]: value };
    setFinancialInfo(updatedFinancialInfo);
    if (handleChange) {
      handleChange('bank_info', updatedFinancialInfo);
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">Financial Information</Typography>
        <Grid container spacing={2}>
          {[
            { label: 'Bank Name', name: 'bank_name' },
            { label: 'IFSC Code', name: 'ifsc_code' },
            { label: 'Account Number', name: 'account_number' },
            { label: 'Branch', name: 'branch' },
          ].map((field) => (
            <Grid item xs={12} sm={6} key={field.name}>
              {isEditing ? (
                <TextField
                  fullWidth
                  label={field.label}
                  name={field.name}
                  value={financialInfo[field.name] || ''}
                  onChange={handleInputChange}
                />
              ) : (
                <Typography variant="body2" gutterBottom>
                  <strong>{field.label}:</strong> {financialInfo[field.name] || 'N/A'}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FinancialInfoCard;
