import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from "./baseQueryWithReauth";

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getDashboardStatistics: builder.query({
      query: () => 'dashboard-statistics/',
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetDashboardStatisticsQuery } = dashboardApi;
