// src/redux/globalSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMenu: null,
  userInfo:null
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setSelectedMenuItem: (state, action) => {
      state.selectedMenu = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { setSelectedMenuItem, setUserInfo } = globalSlice.actions;

export const selectedMenu = (state) => state.global.selectedMenu;
export const userInfo = (state) => state.global.userInfo;

export default globalSlice.reducer;
