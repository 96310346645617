// src/services/contractApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueryWithReauth";
import { tagTypes } from "./tags";

const contractApi = createApi({
  reducerPath: "contractsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes,
  endpoints: (builder) => ({
    getContracts: builder.query({
      query: () => "contracts/",
      providesTags: ["Contract"],
      keepUnusedDataFor: 300,
    }),
    getContractById: builder.query({
      query: (id) => `contracts/${id}/`,
      providesTags: (result, error, id) => [{ type: "Contract", id }],
    }),
    addContract: builder.mutation({
      query: (newContract) => ({
        url: "contracts/",
        method: "POST",
        body: newContract,
      }),
      invalidatesTags: ["Contract", "Client"],
    }),
    updateContract: builder.mutation({
      query: ({ id, ...updatedContract }) => ({
        url: `contracts/${id}/`,
        method: "PATCH",
        body: updatedContract,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Contract", id },
        "Contract",
        "Client",
      ],
    }),
    deleteContract: builder.mutation({
      query: (id) => ({
        url: `contracts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "Contract", id },
        "Contract",
        "Client",
      ],
    }),
  }),
});

export const {
  useGetContractsQuery,
  useGetContractByIdQuery,
  useAddContractMutation,
  useUpdateContractMutation,
  useDeleteContractMutation,
} = contractApi;

export default contractApi;
