import React from "react";
import { RadialBarChart, RadialBar, Legend, Tooltip, ResponsiveContainer } from "recharts";
import { Paper, Typography, Box } from "@mui/material";

const colorFills = [
  "#aa66cc", // Purple
  "#ffbb33", // Yellow
  "#33b5e5", // Blue
  "#00c851"  // Green
];
function AppointmentsOverviewChart({ values }) {

  const data = []
  // Populate the dataMap with values from the props
  Object.entries(values).slice(0, 4).map(([meeting_type, value], index) => {
    data.push(
      {
        name: meeting_type,
        fill: colorFills[index],
        value: value
      }
    )
  });

  return (
    <Paper
      sx={{
        borderRadius: "13px",
        display: "flex",
        flexDirection: "column",
        padding: 2,
        textAlign: "left",
        backgroundColor: "#2c3e50", // Dark background
        color: "white",
        height: '100%', // Ensure Paper takes full height of the grid item
        boxSizing: 'border-box'
      }}
    >
      <Box sx={{ textAlign: "center", marginBottom: 2 }}>
        <Typography variant="h6" color="white">
          Upcoming Appointments 
        </Typography>
      </Box>
      <Box sx={{ height: 200, display: "flex", mt: 1, justifyContent: "center" }}>
        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart 
            cx="50%" 
            cy="50%" 
            innerRadius="20%" 
            outerRadius="150%" // Adjusted radius for a larger chart
            startAngle={180} 
            endAngle={0} 
            data={data}
          >
            <RadialBar 
              minAngle={15} 
              label={{ position: 'insideStart', fill: 'white' }} 
              background 
              clockWise 
              dataKey="value"
              radius={["30%", "70%"]} // Rounded corners
            />
            <Tooltip 
              contentStyle={{ backgroundColor: 'white', color: 'black' }} 
              cursor={{ stroke: 'transparent' }} 
            />
            <Legend 
              iconSize={10} 
              layout="horizontal" 
              verticalAlign="top" 
              align="center"
              wrapperStyle={{
                marginTop: 0,
                paddingTop: 0,
                bottom: 0,
                width: '100%',
                color: 'white',
              }}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
}

export default AppointmentsOverviewChart;
