// src/services/appointmentApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueryWithReauth";
import { tagTypes } from "./tags";

const appointmentApi = createApi({
  reducerPath: "appointmentsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Appointment", "Client"], // Ensure relevant tag types are included
  endpoints: (builder) => ({
    getAppointments: builder.query({
      query: () => "appointments/",
      providesTags: ["Appointment"],
    }),
    getAppointmentById: builder.query({
      query: (id) => `appointments/${id}/`,
      providesTags: (result, error, id) => [{ type: "Appointment", id }],
    }),
    addAppointment: builder.mutation({
      query: (newAppointment) => ({
        url: "appointments/",
        method: "POST",
        body: newAppointment,
      }),
      invalidatesTags: ["Appointment", "Client"],
    }),
    updateAppointment: builder.mutation({
      query: ({ id, ...updatedAppointment }) => ({
        url: `appointments/${id}/`,
        method: "PATCH",
        body: updatedAppointment,
      }),
      invalidatesTags: (result, error, { id, client }) => [
        { type: "Appointment", id },
        { type: "Client", id: client },
        "Appointment",
        "Client",
      ],
    }),
    deleteAppointment: builder.mutation({
      query: (id) => ({
        url: `appointments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Appointment", id }, "Appointment"],
    }),
  }),
});

export const {
  useGetAppointmentsQuery,
  useGetAppointmentByIdQuery,
  useAddAppointmentMutation,
  useUpdateAppointmentMutation,
  useDeleteAppointmentMutation,
} = appointmentApi;

export default appointmentApi;
