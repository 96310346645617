import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  TextField,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';

const CohabitantsCard = ({ patientProfile, newPatient = false, onChange }) => {
  const [cohabitants, setCohabitants] = useState([]);

  const addCohabitant = () => {
    setCohabitants([...cohabitants, { name: '', age: '', gender: '', editMode: true }]);
  };

  useEffect(() => {
    if (patientProfile?.cohabitants?.length > 0) {
      const cohabitantData = patientProfile.cohabitants.map(({ id, ...data }) => data);
      setCohabitants(cohabitantData);
    }
  }, [patientProfile]);

  const handleCohabitantChange = (index, field, value) => {
    const updatedCohabitants = [...cohabitants];
    updatedCohabitants[index][field] = value;
    setCohabitants(updatedCohabitants);
  };

  const deleteCohabitant = (index) => {
    setCohabitants(cohabitants.filter((_, i) => i !== index));
  };

  const toggleEditMode = (index) => {
    const updatedCohabitants = [...cohabitants];
    updatedCohabitants[index].editMode = !updatedCohabitants[index].editMode;
    setCohabitants(updatedCohabitants);
  };

  useEffect(()=>{
    onChange({"cohabitants": cohabitants})
  }, [cohabitants])

  return (
    <Card>
      <CardHeader
        title="Cohabitants"
        action={
          <IconButton onClick={addCohabitant}>
            <AddIcon color='success'/>
          </IconButton>
        }
      />
      <CardContent>
        {cohabitants.map((cohabitant, index) => (
          <Card key={index} sx={{ mb: 2 , bgcolor:"whitesmoke"}}>
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={10}>
                  <Typography variant="h6">Cohabitant {index + 1}</Typography>
                </Grid>
                <Grid item xs={2}>
                  {cohabitant.editMode ? (
                    <IconButton onClick={() => toggleEditMode(index)}>
                      <DoneIcon color='primary'/>
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => toggleEditMode(index)}>
                      <EditIcon color='primary'/>
                    </IconButton>
                  )}
                  <IconButton onClick={() => deleteCohabitant(index)}>
                    <DeleteIcon color='error'/>
                  </IconButton>
                </Grid>
                {cohabitant.editMode ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Name"
                        fullWidth
                        value={cohabitant.name}
                        onChange={(e) => handleCohabitantChange(index, 'name', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Age"
                        fullWidth
                        value={cohabitant.age}
                        onChange={(e) => handleCohabitantChange(index, 'age', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Gender"
                        fullWidth
                        value={cohabitant.gender}
                        onChange={(e) => handleCohabitantChange(index, 'gender', e.target.value)}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={4}>
                      <Typography>Name: {cohabitant.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>Age: {cohabitant.age}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>Gender: {cohabitant.gender}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        ))}
      </CardContent>
    </Card>
  );
};

export default CohabitantsCard;