import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

const CaregiverPreference = ({ patientProfile, newPatient = false, onChange }) => {
  const [isEditing, setIsEditing] = useState(newPatient);
  const [preferences, setPreferences] = useState({
    language: "",
    religion: "",
    sex: "",
  });

  useEffect(() => {
    if (patientProfile) {
      setPreferences({
        language: patientProfile.languages ? patientProfile.languages[0] : "",
        religion: patientProfile.religion || "",
        sex: patientProfile.gender || "",
      });
    }
  }, [patientProfile]);

  const handleToggleEdit = () => {
    setIsEditing((prevIsEditing) => {
      const newIsEditing = !prevIsEditing;
      if (!newIsEditing) {
        onChange({ caregiver_preference: preferences });
      }
      return newIsEditing;
    });
  };

  const handlePreferenceChange = (event) => {
    const { name, value } = event.target;
    setPreferences((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="Caregiver Preference"
        action={
          <IconButton onClick={handleToggleEdit} aria-label="edit">
            {isEditing ? (
              <DoneIcon color="primary" />
            ) : (
              <EditIcon color="primary" />
            )}
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {Object.keys(preferences).map((key) => (
            <Grid item xs={4} key={key}>
              <Typography variant="subtitle2" color="orange" gutterBottom>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Typography>
              {isEditing ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  name={key}
                  value={preferences[key]}
                  onChange={handlePreferenceChange}
                />
              ) : (
                <Typography variant="body2">{preferences[key]}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CaregiverPreference;
