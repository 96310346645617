import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Box,CircularProgress, IconButton, Typography, useTheme, darken, Badge } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getColor } from 'common/utilities';
import { useLoading } from './LoadingProvider';
import { useSnackbar } from './SnackbarProvider';

const handleEditClick = (rowData) => {
  // Your edit logic here
  // console.log('Edit row:', rowData);
};

const handleViewClick = (rowData) => {
  // Your view logic here
  // console.log('View row:', rowData);
};

const Table = ({ data, columns,columnVisibility={}, sorting=[], refetch, chipField="", onAddClick, title="", rowClickUrl, idVar="id" }) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const darkerPrimary = darken(primaryColor, 0.5); 
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState([]); // Add columnFilters state
  const memoizedColumns = useMemo(
    () => columns,
    [columns]
  );
  // const memoizedColumns = useMemo(
  //   () => [
  //     ...displayedColumns,
  //   ],
  //   [columns]
  // );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, //customize the default page size
  });
  const location = useLocation();

const uniqueChipValues = useMemo(() => {
  if (!data || !chipField) return [];

  // Create a unique set of chip values and count them
  const chipValues = data.flatMap(row =>
    row[chipField]?.split(',').map(value => value.trim()) || []
  );

  const valueCounts = chipValues.reduce((acc, value) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});
  
  // Convert the valueCounts object to an array and sort by count
  const sortedValues = Object.entries(valueCounts)
    .sort((a, b) => b[1] - a[1]) // Sort in descending order of counts
    .slice(0, 4); // Take top 4

  const allCount = data.length; // Total count of entries

  // Add the "All" chip with the total entries count
  const result = [['All', allCount], ...sortedValues ];

  return result;
}, [data, chipField]);

  const { startLoading, stopLoading } = useLoading();
  const showSnackbar = useSnackbar();
  const [refreshing, setRefreshing] = useState(false);
  const handleRefetch = async () => {
    try {
      startLoading();
      setRefreshing(true)
      await refetch();  // Refetch data from the API
      showSnackbar("Data refreshed successfully", "success");
    } catch (error) {
      showSnackbar("Failed to refresh data", "error");
      console.error("Error during refetch:", error);
    } finally {
      setRefreshing(false)
      stopLoading();
    }
  };

  const table = useMaterialReactTable({
    columns: columns,
    data,
    initialState: {
      columnVisibility: columnVisibility,
      sorting: sorting,
    },
    onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
    state: { pagination, columnFilters },
    onColumnFiltersChange: setColumnFilters,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const previousURL = encodeURIComponent(location.pathname);
        navigate(
          rowClickUrl + row.original[idVar] + `?redirect=${previousURL}`
        );
      },
      sx: {
        cursor: "pointer",
      },
    }),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small",
      margin: "none",
      sx: {
        width: "80%",
      },
    },
    muiTablePaperProps: {
      sx: {
        // borderRadius: 6,
        width: "100%",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: darkerPrimary,
        bgcolor: "white",
        borderColor: darkerPrimary,
      },
    },
    renderTopToolbarCustomActions: () => (
      <Box
        display="flex"
        width={"100%"}
        alignItems="center"
        justifyContent="flex-end"
      >
        {uniqueChipValues &&
          uniqueChipValues.map(([value, count], index) => (
            <Button
              key={index}
              variant="outlined"
              onClick={() =>
                setColumnFilters(
                  value === "All" ? [] : [{ id: chipField, value }] // Set column filter
                )
              }
              sx={{
                mr: 2,
                mt: 1,
                position: "relative",
                borderRadius: 5,
                padding: "4px 8px",
                fontSize: "0.75rem",
                backgroundColor: getColor(value), // Set background color based on value
                color: "#ffffff", // Set text color (you can also make it dynamic if needed)
                "&:hover": {
                  backgroundColor: `${getColor(value)}CC`, // Slightly lighter color for hover (adjust as needed)
                  borderColor: `${getColor(value)}CC`, // Slightly lighter color for hover (adjust as needed)
                  color: "#ffffff", // Hover text color
                },
                borderColor: getColor(value), // Border color based on value
              }}
            >
              {value}
              <Badge
                badgeContent={count}
                color="warning"
                sx={{ position: "absolute", top: -1, right: -1 }}
              />
            </Button>
          ))}
        {refetch && (
          <IconButton
            onClick={handleRefetch}
            color="primary"
            aria-label="refresh"
          >
            {refreshing ? (
              <CircularProgress size={24} /> // Loading animation
            ) : (
              <RefreshIcon /> // Refresh icon
            )}
          </IconButton>
        )}
        {onAddClick && (
          <Button
            variant="contained"
            // color="primary"
            sx={{ bgcolor: darkerPrimary }}
            onClick={onAddClick}
            startIcon={<AddCircleIcon />} // Add circle icon
          >
            Add New
          </Button>
        )}
      </Box>
    ),
  });
  return (
    <Box 
      position="relative" 
      sx={{ width: '100%', mt:4}}
    >
      {/* Box that lies on top of the table header */}
      <Box 
        position="absolute" 
        top={-30} 
        left={20}
        width={"20%"}
        borderRadius={2} 
        bgcolor={darkerPrimary}
        color="white"
        p={2}
        zIndex={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6">
          {title}
        </Typography>
      </Box>
    
      {/* The MaterialReactTable component */}
      <MaterialReactTable      
        table={table}
        enableStickyHeader
        options={{
            pagination: {
                rowsPerPageOptions: [5, 10, 20, 50], // Set available rows per page options
            },
        }}
        sx={{
          '& .MuiTable-root': {
            width: '100%',
          },
        }}
        
      />
    </Box>
    );
};

export default Table;