import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Container,
  Paper,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import ITextField from "./ITextField";
import theme from "Theme";
import { useSnackbar } from "./SnackbarProvider";
import { useLoading } from "./LoadingProvider";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLoginMutation } from "services/authApi";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginMutation, { isLoading }] = useLoginMutation();

  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    navigate("/");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();

    try {
      const user = { username, password };
      const result = await loginMutation(user).unwrap();
      const { access, refresh } = result;
      localStorage.setItem("access_token", access);
      localStorage.setItem("refresh_token", refresh);
      showSnackbar("Logged In", "success");
      navigate("/dashboard");
    } catch (error) {
      console.error("Login failed:", error);
      navigate("/");
      showSnackbar("Error Occurred during login", "error");
    } finally {
      stopLoading();
    }
  };

  const style = {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    zIndex: 1,
  };

  return (
    <Container sx={{ width: { lg: "35%", sm: "50%" } }}>
      <Box
        position="relative"
        marginTop={4}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -20,
            height: 70,
            width: "70%",
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 5,
            bgcolor: "primary.main",
          }}
        >
          <Typography variant="h5" sx={{ color: "white" }} gutterBottom>
            Login
          </Typography>
          <Typography sx={{ fontSize: 10, color: "white" }} gutterBottom>
            Your Portal to Caring
          </Typography>
        </Box>
        <Paper style={style} elevation={3} sx={{ padding: 2, borderRadius: 4 }}>
          <Grid
            container
            sx={{ pt: 8 }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                label="User Name"
                onChange={(e) => setUsername(e.target.value)}
                onInput={(e) => setUsername(e.target.value)}
                onKeyPress={handleKeyPress}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                onChange={handlePasswordChange}
                onInput={handlePasswordChange}
                onKeyPress={handleKeyPress}
                variant="outlined"
                fullWidth
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                        sx={{
                          color: theme.palette.primary.main,
                          backgroundColor: "white",
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                variant="contained"
                fullWidth                
                sx={{
                  bgcolor: "#FF0000",
                  '&:hover': {
                    bgcolor: "#CC0000", // Darker shade of red for hover effect
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            {/* <Grid item textAlign="center" xs={12}>
              <Link href="#" color="inherit">
                Forgot Password?
              </Link>
            </Grid> */}
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}

export default LoginForm;
