import React from 'react';
import Avatar from '@mui/material/Avatar';

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function getInitials(firstName, lastName) {
  return `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase();
}

function UserAvatar({ firstName, lastName,sx }) {
  const initials = getInitials(firstName, lastName);
  const backgroundColor = getRandomColor();

  return (
    <Avatar sx={{ width: 56, height: 56, marginRight: 2, bgcolor: backgroundColor,...sx }}>
      {initials}
    </Avatar>
  );
}

export default UserAvatar;
