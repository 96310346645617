import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Chip,
  IconButton,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

const careNeedsList = [
  "Toilet assistance",
  "Feeding assistance",
  "Mobility Assistance",
  "Medication Management",
  "Health Monitoring",
  "Memory Care",
  "Bath assistance",
];

const CareNeeds = ({ patientProfile, newPatient = false, onChange }) => {
  const [isEditing, setIsEditing] = useState(newPatient);
  const [selectedNeeds, setSelectedNeeds] = useState([]);
  const [otherNeeds, setOtherNeeds] = useState("");

  useEffect(() => {
    // Initialize selectedNeeds with patientProfile data
    if (patientProfile?.careneeds) {
      setSelectedNeeds(patientProfile.careneeds);
    }
  }, [patientProfile]);

  const handleToggleEdit = () => {
    setIsEditing((prevIsEditing) => {
      const newIsEditing = !prevIsEditing;
      if (!newIsEditing) {
        onChange({ careneeds: selectedNeeds });
      }
      return newIsEditing;
    });

    if (isEditing) {
      // When switching from editing to viewing, add otherNeeds to selectedNeeds
      const otherNeedsArray = otherNeeds
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item);
      setSelectedNeeds([
        ...new Set([...selectedNeeds, ...otherNeedsArray]),
      ]);
      setOtherNeeds("");
    }
  };

  const handleNeedChange = (need) => {
    setSelectedNeeds((prev) =>
      prev.includes(need)
        ? prev.filter((item) => item !== need)
        : [...prev, need]
    );
  };

  const handleOtherNeedsChange = (event) => {
    setOtherNeeds(event.target.value);
  };

  const handleOtherNeedsBlur = () => {
    const others = otherNeeds
      .split(",")
      .map((need) => need.trim())
      .filter((need) => need);
    setSelectedNeeds((prev) => [...new Set([...prev, ...others])]);
    setOtherNeeds("");
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="Care Needs"
        action={
          <IconButton onClick={handleToggleEdit} aria-label="edit">
            {isEditing ? (
              <DoneIcon color="primary" />
            ) : (
              <EditIcon color="primary" />
            )}
          </IconButton>
        }
      />
      <CardContent>
        {isEditing ? (
          <Grid container spacing={2}>
            {careNeedsList.map((need) => (
              <Grid item xs={12} sm={4} key={need}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedNeeds.includes(need)}
                      onChange={() => handleNeedChange(need)}
                    />
                  }
                  label={need}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Enter other needs, separated by commas"
                variant="outlined"
                value={otherNeeds}
                onChange={handleOtherNeedsChange}
                onBlur={handleOtherNeedsBlur}
                placeholder="Enter other needs, separated by commas"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="orange">
                Selected Needs:
              </Typography>
              {selectedNeeds.length > 0 ? (
                selectedNeeds.map((need) => (
                  <Chip key={need} label={need} sx={{ m: 0.5 }} />
                ))
              ) : (
                <Typography variant="body2">No needs selected.</Typography>
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default CareNeeds;
