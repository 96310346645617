// src/services/caregiverApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueryWithReauth';
import { tagTypes } from './tags';
import { CaregiverStatuses } from 'CommonData/Common';

const caregiverApi = createApi({
  reducerPath: 'caregiversApi',
  baseQuery: baseQueryWithReauth,
  tagTypes,
  endpoints: (builder) => ({
    getCaregivers: builder.query({
      query: () => 'caregivers/',
      providesTags: ['Caregiver'],
      keepUnusedDataFor: 300,
    }),
    getCaregiverById: builder.query({
      query: (id) => `caregivers/${id}/`,
      providesTags: (result, error, id) => [{ type: 'Caregiver', id }],
    }),
    createInterestedCaregiver: builder.mutation({
      query: (newCaregiver) => {
        return {
        url: 'public/caregivers/interested-caregiver/',
        method: 'POST',
        body: newCaregiver
        }
      },
      invalidatesTags: ['Caregiver'],
    }),
    addCaregiver: builder.mutation({
      query: (newCaregiver) => ({
        url: 'caregivers',
        method: 'POST',
        body: newCaregiver,
      }),
      invalidatesTags: ['Caregiver'],
    }),
    updateCaregiver: builder.mutation({
      query: ({ id, updatedCaregiver }) => {
        return {
          url: `caregivers/${id}/`,
          method: 'PATCH',
          body: updatedCaregiver,
          // Do NOT set 'Content-Type' header; FormData handles this automatically
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Caregiver', id }, 'Caregiver'],
    }),        
    deleteCaregiver: builder.mutation({
      query: (id) => ({
        url: `caregivers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Caregiver', id }],
    }),
    fetchResumeFile: builder.query({
      // The query to fetch the resume file
      query: ({ fileUrl }) => ({
        url: `${fileUrl}`,
        method: 'GET',
        responseHandler: (response) => response.blob(), // we expect the file to be in a binary format (blob)
      }),
      // Disable the cache for file download
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetCaregiversQuery,
  useGetCaregiverByIdQuery,
  useCreateInterestedCaregiverMutation,
  useAddCaregiverMutation,
  useUpdateCaregiverMutation,
  useDeleteCaregiverMutation,
  useFetchResumeFileQuery,
} = caregiverApi;

export default caregiverApi;
