import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "pages/LandingPage";
import ClientsListPage from "pages/Client/ClientsListPage";
import PatientsListPage from "pages/Patient/PatientsListPage";
import DashboardPage from "pages/DashboardPage";
import BillingsListPage from "pages/Billings/BillingsListPage";
import AppointmentsListPage from "pages/Appointment/AppointmentsListPage";
// import "./assets/styles/global.css"; // Import the global CSS file
import CaregiversListPage from "pages/Caregiver/CaregiversListPage";
import ProtectedRoute from "components/ProtectedRoute";
import AppointmentDetail from "components/AppointmentDetail";
import HomeLayout from "layout/HomeLayout";
import ClientDetails from "pages/Client/ClientDetails";
import ScheduleAppointment from "components/ScheduleAppointment";
import PatientDetails from "pages/Patient/PatientDetails";
import NewPatient from "components/Patient/NewPatient";
import LandingLayout from "layout/LandingLayout";
import LoginForm from "components/LoginForm";
import ClientInterestForm from "components/ClientInterestForm";
import CaregiverApplyForm from "pages/Caregiver/CaregiverApplyForm";
import CaregiverDetails from "pages/Caregiver/CaregiverDetails";
import ContractCard from "pages/Contracts/ContractCard";
import ContractsListPage from "pages/Contracts/ContractsListPage";
import BillingCard from "pages/Billings/BillingCard";
import AdminPage from "pages/AdminPage";
import NewLandingLayout from "layout/NewLandingLayout";
import s1image from "assets/s1image.jpg";
import s2image from "assets/s2image.jpg";
import smithaImage from 'assets/smitha.png';
import subaImage from 'assets/suba.png';
import daycareImage from 'assets/daycare.jpg';
import nightcareImage from 'assets/nightcare.jpg';
import stayAtHomeImage from 'assets/stayathome.jpg';
import { preloadImages } from "common/utilities";

function App() {
  useEffect(() => {
    // Array of image paths to preload
    const imagesToPreload = [
      s1image,
      s2image,
      smithaImage,
      subaImage,
      daycareImage,
      nightcareImage,
      stayAtHomeImage,
    ];

    // Preload the images
    preloadImages(imagesToPreload);
  }, []);

  // const { isLoading, isError, isSuccess } = useApiStatus();
  // const { startLoading, stopLoading } = useLoading();
  // const showSnackbar = useSnackbar();

  // useEffect(() => {
  //   if (isLoading) {
  //     startLoading();
  //   } else {
  //     stopLoading();
  //   }
  // }, [isLoading, startLoading, stopLoading]);

  // useEffect(() => {
  //   if (isError) {
  //     showSnackbar({ message: 'An error occurred', severity: 'error' });
  //   }
  //   if (isSuccess) {
  //     showSnackbar({ message: 'Operation successful', severity: 'success' });
  //   }
  // }, [isError, isSuccess, showSnackbar]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<NewLandingLayout/>}>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/clients/new" element={<ClientInterestForm />} />
          <Route path="/caregiver/new" element={<CaregiverApplyForm />} />
        </Route>
        <Route path="/" element={<ProtectedRoute element={HomeLayout} />}>
          <Route
            path="/admin"
            element={<ProtectedRoute element={AdminPage} />}
          />
          <Route
            path="/appointments"
            element={<ProtectedRoute element={AppointmentsListPage} />}
          />
          <Route
            path="/appointments/:appointmentId"
            element={<ProtectedRoute element={AppointmentDetail} />}
          />
          <Route
            path="/appointments/new"
            element={<ProtectedRoute element={ScheduleAppointment} />}
          />
          <Route
            path="/billings"
            element={<ProtectedRoute element={BillingsListPage} />}
          />
          <Route
            path="/billings/:billingId"
            element={<ProtectedRoute element={BillingCard} />}
          />
          <Route
            path="/billings/new"
            element={<ProtectedRoute element={BillingCard} />}
          />
          <Route
            path="/caregivers"
            element={<ProtectedRoute element={CaregiversListPage} />}
          />
          <Route
            path="/caregivers/:caregiver_id"
            element={<ProtectedRoute element={CaregiverDetails} />}
          />
          <Route
            path="/caregivers/:caregiver_id/appointment"
            element={<ProtectedRoute element={ScheduleAppointment} />}
          />
          <Route
            path="/clients"
            element={<ProtectedRoute element={ClientsListPage} />}
          />
          <Route
            path="/clients/:clientId"
            element={<ProtectedRoute element={ClientDetails} />}
          />
          <Route
            path="/clients/:clientId/appointments/:appointmentId"
            element={<ProtectedRoute element={AppointmentDetail} />}
          />
          <Route
            path="/clients/:clientId/appointments/new"
            element={<ProtectedRoute element={ScheduleAppointment} />}
          />
          <Route
            path="/clients/:clientId/billings/:billingId"
            element={<ProtectedRoute element={BillingCard} />}
          />
          <Route
            path="/clients/:clientId/billings/new"
            element={<ProtectedRoute element={BillingCard} />}
          />
          <Route
            path="/clients/:clientId/contracts/:contractId"
            element={<ProtectedRoute element={ContractCard} />}
          />
          <Route
            path="/clients/:clientId/contracts/new"
            element={<ProtectedRoute element={ContractCard} />}
          />
          <Route
            path="/clients/:clientId/patients/:patientId"
            element={<ProtectedRoute element={PatientDetails} />}
          />
          <Route
            path="/clients/:clientId/patients/new"
            element={<ProtectedRoute element={NewPatient} />}
          />

          {/* Fallback route for any other path under /clients/:clientId */}
          <Route
            path="/clients/:clientId/*"
            element={<ProtectedRoute element={ClientDetails} />}
          />
          <Route
            path="/contracts"
            element={<ProtectedRoute element={ContractsListPage} />}
          />
          <Route
            path="/contracts/:contractId"
            element={<ProtectedRoute element={ContractCard} />}
          />
          <Route
            path="/contracts/:contractId/billing"
            element={<ProtectedRoute element={BillingCard} />}
          />
          <Route
            path="/contracts/new"
            element={<ProtectedRoute element={ContractCard} />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={DashboardPage} />}
          />
          <Route
            path="/patients"
            element={<ProtectedRoute element={PatientsListPage} />}
          />
          <Route
            path="/patients/:patientId"
            element={<ProtectedRoute element={PatientDetails} />}
          />
          <Route
            path="/patients/new"
            element={<ProtectedRoute element={NewPatient} />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
