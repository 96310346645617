import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import ReactMaterialTable from "./ReactMaterialTable";

const ProfessionalBackgroundCard = ({
  caregiverDetails,
  handleChange,
  isEditing,
}) => {
  const empHistoryColumns = [
    {
      field: "organisationName",
      headerName: "Organisation Name",
      type: "text",
    },
    { field: "designation", headerName: "Designation", type: "text" },
    { field: "years", headerName: "Years", type: "text" },
    { field: "role", headerName: "Role", type: "text" },
  ];

  const referenceColumns = [
    { field: "name", headerName: "Name", type: "text" },
    { field: "designation", headerName: "Designation", type: "text" },
    { field: "details", headerName: "Details", type: "text" },
    { field: "contactNumber", headerName: "Contact Number", type: "text" },
    { field: "emailId", headerName: "Email ID", type: "text" },
  ];

  const educationColumns = [
    { field: "institute", headerName: "Institute", type: "text" },
    { field: "degree", headerName: "Degree", type: "text" },
    { field: "fieldOfStudy", headerName: "Field of Study", type: "text" },
    { field: "startYear", headerName: "Start Year", type: "date" },
    { field: "endYear", headerName: "End Year", type: "date" },
  ];

  const certificationColumns = [
    {
      field: "certificationName",
      headerName: "Certification Name",
      type: "text",
    },
    {
      field: "issuingOrganization",
      headerName: "Issuing Organization",
      type: "text",
    },
    { field: "issueDate", headerName: "Issue Date", type: "date" },
    { field: "expiryDate", headerName: "Expiry Date", type: "date" },
  ];

  const trainingColumns = [
    { field: "trainingName", headerName: "Training Name", type: "text",},
    { field: "Institution", headerName: "Institution Name", type: "text",},
    { field: "trainingStart", headerName: "Training Start Date", type: "date" },
    { field: "trainingEnd", headerName: "Training End Date", type: "date" },
  ];

  const handleTableChange = (tableKey, updatedData) => {
    handleChange(tableKey, updatedData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Employment History in Caregiving</Typography>
            <ReactMaterialTable
              columns={empHistoryColumns}
              data={caregiverDetails?.employmentHistory || []}
              handleChange={(data) =>
                handleTableChange("employmentHistory", data)
              }
              isEditing={isEditing}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">References</Typography>
            <ReactMaterialTable
              columns={referenceColumns}
              data={caregiverDetails?.references || []}
              handleChange={(data) => handleTableChange("references", data)}
              isEditing={isEditing}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Education Details</Typography>
            <ReactMaterialTable
              columns={educationColumns}
              data={caregiverDetails?.education_details || []}
              handleChange={(data) =>
                handleTableChange("education_details", data)
              }
              isEditing={isEditing}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Certifications</Typography>
            <ReactMaterialTable
              columns={certificationColumns}
              data={caregiverDetails?.certifications || []}
              handleChange={(data) => handleTableChange("certifications", data)}
              isEditing={isEditing}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Trainings</Typography>
            <ReactMaterialTable
              columns={trainingColumns}
              data={caregiverDetails?.trainings || []}
              handleChange={(data) => handleTableChange("trainings", data)}
              isEditing={isEditing}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProfessionalBackgroundCard;
